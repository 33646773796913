import {
  IDeleteOrderPayload,
  IDeleteOrderResponse,
  IEditOrderPayload,
  IEditOrderResponse,
  IGetOrdersPayload,
  IPostOrderPayload,
  IPostOrderResponse,
} from './interfaces';

import { http } from '@/store/effects';
import { ApplicationError } from '@/util/errors';
import { AsyncRequestWithToken } from '@/util/types';

// get orders
export const getOrders = async (
  token: string,
  { market, status, asset_id }: IGetOrdersPayload,
) => {
  const params = { status, asset_id, market } as IGetOrdersPayload;
  try {
    const { data } = await http.getWithToken<IPostOrderResponse | undefined>({
      token,
      url: `/market-service/orders`,
      config: {
        params,
      },
    });
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.orders.getOrder');
  }
};

// post order
export const postOrder: AsyncRequestWithToken<
  IPostOrderPayload,
  IPostOrderResponse | undefined
> = async (token, { order, market }) => {
  const { data } = await http.postWithToken(
    token,
    `/market-service/orders?market=${market}`,
    order,
    null,
  );
  return data;
};

// edit order
export const patchOrder: AsyncRequestWithToken<
  IEditOrderPayload,
  IEditOrderResponse | undefined
> = async (token, { id, order, market }) => {
  try {
    const edits = { amount: order.amount, price: order.price };
    const { data } = await http.patchWithToken(
      token,
      `/market-service/orders/${id}/edit?market=${market}`,
      edits,
      null,
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.orders.patchOrder');
  }
};

// cancel order
export const cancelOrder: AsyncRequestWithToken<
  IDeleteOrderPayload,
  IDeleteOrderResponse | undefined
> = async (token, { id, market }) => {
  try {
    const { data } = await http.patchWithToken(
      token,
      `/market-service/orders/${id}/cancel?market=${market}`,
      null,
      null,
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.orders.deleteOrder');
  }
};
