// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ProductName } from '@/store/namespaces/eligibility/types';
import { MarketName } from '@thndr/namespace/market';

export enum LOADING_STATUS {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  FAILURE = 'FAILURE',
  LOADED = 'LOADED',
}
export enum ASSET_CLASSES {
  STOCK = 'STOCK',
  ETF = 'ETF',
  INDEX = 'INDEX',
  MANAGED_FUND = 'FUND',
}

type ISavingMFReturnItem = {
  actual_return: number;
  annualized_return: number;
  is_empty: boolean;
};

export interface ISavingMFReturn {
  weekly: ISavingMFReturnItem;
  monthly: ISavingMFReturnItem;
  semi_yearly: ISavingMFReturnItem;
  yearly: ISavingMFReturnItem;
}

export interface IAssetClass {
  id: string;
  asset_class: string | ASSET_CLASSES;
  market: MarketName;
  product: ProductName;
  name: string;
  about: string;
  currency: string;
  country: string;
  logo: string;
  is_visible: boolean;
  tags: string[];
  created_at: string;
  symbol: string;
  has_financials: boolean;
  has_analysis: boolean;
  has_consensus: boolean;
  is_ipo: boolean;
  ipo_price: number;
  ipo_max_shares: number;
  ipo_min_shares: number;
  ipo_share_price_multiplier: number;
  info_link: string;
  is_tradable: boolean;
  is_3dp: boolean;
  ceo: string;
  hq: string;
  established_at?: string;
  min_value?: number;
  previous_close?: number;
  last_trade_price?: number; // 67.1;
  price?: number;
  annualized_return?: number;
  last_unit_price?: number;
  fractionable?: boolean;
  stats?: {
    market_cap?: number;
    avg_value?: number;
    div_yield_prc?: number;
    pe?: number;
    eps?: number;
    symbol_state?: StockStatus;
  };
  is_right: boolean;
  industry?: string;
}
export enum AssetClasses {
  STOCK = 'STOCK',
  ETF = 'ETF',
  INDEX = 'INDEX',
  FUND = 'FUND',
}

export interface IETF extends IAssetClass {
  isin: string;
  manager: string;
  currency_id: number;
  currency: string;
  min_value: number;
  max_value: number;
  is_tradable: boolean;
  feed: null;
}

export interface IIndex extends IAssetClass {
  symbol: string;
  constituents: IAssetClass[];
  feed: IFeed;
}

export interface IStock extends IAssetClass {
  day_trade_limit: number;
  isin: string;
  symbol: string;
  industry: string;
  country_id: number;
  country: string;
  currency_id: number;
  currency: string;
  is_tradable: boolean;
  feed: IFeed;
}

export interface IManagedFund extends IAssetClass {
  manager: string;
  currency_id: number;
  currency: string;
  min_value: number;
  max_value: number;
  min_volume: number;
  is_tradable: boolean;
  feed: null;
  risk_profile: string;
  expense_ratio: string;
  risk_level: string;
  return_period: string;
  subscription_frequency: string;
}
export enum MARKET_ID {
  OOTC = 'OOTC',
  NOPL = 'NOPL',
}
export type IFeed = Partial<{
  symbol_code: string;
  last_trade_price: number;
  last_trade_volume: number;
  total_trades: number;
  total_volume: number;
  bid_volume: number;
  ask_volume: number;
  total_bids: number;
  total_asks: number;
  last_change: number;
  last_change_prc: number;
  avg_change: number;
  avg_change_prc: number;
  last_trade_date: string;
  previous_close: number;
  ref_price: number;
  high_price_limit: number;
  low_price_limit: number;
  total_buy: number;
  total_sell: number;
  avg_5day: number;
  avg_30day: number;
  avg_90day: number;
  listed_shares: number;
  currency: string;
  eps: number;
  symbol: string;
  market_cap: number;
  type: string;
  open: number;
  close: number;
  high: number;
  low: number;
  high_52w: number;
  low_52w: number;
  pe: number;
  div_yield_prc: number;
  value: number;
  highest_bid: number;
  lowest_ask: number;
  full_name: string;
  category: string;
  lowest_bid: number;
  highest_ask: number;
  beta: number;
  avg_value: number;
  last_unit_price: number;
  annualized_return: number;
  manager?: string;
  min_value?: number;
  max_value?: number;
  name?: string;
  day_trade_limit?: number;
  saving_return?: ISavingMFReturn;
  market_id?: MARKET_ID;
}>;

export type IAsset = IStock | IIndex | IETF | IManagedFund;
export type UserProfile = {
  id: string;
  user_id: string;
  username: string;
  first_name: string;
  last_name: string;
  profile_picture: string;
  privacy: string;
  is_verified: string;
};
export type IUserData = {
  count: number;
  user_profiles: [];
};
export interface IGetAssetListPayload {
  search?: string;
  asset_ids?: string[];
  pageCount?: number;
  include_feed?: boolean;
  page?: number;
  market?: MarketName[];
  token?: string;
}

export interface IGetAssetDetailsPayload {
  asset_id: string;
  token?: string | null;
}
export interface IGetAssetListQueryParams {
  search?: string;
  asset_ids?: string[];
  page_count?: number;
  include_feed?: boolean;
  page?: number;
  market?: MarketName[];
  token?: string;
}
export interface IGetAssetListResponse {
  count: number;
  results: IAsset[];
}
export interface ISearchAssetListPayload {
  query?: string;
  page_count?: number;
  page?: number;
  market?: MarketName[];
}
export interface ISearchAssetListResponse {
  count: number;
  results: IAsset[];
}

export interface IUseIPOProps {
  assetId: string;
}

export interface IGetMarketDepthPayload {
  assetId: string;
}

export type IOrderPrice = {
  volume_traded: number;
  order_price: number;
  total_bids?: number;
  total_ask?: number;
  split: number;
  volume_traded_cum_sum: number;
};

export type IGetMarketDepthResponse = {
  bids_per_price: Array<IOrderPrice>;
  asks_per_price: Array<IOrderPrice>;
  total_bids_and_asks: {
    total_bids: number;
    total_asks: number;
  };
};

export type IChart = {
  [timestamp: string]: number;
};

export type IStockChart = Array<{ [timestamp: string]: number }>;

export type IAdvancedChart = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume?: number;
};

export enum CHART_INTERVALS {
  '1D' = '1d',
  '1W' = '1w',
  '1M' = '1M',
  '6M' = '6M',
  '1Y' = '1y',
  'all' = 'all',
}

export enum MOBILE_CHART_TYPES {
  Line = 'LINE',
  Candlestick = 'CANDLESTICK',
}
export enum MOBILE_CHART_INTERVALS {
  '1D' = '1d',
  '1W' = '1w',
  '1M' = '1M',
  '6M' = '6M',
  '1Y' = '1y',
  '2Y' = '2y',
  'ALL' = 'all',
}

export interface IGetChartPayload {
  asset_ids: string[];
  interval: CHART_INTERVALS;
  candlesticks?: boolean;
  market: MarketName;
}

export interface IGetChartResponse {
  [assetId: string]: IChart;
}

export type IGetMFChartPayload = {
  fundName?: string;
  interval: keyof typeof MOBILE_CHART_INTERVALS;
};

export type IGetMFChartResponse = Array<{ [timestamp: string]: number }>;

export type IGetAssetChartPayload = {
  assetId: string;
  interval: keyof typeof MOBILE_CHART_INTERVALS;
  market: MarketName;
};

export type IGetAssetChartResponse = { [assetId: string]: IStockChart };

export interface IConsensusResponse {
  company_name: string; //"Commercial International Bank -Egypt S.A.E"
  consensus_data: IConsensusData;
  symbol: string; // "COMI"
  symbol_code: string; //"EGS60121C018"
}

export interface IConsensusData {
  average_broker_recommendation: string; // "Outperform"
  average_consensus_recommendation: number; //"1.61538"
  last_price_update: string; //"9/16/2020"
  potential_upside: string; // ""
  target_price_estimates: number; //"13.00000"
  target_price_high: number; //"95.5"
  target_price_low: number; //"76.1"
  target_price_mean: number; //"86.3"
  total_buy_recommendations: number; //"4.00000"
  total_hold_recommendations: number; //"2.00000"
  total_no_opinion_recommendations: number; //"0.00000"
  total_outperform_recommendations: number; //"7.00000"
  total_sell_recommendations: number; //"0.00000"
  total_underperform_recommendations: number; //"0.00000"
  factor_score: number; // 3.3
}

export interface FinancialYear {
  net_profit: object; //{fy_3: "6009.1180", fy_2: "7515.5550", fy_1: "9581.9940", fy: "11801.0000" }
  revenue: object; // {fy_3: "19019.0000", fy_2: "28492.7800", fy_1: "37197.5200", fy: "42217.0000" }
  roa: object; // {fy_3: "0.026884", fy_2: "0.02673", fy_1: "0.030073", fy: "0.032367" }
  roe: object; // {fy_3: "0.277724", fy_2: "0.266894", fy_1: "0.269844", fy: "0.242574"}
  year: object; // {fy_3: "2016", fy_2: "2017", fy_1: "2018", fy: "2019"}
}
export interface FinancialQuarter {
  ebitda: object; // {fq_3: "#N/A N/A", fq_2: "#N/A N/A", fq_1: "#N/A N/A", fq: "#N/A N/A"}
  net_profit: object; // {fq_3: "3183.0940", fq_2: "3263.4010", fq_1: "2398.5210", fq: "2599.2840" }
  quarter: object; // {fq_3: "Q3", fq_2: "Q4", fq_1: "Q1", fq: "Q2" }
  quarter_period: object; // {fq_3: "30-Sep-19", fq_2: "31-Dec-19", fq_1: "31-Mar-20", fq: "30-Jun-20" }
  revenue: object; // {fq_3: "10831.8370", fq_2: "10806.4200", fq_1: "10525.0270", fq: "10235.5410" }
  roa: object; // {fq_3: "0.031643909", fq_2: "0.032360277", fq_1: "0.031516938", fq: "0.030062645"}
  roe: object; //{fq_3: "0.251059121", fq_2: "0.242519023", fq_1: "0.241274626", fq: "0.21484944"}
}

export interface IFinancials {
  cash_as_percentage_of_assets: number; //"0.078158632"
  currency: string; //"EGP"
  fisical_year: string; //"12/2019"
  fq: FinancialQuarter; //{quarter_period: {fq_3: "30-Sep-19", fq_2: "31-Dec-19", fq_1: "31-Mar-20", fq: "30-Jun-20"},…}
  fy: FinancialYear;
  latest_financial_period: string; //"6/30/2020"
  latest_quarter: string; // "Q2"
  latest_quarter_date: string; //"30-Jun-2020"
  latest_quarter_year: string; // "6/30/2020"
  net_debt_equity: number; //"-1.3917"
  report_type: string; //"Bank"
  symbol: string; //"COMI EY Equity"
  symbol_code: string; //"EGS60121C018"
  total_assets: number; //"400050.3980"
  total_liabilities: number; //"347186.3280"
}

export interface ISearchAsset {
  name: string;
  symbol: string;
  market: string;
  logo: string;
  is_visible: boolean;
  id: string;
  currency: string;
  asset_class: string;
  alias: string;
}

export interface ISearchResponse {
  count: number;
  assets: ISearchAsset[];
}

export interface ISearchQueryParams {
  query?: string;
  market?: string[];
  page?: number;
  page_count?: number;
}

export interface INewsArticle {
  content: string; //"The EGX will be closed from Thursday 29 April till..."
  created_at: string; // "2021-04-29T11:58:15+02:00"
  id: number; //173483
  link: string;
  source: string; //"thndr"
  title: string; //"EGX Holiday Noitce"
  source_logo: string;
}

export interface INewsResponse {
  results: INewsArticle[];
  count: number;
}
export type IMarketItem = {
  about: string;
  asset_class: string;
  created_at: string;
  feed: IFeed | null;
  has_analysis: boolean;
  has_consensus: boolean;
  has_financials: boolean;
  is_3dp: boolean;
  is_visible: boolean;
  product: string;
  risk_level: number | null;
  symbol: string | null;
  tags: [] | null;
  currency: string;
  id: string;
  logo: string;
  market: string;
  market_indicator_name: string;
  name: string;
};
export type MarketIndictorResults = Promise<{ results: IMarketItem[] }>;

export enum StockStatus {
  ACTIVE = 'A',
  SUSPENDED = 'S',
}
