import {
  useUnleash,
  useIsEnabled,
  useToggleList,
  useGetVariant,
} from './unleash/hooks';
import { UnleashProvider } from './unleash/context';
import { UnleashFeature } from './types';

export const FeatureProvider = UnleashProvider;

export { useUnleash, useToggleList };
export function useFeature(feature_key: UnleashFeature) {
  return useIsEnabled(feature_key);
}
export function useVariant(feature_key: UnleashFeature) {
  return useGetVariant(feature_key);
}
