import { WaitingActions } from '@/constants/waitingActions';

let waitingActionsMap: Partial<Record<keyof WaitingActions, (v: any) => void>> =
  {};

export function getWaitingActions() {
  return waitingActionsMap;
}
export function cleatWaitingActions() {
  waitingActionsMap = {};
}

export function untilWeResolveAction<Action extends keyof WaitingActions>(
  action: Action,
) {
  return new Promise<WaitingActions[Action]>((res) => {
    waitingActionsMap[action] = (_: WaitingActions[Action]) => res(_);
  });
}

export function resolveAction<Action extends keyof WaitingActions>(
  action: Action,
  value?: WaitingActions[Action],
) {
  const resolve = waitingActionsMap[action];
  if (resolve) {
    resolve(value);
    delete waitingActionsMap[action];
  } else {
    throw new Error(`no waiting promise for ${action} to resolve`);
  }
}

export function untilWeResolve<Resolve = void>(): [
  Promise<Resolve>,
  (value: Resolve) => void,
] {
  let resolve = (value: Resolve) =>
    console.warn(`unassigned promise resolution for value ${value}`);
  const untilPromise = new Promise<Resolve>((res) => {
    resolve = res;
  });
  const resolvePromise = (value: Resolve) => resolve(value);

  return [untilPromise, resolvePromise];
}
