import { AxiosRequestConfig } from 'axios';
import { currentSelectedLanguage } from '::platform';
import qs from 'qs';
import { instance } from './instance';

type GetWithTokenArgs = {
  url: string;
  token: string;
  sendLang?: boolean;
  config?: AxiosRequestConfig;
  params?: any;
};

export const getWithToken = <Response>({
  url,
  token,
  sendLang = false,
  config: { headers = {}, ...config } = {},
  params,
}: GetWithTokenArgs) => {
  let allHeaders: Record<string, string> = {
    // FIXME: now we manually set token type [Auth, Bearer], but it should be refactored somehow
    Authorization: token,
    ...headers,
  };

  if (sendLang) {
    allHeaders = {
      ...allHeaders,
      'X-Language': currentSelectedLanguage,
      sessionId: global.sessionID,
    };
  }

  return instance.get<Response>(url, {
    ...config,
    headers: {
      ...allHeaders,
    },
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: false });
    },
  });
};
