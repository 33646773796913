import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { i18n } from 'next-i18next';

import { instance } from './instance';

type GetWithTokenArgs = {
  url: string;
  token: string;
  sendLang?: boolean;
  config?: AxiosRequestConfig;
};

export const getWithToken = <Response>({
  url,
  token,
  sendLang = false,
  config: { headers = {}, ...config } = {},
}: GetWithTokenArgs) => {
  let allHeaders: Record<string, string> = {
    // FIXME: remove conditional token after actions refactor
    Authorization: token.indexOf(' ') > -1 ? token : `Auth ${token}`,
    sessionId: global.sessionID,
    ...headers,
  };

  if (sendLang) {
    allHeaders = {
      ...allHeaders,
      'X-Language': i18n?.language || 'en',
    };
  }

  return instance.get<Response>(url, {
    ...config,
    headers: {
      ...allHeaders,
    },
  });
};
