import { storage } from '@/store/effects/storage';
import {
  AUTH_TOKENS,
  AuthTokenKey,
  ThndrAPIToken,
  AuthTokens,
  InitialAuthTokens,
} from '@/constants/auth';

export async function storeAuthToken(key: AuthTokenKey, token: ThndrAPIToken) {
  const tokens = await getOrInitIfNotStored();
  tokens[key] = token;
  return storage.setItem(AUTH_TOKENS, tokens);
}

export async function loadFromStorage(tokens: Partial<AuthTokens>) {
  const stored = await getOrInitIfNotStored();
  return Object.fromEntries<AuthTokens>(
    Object.entries(stored).map(([key, token]) => [
      key,
      tokens[key] || token || null,
    ]),
  );
}

async function getOrInitIfNotStored() {
  let storedTokens = await storage.getItem<AuthTokens>(AUTH_TOKENS);
  return (storedTokens ||
    (await storage.setItem(AUTH_TOKENS, {
      ...InitialAuthTokens,
    }))) as AuthTokens;
}

export async function clearFromStorage() {
  return storage.removeItem(AUTH_TOKENS);
}
