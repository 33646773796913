import { thndrApi } from '@thndr/services/api';

export const getScoreBreakdownDetails = async (
  asset_id: string
): Promise<IScoreBreakdownDetailsResponse> => {
  const { data } = await thndrApi.get(
    `assets-service/analysis/consensus/${asset_id}/break-down`
  );
  return data.consensus_data;
};

export interface IConsensusResponse {
  company_name: string; //"Commercial International Bank -Egypt S.A.E"
  consensus_data: IScoreBreakdownDetailsResponse;
  symbol: string; // "COMI"
  symbol_code: string; //"EGS60121C018"
}

export interface IScoreBreakdownDetailsResponse {
  roae: IConsensusDataDetails;
  net_income_cagr: IConsensusDataDetails;
  quarter_losses: IConsensusDataDetails;
  debt_equity: IConsensusDataDetails;
  ccc: IConsensusDataDetails;
  avgcfo: IConsensusDataDetails;
  fcfe: IConsensusDataDetails;
  peg: IConsensusDataDetails;
  analyst_upside: IConsensusDataDetails;
  adtv: IConsensusDataDetails;
  dividend_payout: IConsensusDataDetails;
  liquidity_ratio: IConsensusDataDetails;
  capital_adequacy: IConsensusDataDetails;
  factor_score?: number; // 3.3
}
export interface IConsensusDataDetails {
  score?: number;
  weight?: number;
}
