import { useQuery, useQueryClient } from 'react-query';
import { searchAssets } from '../api/search';
import { getTimeTillNextUpdate } from '../util';

const EXPLORE_SEARCH_KEY = 'explore_search';
export const useExploreSearch = ({
  searchQuery,
  market,
  enabled,
}: {
  searchQuery: string;
  market: string;
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();
  const lastUpdatedAt = queryClient.getQueryState([
    EXPLORE_SEARCH_KEY,
    searchQuery,
  ])?.dataUpdatedAt;
  const timeTillNextUpdate = lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 1)
    : 1 * 60 * 1000;
  const { data, isLoading, error } = useQuery({
    queryKey: [EXPLORE_SEARCH_KEY, searchQuery],
    queryFn: () => searchAssets({ market, query: searchQuery }),
    enabled: !!searchQuery && enabled,
    staleTime: timeTillNextUpdate,
    cacheTime: timeTillNextUpdate,
  });
  return {
    data,
    isLoading,
    error,
  };
};
