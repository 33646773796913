import { StripePayload, SubscriptionPlan } from '../types';
import { usePaymentMethod } from '../hooks-old/usePaymentMethod';
import { useMySubs } from '../hooks-old/useMySubs';

export const useCheckout = ({
  subsId,
  isPaymentMethodEnabled,
}: {
  subsId: string;
  isPaymentMethodEnabled: boolean;
}) => {
  const {
    subscriberData,
    createPaymentMethod,
    isLoading: isLoadingPayment,
  } = usePaymentMethod({
    isEnabled: isPaymentMethodEnabled,
  });
  const {
    createSubsctiption,
    upgradeSubscription,
    hasError,
    isLoading,
    success,
    setSuccess,
  } = useMySubs(subsId);
  const checkout = async (
    stripePayload: StripePayload,
    products: SubscriptionPlan[],
    promoCode: string
  ) => {
    try {
      const newProducts = products?.map((product) => {
        return {
          product: product?.product_name,
          frequency: product?.frequency,
          currency: product.currency,
        };
      });

      await createSubsctiption({
        products: newProducts,
        promoCode,
        stripePayload,
        paymentMethodId: !subscriberData?.payment_method?.id
          ? await createPaymentMethod(stripePayload, true)
          : subscriberData?.payment_method?.id,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return {
    checkout,
    upgradeSubscription,
    hasError,
    isLoading: isLoading || isLoadingPayment,
    success,
    setSuccess,
  };
};
