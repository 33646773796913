import { thndrApi } from '@thndr/services/api';
import {
  MOBILE_CHART_INTERVALS,
  IGetAssetChartPayload,
  IGetAssetChartResponse,
} from '../types';

export const getAssetChart = async ({
  assetId,
  interval,
  market,
}: IGetAssetChartPayload) => {
  const { data } = await thndrApi.get<IGetAssetChartResponse>(
    '/assets-service/charts',
    {
      params: {
        asset_ids: assetId,
        option: MOBILE_CHART_INTERVALS[interval],
        market,
      },
    }
  );
  return data;
};
