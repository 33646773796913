import { thndrApi } from '@thndr/services/api';
import {
  MOBILE_CHART_INTERVALS,
  IGetMFChartPayload,
  IGetMFChartResponse,
} from '../types';

export const getMFChart = async ({
  fundName,
  interval,
}: IGetMFChartPayload) => {
  const { data } = await thndrApi.get<IGetMFChartResponse>(
    `/assets-service/charts/mutual-funds/${fundName}`,
    {
      params: {
        fund_name: fundName,
        option: MOBILE_CHART_INTERVALS[interval],
      },
    }
  );
  return data;
};
