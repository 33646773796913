interface IFlagDescription {
  name: string;
  description: string;
  defaultValue: any;
}
enum SYSTEM_FLAGS {
  'canWebTrade' = 'canWebTrade',
}

export const systemFlags: Record<SYSTEM_FLAGS, IFlagDescription> = {
  [SYSTEM_FLAGS.canWebTrade]: {
    name: 'canWebTrade',
    description: 'can web trade',
    defaultValue: false,
  },
};

export const systemFlagValues = process.env.NEXT_PUBLIC___DEV__
  ? {
      [SYSTEM_FLAGS.canWebTrade]: false,
    }
  : {
      [SYSTEM_FLAGS.canWebTrade]: false,
    };

export type SystemFlagValues = typeof systemFlagValues;
