import { derived, Config } from '../../../../src/store/index';

import {
  ComplianceFormLoadingStatus,
  ComplianceFormStatus,
  EligibilityLoadingStatus,
  EligibilityStatus,
  IAccountState,
  ProductName,
  RegistrationStatus,
} from './types';

export const state: IAccountState = {
  eligibilityLoadingStatus: EligibilityLoadingStatus.EMPTY,
  eligibilities: [],
  eligibilityModalData: null,
  agreementFormStatus: ComplianceFormStatus.NEW,
  agreementFormLoadingStatus: ComplianceFormLoadingStatus.EMPTY,
  hasBookedAppointment: false,
  eligibilityStates: derived<
    IAccountState,
    Config['state'],
    Record<ProductName, EligibilityStatus>
  >(
    (_state) =>
      Object.fromEntries(
        Object.values(
          _state.eligibilities,
        ).map(({ product_name, eligibility_status }) => [
          product_name,
          eligibility_status,
        ]),
      ) as Record<ProductName, EligibilityStatus>,
  ),
  eligibilityRegistrationStates: derived<
    IAccountState,
    Config['state'],
    Record<ProductName, RegistrationStatus>
  >(
    (_state) =>
      Object.fromEntries(
        Object.values(
          _state.eligibilities,
        ).map(({ product_name, registration_status }) => [
          product_name,
          registration_status,
        ]),
      ) as Record<ProductName, RegistrationStatus>,
  ),
  // isAgreementFormAccepted: derived<IAccountState, Config['state'], boolean>(
  //   (_state, _global) => {
  //     const complianceStatus = _global?.compliance?.formData?.status;
  //     if (complianceStatus) {
  //       return complianceStatus === ComplianceFormStatus.ACCEPTED;
  //     } else {
  //       return _state.agreementFormStatus === ComplianceFormStatus.ACCEPTED;
  //     }
  //   },
  // ),
  isAgreementFormRejected: derived<IAccountState, Config['state'], boolean>(
    (_state) => _state.agreementFormStatus === ComplianceFormStatus.REJECTED,
  ),
  isAgreementFormUnderReview: derived<IAccountState, Config['state'], boolean>(
    (_state) =>
      _state.agreementFormStatus === ComplianceFormStatus.PENDING_REVIEW,
  ),
};
