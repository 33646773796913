import 'antd/dist/antd.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles/chart-loading.scss';
import { logger } from '@thndr/services/logger';
import React, { useEffect, useMemo } from 'react';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import * as snippet from '@segment/snippet';
import cuid from 'cuid';
import {
  createOvermind,
  createOvermindSSR,
  rehydrate,
  Overmind,
} from 'overmind';
import { FeatureProvider, unleash } from '@/services/feature';
import { Provider } from 'overmind-react';
import { appWithTranslation, i18n } from 'next-i18next';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { config, Config } from '@/store';
import AppErrorBoundary from '@/components/AppErrorBoundary';
import theme from '::theme';
import { GlobalStyle } from '@/globalStyles';
import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { ReactQueryDevtools } from 'react-query/devtools';

import '../styles/globals.css';
import 'chart.js/auto';

const DEFAULT_WRITE_KEY = 'p61eewzPtfKkkTlC088d0odMX44OzZ3H';
const DEFAULT_STALE_TIME = 20 * 60000;

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import { CartProvider } from '@thndr/namespace/subscriptions';
import { QueryClient, QueryClientProvider } from 'react-query';

function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY || DEFAULT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      refetchOnWindowFocus: false,
    },
  },
});
const ThndrApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const mutations = pageProps?.mutations ?? [];
  Chart.register(ChartDataLabels);
  const uniqueId = cuid();
  const prefix = 'WEB-';
  global.sessionID = prefix.concat(uniqueId);
  const overmind = useMemo(() => {
    let overmind: Overmind<Config>;
    // try {
    if (typeof window !== 'undefined') {
      // On the client we just instantiate the Overmind instance and run.
      // the "changePage" action
      overmind = createOvermind(config, {
        devtools: 'localhost:3031',
      });
      overmind.actions.rehydrateState(mutations);
      window['THNDR_STORE_OBJECTS'] = {
        config,
        overmind: overmind,
      };
      overmind.effects.navigation.setTopLevelNavigator(router);
    } else {
      // On the server we rehydrate the mutations to an SSR instance of Overmind,
      // as we do not want to run any additional logic here
      overmind = createOvermindSSR(config);
      rehydrate(overmind.state, mutations);
    }
    return overmind;
    // } catch() {}
  }, []);

  NProgress.configure({ showSpinner: false });
  Router.events.on('routeChangeStart', () => NProgress.start());
  Router.events.on('routeChangeComplete', (url) => {
    overmind.effects.analytics.trackPage(url);
    NProgress.done();
  });
  Router.events.on('routeChangeError', () => NProgress.done());

  useEffect(() => {
    overmind.actions.rehydrateState(pageProps.mutations || []);
  }, [pageProps]);
  useEffect(() => {
    logger.initialize();
  }, []);
  const isRtl = router.locale === 'ar';
  const direction = isRtl ? 'rtl' : 'ltr';
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_PUBLISHABLE_KEY, {
    locale: i18n?.language as StripeElementLocale,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <AppErrorBoundary>
        <FeatureProvider instance={unleash}>
          <ConfigProvider direction={direction}>
            <Elements stripe={stripePromise}>
              <ThemeProvider
                theme={{
                  ...theme,
                  rtl: isRtl,
                }}
              >
                <GlobalStyle />

                <Provider value={overmind}>
                  <CartProvider>
                    <Script
                      id="segment-script"
                      dangerouslySetInnerHTML={{ __html: renderSnippet() }}
                    />
                    <Head>
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
                      />
                    </Head>
                    <Component {...pageProps} />
                  </CartProvider>
                </Provider>
              </ThemeProvider>
            </Elements>
          </ConfigProvider>
        </FeatureProvider>
      </AppErrorBoundary>
    </QueryClientProvider>
  );
};

export default appWithTranslation(ThndrApp);
