import {
  IAsset,
  IChart,
  IConsensusData,
  IFinancials,
  INewsArticle,
  LOADING_STATUS,
} from '../types';
import { IGetAnalytics } from '../constants';

export type AssetsState = {
  recommendations: {
    data: { [assetId: string]: string[] };
    status: LOADING_STATUS;
  };
  anaylsis: {
    data: { [assetId: string]: IGetAnalytics };
    status: LOADING_STATUS;
  };
  assetInfo: {
    data: { [assetId: string]: IAsset };
    status: LOADING_STATUS;
  };
  watchlist: {
    status: LOADING_STATUS;
    data: string[];
  };
  watchlistFullData: { data: { [assetId: string]: IAsset } };
  marketDepth: {
    status: LOADING_STATUS;
    data: { [assetId: string]: any };
  };
  charts: { [assetId: string]: { [interval: string]: IChart } };
  financials: {
    status: LOADING_STATUS;
    data: { [assetId: string]: IFinancials };
  };
  consensus: {
    status: LOADING_STATUS;
    data: { [assetId: string]: IConsensusData };
  };
  search: {
    status: LOADING_STATUS;
    query: string;
    count: number;
    assets: string[];
    page: number;
    page_count: number;
    isVisible: boolean;
  };
  news: {
    status: LOADING_STATUS;
    data: INewsArticle[];
    stockNews: {
      [symbol: string]: {
        count: number;
        currentPageNews: INewsArticle[];
        result: { [page: number]: INewsArticle[] };
      };
    };
  };
  selectedAssetID: string;
};

export const state: AssetsState = {
  recommendations: { data: {}, status: LOADING_STATUS.IDLE },
  anaylsis: { data: {}, status: LOADING_STATUS.IDLE },
  assetInfo: { data: {}, status: LOADING_STATUS.IDLE },
  watchlistFullData: { data: {} },
  watchlist: { data: [], status: LOADING_STATUS.IDLE },
  marketDepth: { data: {}, status: LOADING_STATUS.IDLE },
  consensus: { data: {}, status: LOADING_STATUS.IDLE },
  financials: { data: {}, status: LOADING_STATUS.IDLE },
  charts: {},
  search: {
    status: LOADING_STATUS.IDLE,
    query: '',
    assets: [],
    count: 0,
    page: 0,
    page_count: 0,
    isVisible: false,
  },
  news: {
    status: LOADING_STATUS.IDLE,
    data: [],
    stockNews: {},
  },
  selectedAssetID: '',
};
