import { useEffect, useState } from 'react';
import { useMySubs } from '../hooks-old/useMySubs';
import { Product } from '../types';

export const useIsSubscribedV2 = (product: Product) => {
  const { mySubs } = useMySubs();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  useEffect(() => {
    if (mySubs?.length > 0) {
      const isSubscribed = mySubs.some((subscription) =>
        subscription.products.includes(product)
      );
      setIsSubscribed(isSubscribed);
    }
  }, [mySubs]);
  return isSubscribed;
};
