import { thndrApi } from '@thndr/services/api';
import { PaymentMethodResponse } from '../types';

export const getPaymentMethod = async () => {
  return await thndrApi.get<PaymentMethodResponse>(
    'payment-service/v2/subscribers'
  );
};

export const createPaymentMethod = async () => {
  return true;
};

export const updatePaymentMethod = async () => {
  return true;
};
