import { AxiosRequestConfig } from 'axios';

import { instance } from './instance';

type DeleteWithTokenArgs = {
  url: string;
  token: string;
  config?: AxiosRequestConfig;
};

export const deleteWithToken = <Response = any>({
  token,
  url,
  config: { headers, ...config } = {},
}: DeleteWithTokenArgs) => {
  const allHeaders = {
    // FIXME: remove conditional token after actions refactor
    Authorization: token.indexOf(' ') > -1 ? token : `Auth ${token}`,
    sessionId: global.sessionID,
    ...headers,
  };
  return instance.delete<Response>(url, {
    headers: allHeaders,
    ...config,
  });
};
