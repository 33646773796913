import { instance } from './instance';

export const postWithToken = <Response = any>(
  token: string,
  url: string,
  body,
  options,
) => {
  const headers = {
    // FIXME: remove conditional token after actions refactor
    Authorization: token.indexOf(' ') > -1 ? token : `Auth ${token}`,
    sessionId: global.sessionID,
  };
  return instance.post<Response>(url, body, { headers, ...options });
};
