import { thndrApi } from '@thndr/services/api';
import { useQuery, useQueryClient } from 'react-query';
import { IAsset, IGetAssetListResponse } from '../types';
import { getTimeTillNextUpdate } from '../util';
import qs from 'qs';
import { ASSETS_QUERY_KEYS } from '../query-keys';

const getAssetsDetails = async ({
  asset_id,
  include_feed = true,
  feed_detail = true,
}: {
  asset_id: string[];
  include_feed?: boolean;
  feed_detail?: boolean;
}): Promise<IGetAssetListResponse> => {
  const { data } = await thndrApi.get<IGetAssetListResponse>(
    `/assets-service/assets`,
    {
      params: {
        asset_id,
        include_feed,
        feed_detail,
        page_count: asset_id?.length,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }
  );
  return data;
};

export const useAssetsList = ({
  asset_ids,
  enabled = true,
  onSuccess,
}: {
  asset_ids: string[];
  enabled?: boolean;
  onSuccess?: (data: IGetAssetListResponse) => void;
}) => {
  const queryClient = useQueryClient();
  const lastUpdatedAt = queryClient.getQueryState([
    ASSETS_QUERY_KEYS.assetList,
    asset_ids,
  ])?.dataUpdatedAt;
  const timeTillNextUpdate = lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 15)
    : 15 * 60 * 1000;
  const { data, isLoading } = useQuery({
    queryKey: [ASSETS_QUERY_KEYS.assetList, asset_ids],
    queryFn: () => getAssetsDetails({ asset_id: asset_ids }),
    staleTime: timeTillNextUpdate,
    cacheTime: timeTillNextUpdate,
    enabled,
    onSuccess,
  });

  return {
    data: data?.results as IAsset[],
    isLoading,
  };
};
