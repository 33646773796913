import { FeatureState, FeatureLoadingState } from '@/services/feature/types';
import { Config, derived } from '@/store';

export const state: FeatureState = {
  didFeaturesLoad: FeatureLoadingState.NotReady,
  features: [],
  context: {
    properties: {
      appId: process.env.NEXT_PUBLIC_APP_ID,
      tags: [],
    },
  },
  waitForFeatureList: derived<FeatureState, Config, boolean>(
    (_state) => _state.didFeaturesLoad === FeatureLoadingState.NotReady,
  ),
  lastFeatureUpdate: null,
};
