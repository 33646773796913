/* eslint-disable no-prototype-builtins */
import * as Sentry from '@sentry/nextjs';
import { sendDataToBugsjoy } from './bugsjoy';
import dayjs from 'dayjs';
const globalForLogger = global as typeof globalThis & {
  lastTimestamp: number;
};
export const sentry = {
  init: async () => {
    if (process.env['NEXT_PUBLIC_ENV'] === 'production') {
      try {
        Sentry.init({
          dsn: process.env['NEXT_PUBLIC_SENTRY_DSN'],
          ignoreErrors: [
            'Network request failed',
            'Failed to fetch',
            'NetworkError',
            'Network Error',
            '401',
            'Cannot convert value',
            'Permission denied',
          ],
          attachStacktrace: true,
          tracesSampleRate: 1.0,
          maxBreadcrumbs: 400,
          release: 'thndr-web@1.0.0',
          integrations: (integrations) => {
            return integrations.filter((integration) => {
              return integration.name !== 'Dedupe';
            });
          },

          beforeSend: (event) => {
            if (globalForLogger.lastTimestamp) {
              event.breadcrumbs = event?.breadcrumbs?.filter((elm) =>
                dayjs(dayjs.unix(elm?.timestamp ?? 0)).isAfter(
                  dayjs.unix(globalForLogger.lastTimestamp),
                ),
              );
            }
            sendDataToBugsjoy({
              event: { ...event },
              url: `${process.env['NEXT_PUBLIC_BUGSJOY_URL']}/logs` as string,
              key: process.env['NEXT_PUBLIC_JOY_KEY'] as string,
            });

            globalForLogger.lastTimestamp =
              event.breadcrumbs?.[event.breadcrumbs?.length - 1].timestamp ?? 0;
            event.breadcrumbs = [];
            if (event.exception?.values?.[0]?.value === 'INTERNAL TRIGGER') {
              return null;
            }
            return event;
          },
          beforeBreadcrumb(breadcrumb, hint?) {
            if (
              (breadcrumb.category == 'xhr' ||
                breadcrumb.category == 'fetch') &&
              !hint?.xhr.responseURL.includes(
                process.env['NEXT_PUBLIC_API_URL'],
              )
            ) {
              return null;
            } else if (
              breadcrumb.category == 'xhr' ||
              breadcrumb.category == 'fetch'
            ) {
              const responseData = JSON.parse(hint?.xhr?.response);
              const reqBody = JSON.parse(hint?.xhr?.__sentry_xhr__?.body);
              const data = {
                status: hint?.xhr.status,
                responseUrl: hint?.xhr.responseURL,
                method: breadcrumb?.data?.method,
                url: hint?.xhr.responseURL,
              };
              if (responseData) {
                for (const key in responseData) {
                  if (
                    String(key).match(
                      /token|pin|password|auth_token|request_secret/,
                    )
                  ) {
                    responseData[key] = 'SENSETIVE DATA';
                  }
                }
              }
              if (reqBody) {
                for (const key in reqBody) {
                  if (
                    String(key).match(
                      /token|pin|password|auth_token|request_secret/,
                    )
                  ) {
                    reqBody[key] = 'SENSETIVE DATA';
                  }
                }
                if (reqBody.hasOwnProperty('credentials')) {
                  reqBody.credentials = 'SENSETIVE DATA';
                }
                if (reqBody.hasOwnProperty('data')) {
                  for (const key in reqBody.data) {
                    if (
                      String(key).match(
                        /token|pin|password|auth_token|request_secret/,
                      )
                    ) {
                      reqBody.data[key] = 'SENSETIVE DATA';
                    }
                  }
                }
              }
              return {
                ...breadcrumb,
                data,
                body: reqBody,
                response: responseData,
              };
            } else if (breadcrumb.message === 'INTERNAL TRIGGER') {
              return null;
            } else return breadcrumb;
          },
          autoSessionTracking: true,
          environment: process.env['NEXT_PUBLIC_ENV'],
        });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
