import { IOrder } from '@/models/investor';

export enum ORDER_TYPES {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum ORDER_PRICE_TYPES {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
}
export enum Currencies {
  egypt = 'egp',
  simulator = 'simulator',
  us = 'USD',
}
export type OrdersByStatus = {
  [order_status in ORDER_STATUS]: IOrder[];
};
export enum QUICK_TRADE_VIEWS {
  SEARCH_ASSET = 'SEARCH_ASSET',
  SET_DETAILS = 'SET_DETAILS',
  REVIEW_ORDER = 'REVIEW_ORDER',
  ORDER_SUCCESS = 'ORDER_SUCCESS',
  ORDER_LOADING = 'ORDER_LOADING',
  ORDER_FAILED = 'ORDER_FAILED',
  EDIT_ORDER = 'EDIT_ORDER',
}

export enum ORDER_STATUS {
  QUEUED_SUBMIT = 'QUEUED_SUBMIT',
  QUEUED_CANCEL = 'QUEUED_CANCEL',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
}

export enum ORDER_STATUS_DETAIL {
  PENDING = 'PENDING',
  PENDING_SUBMIT = 'PENDING_SUBMIT',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  PENDING_QUEUED_CANCEL = 'PENDING_QUEUED_CANCEL',
  PENDING_MCDR = 'PENDING_MCDR',
  APPROVED_MCDR = 'APPROVED_MCDR',
  REJECTED_MCDR = 'REJECTED_MCDR',
  FULFILLED = 'FULFILLED',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  PARTIALLY_CANCELLED = 'PARTIALLY_CANCELLED',
  LOCALLY_CANCELLED = 'LOCALLY_CANCELLED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  SUSPENDED = 'SUSPENDED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  EXTERNAL_ERROR = 'EXTERNAL_ERROR',
  TRADE_CANCELLED = 'TRADE_CANCELLED',
  NOT_EXIST = 'NOT_EXIST',
  PROCESSING = 'PROCESSING',
}

export enum ORDER_TIME_IN_FORCE {
  DAY = 'day',
  DATE = 'date',
  GTC = 'gtc',
  OPG = 'opg',
  CLS = 'cls',
  IOC = 'ioc',
  FOK = 'fok',
}

export enum ORDER_EXECUTION {
  NO_FILL = 'NO_FILL',
  MIN_FILL = 'MIN_FILL',
  FILL_OR_KILL = 'FILL_OR_KILL',
  FILL_AND_KILL = 'FILL_AND_KILL',
  ALL_OR_NONE = 'ALL_OR_NONE',
}

export enum ORDER_SETTLEMENT {
  T0 = 'T0',
  T1 = 'T1',
  T2 = 'SETTLED',
  // AUTO = 'AUTO',
}
export enum OrderSubmissionStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Loading = 'LOADING',
}
export const THNDR_FEES = 2;
export const WAIVED_FEES = 0;
export const SAFETY_MARGIN = 0.03;
export const GOV_MARGIN = 0.001;
export const CUSTODY_MARGIN = 0.0005;
export const BLOCKED_MARGIN = 0.002;
export const BLOCKED_AMOUNT = 10;
