import { Product, SubscriptionFrequency, SubscriptionPlan } from '../types';
export function arrayToDict<T>(key: string, arr: T[]) {
  return Object.assign({}, ...arr?.map((item: T) => ({ [item?.[key]]: item })));
}

export const removeByPlanIds = ({
  planIds,
  plans,
}: {
  planIds: string[];
  plans: { [planId: string]: SubscriptionPlan };
}) => {
  return Object.values(plans).reduce(
    (acc, plan) =>
      planIds?.includes(plan.id) ? acc : { ...acc, [plan.id]: plan },
    {}
  );
};
export const removeByProducts = ({
  products,
  plans,
}: {
  products: Product[];
  plans: { [planId: string]: SubscriptionPlan };
}) => {
  return Object.values(plans).reduce(
    (acc, plan) =>
      products?.includes(plan.product_name) ? acc : { ...acc, [plan.id]: plan },
    {}
  );
};

export const filterByFrequency = ({
  activePlans,
  frequency,
}: {
  activePlans: { [planId: string]: SubscriptionPlan };
  frequency: SubscriptionFrequency;
}) => {
  return Object.values(activePlans).reduce((acc, plan) => {
    if (plan.frequency === frequency) {
      acc = { ...acc, [plan.id]: plan };
    }
    return acc;
  }, {});
};

// export const filterByComboStatus = ({
//   plans,
//   isCombo,
// }: {
//   plans: { [planId: string]: SubscriptionPlan };
//   isCombo: boolean;
// }) => {
//   return Object.values(plans).reduce(
//     (acc: { [planId: string]: SubscriptionPlan }, plan: SubscriptionPlan) => {
//       if (plan.is_combo === isCombo) {
//         acc = { ...acc, [plan.id]: plan };
//       }
//       return acc;
//     },
//     {}
//   );
// };

export const removeDeprecatedPlans = ({
  plans,
}: {
  plans: SubscriptionPlan[];
}) => {
  return plans?.reduce((acc: SubscriptionPlan[], plan: SubscriptionPlan) => {
    if (!plan.is_deprecated) {
      acc = [...acc, plan];
    }
    return acc;
  }, []);
};

export const addCartStatus = ({
  plans,
  cartItems,
}: {
  plans: { [planId: string]: SubscriptionPlan };
  cartItems: SubscriptionPlan[];
}) => {
  return Object.values(plans)?.reduce(
    (acc: { [planId: string]: SubscriptionPlan }, plan: SubscriptionPlan) => {
      if (cartItems?.find((item) => item?.id == plan?.id)) {
        if (typeof window !== 'undefined' && cartItems?.length !== 0) {
          localStorage.setItem('userCart', JSON.stringify(cartItems));
        }
        return { ...acc, [plan.id]: { ...plan, inCart: true } };
      }
      if (typeof window !== 'undefined' && cartItems?.length !== 0) {
        localStorage.setItem('userCart', JSON.stringify(cartItems));
      }
      return { ...acc, [plan.id]: plan };
    },
    {}
  );
};
export const updateCartOnFrequencyChange = ({
  cartItems,
  availableItems,
}: {
  cartItems: SubscriptionPlan[];
  availableItems: SubscriptionPlan[];
}) => {
  return cartItems?.reduce(
    (acc: SubscriptionPlan[], cartPlan: SubscriptionPlan) => {
      return availableItems.reduce(
        (acc2: SubscriptionPlan[], availablePlan: SubscriptionPlan) => {
          if (cartPlan.product_name === availablePlan.product_name) {
            if (typeof window !== 'undefined' && cartItems?.length !== 0) {
              localStorage.setItem('userCart', JSON.stringify(cartItems));
            }
            return [...acc2, availablePlan];
          }
          if (typeof window !== 'undefined' && cartItems?.length !== 0) {
            localStorage.setItem('userCart', JSON.stringify(cartItems));
          }
          return acc2;
        },
        acc
      );
    },
    []
  );
};
