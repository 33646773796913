import { http, notice, logger } from '@/store/effects';
import { ApplicationError } from '@/util/errors';

export async function postRequestVerificationEmail(payload: { email: string }) {
  const { data } = await http.instance.post<{ id: string }>(
    `${
      process.env.NEXT_PUBLIC_USE_PRISM == 'true'
        ? `http://127.0.0.1:4011`
        : `/auth-service`
    }/users/`,
    payload,
  );
  return data;
}

export async function postVerifyEmail(payload: { id: string; code: string }) {
  try {
    const { data } = await http.instance.post<{ firebase_auth_token: string }>(
      `${
        process.env.NEXT_PUBLIC_USE_PRISM == 'true'
          ? `http://127.0.0.1:4011`
          : `/auth-service`
      }/users/authorize`,
      payload,
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'errors.auth.postVerifyEmail');
  }
}
