import AsyncStorage from 'localforage';

export const storage = {
  async setItem(key: string, value: any) {
    return AsyncStorage.setItem(key, value);
  },
  async getItem<T = any>(key: string): Promise<T | null> {
    return AsyncStorage.getItem<T>(key);
  },
  async removeItem(key: string) {
    return AsyncStorage.removeItem(key);
  },
  async clearStorage() {
    return AsyncStorage.clear();
  },
};
