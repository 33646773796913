import { http } from '@/store/effects';
import { IMarketStocks, IStockDetail } from '@/models/types';
import { AsyncRequestWithToken } from '@/util/types';
import { ApplicationError } from '@/util/errors';
import { CHART_INTERVALS } from '@/constants/market';
import { IAdvancedChart, IBidsAndAsks, IChart } from '@/models/market';

export const getMarketData: AsyncRequestWithToken<void, IMarketStocks> = async (
  token,
) => {
  try {
    const { data } = await http.getWithToken<IStockDetail[]>({
      token,
      url: `/api/feed/market`,
    });

    return Object.fromEntries(data.map((stock) => [stock.symbol, stock]));
  } catch (error) {
    throw new ApplicationError(error, 'errors.market.getMarketData');
  }
};

interface IGetChartPayload {
  assetIds: string[];
  interval: CHART_INTERVALS;
  candlesticks?: boolean;
}

interface IGetChartResponse {
  [assetId: string]: IChart;
}

export const getChart = async (
  token: string,
  { assetIds, interval, candlesticks }: IGetChartPayload,
) => {
  const params = {
    asset_ids: assetIds,
    option: interval,
    candlesticks: Boolean(candlesticks),
  };

  try {
    const { data } = await http.getWithToken<IGetChartResponse>({
      token,
      url: `/assets-service/charts`,
      config: {
        params,
      },
    });
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'ERROR');
  }
};

interface IGetAdvancedChartPayload {
  asset_id: string;
  resolution: string;
  from_timestamp: number;
  to_timestamp: number;
  next_date?: number;
}

interface IGetAdvancedChartResponse {
  points: IAdvancedChart[];
  next_date?: number;
}

export const getAdvancedChart = async (
  token: string,
  {
    asset_id,
    resolution,
    from_timestamp,
    to_timestamp,
  }: IGetAdvancedChartPayload,
) => {
  const params = {
    asset_id,
    resolution,
    from_timestamp: from_timestamp * 1000,
    to_timestamp: to_timestamp * 1000,
  };
  try {
    const { data } = await http.getWithToken<IGetAdvancedChartResponse>({
      token,
      url: `/assets-service/charts/advanced`,
      config: {
        params,
      },
    });
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'ERROR');
  }
};

interface IGetMarketDepthPayload {
  assetId: string;
}
type IGetMarketDepthResponse = IBidsAndAsks;

export const getMarketDepth = async (
  token: string,
  { assetId }: IGetMarketDepthPayload,
) => {
  try {
    const { data } = await http.getWithToken<IGetMarketDepthResponse>({
      token,
      url: `/assets-service/market-depth/${assetId}`,
    });
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.market.getMarketDepth');
  }
};
