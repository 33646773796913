import { thndrApi } from '@thndr/services/api';
import { Subscription, SubscriptionProvider } from '../types';

export const getSubscriptions = async () => {
  return await thndrApi.get<{
    count: number;
    results: Subscription[];
    provider?: SubscriptionProvider;
  }>('payment-service/v2/subscriptions');
};

export const getSubscription = async (subscriptionId: string) => {
  return await thndrApi.get<Subscription>(
    `payment-service/v2/subscriptions/${subscriptionId}`
  );
};

export const createSubscription = async () => {
  return await thndrApi.post('payment-service/v2/subscriptions');
};

export const cancelSubscription = async (subscriptionId: string) => {
  return await thndrApi.post(
    `payment-service/v2/subscriptions/${subscriptionId}/cancel`
  );
};

export const reActivateSubscription = async () => {
  return await thndrApi.get('payment-service/v2/subscriptions');
};
