export enum USER_TYPES {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export enum USER_INFO_STATUS {
  IDLE = 'IDLE',
  LOADING_INFO = 'LOADING_INFO',
  LOADING_INFO_ERROR = 'LOADING_INFO_ERROR',
  LOADING_INFO_SUCCESS = 'LOADING_INFO_SUCCESS',
  CREATING_USER = 'CREATING_USER',
  UPGRADING_USER = 'UPGRADING_USER',
  AUTHENTICATING_USER = 'AUTHENTICATING_USER',
  SQ_RECOVERY = 'SQ_RECOVERY',
  SETTING_PIN = 'SETTING_PIN',
  FULL_RECOVERY = 'FULL_RECOVERY',
  UNVERIFIED_RECOVERY = 'UNVERIFIED_RECOVERY',
}

export interface IUserInfo {
  email: string;
  has_active_recovery: boolean;
  name: string;
  phone_number: string;
  type: USER_TYPES;
  uid: string;
  username: string | null;
  has_pin: boolean;
  has_password: boolean;
  has_security_question: boolean;
  alpaca_id: string | null;
}
