import { instance } from './instance';

export const patchWithToken = <Response = any>(
  token: string,
  url: string,
  body: object,
  options: any
) => {
  const headers = {
    // FIXME: now we manually set token type [Auth, Bearer], but it should be refactored somehow
    Authorization: token,
    sessionId: global.sessionID,
  };
  return instance.patch<Response>(url, body, { headers, ...options });
};
