import { IFee, IOrder, IOwnedAsset } from '@/models/investor';
import { Config, derived } from '@/store';

export type IInvestorState = {
  isLoadingInvestorData: boolean;
  isSubmittingOrder: boolean;
  investorIntervalId: NodeJS.Timeout | null;
  isMarketOpen: boolean | null;
  fees: {
    error: boolean;
    data: Array<IFee> | null;
    isLoading: boolean;
  };
  purchasingPower: {
    isLoading: boolean;
    value: number;
  };
  cashInHolding: {
    isLoading: boolean;
    value: number;
  };
  orders: {
    isLoading: boolean;
    data: IOrder[];
  };
  shouldUnlockInvestor: boolean;
};

export const state: IInvestorState = {
  isLoadingInvestorData: false,
  isSubmittingOrder: false,
  investorIntervalId: null,
  isMarketOpen: null,
  fees: {
    error: false,
    data: null,
    isLoading: false,
  },
  purchasingPower: {
    isLoading: false,
    value: null,
  },

  cashInHolding: {
    isLoading: false,
    value: null,
  },
  orders: {
    isLoading: false,
    data: [],
  },
  shouldUnlockInvestor: derived<IInvestorState, Config['state'], boolean>(
    (_, globalState) =>
      globalState.user.isBasic || globalState.auth.isUnverified,
  ),
};
