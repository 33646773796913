import { instance } from './instance';

export const patchWithToken = <Response = any>(
  token: string,
  url: string,
  body: object,
  options,
) => {
  const headers = {
    // FIXME: remove conditional token after actions refactor
    Authorization: token.indexOf(' ') > -1 ? token : `Auth ${token}`,
    sessionId: global.sessionID,
  };
  return instance.patch<Response>(url, body, { headers, ...options });
};
