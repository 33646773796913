import { AuthErrorMap, AuthErrorTypes } from '@/constants/error-types';
import { logger } from '@/store/effects';

export class ApplicationError extends Error {
  originalError: Error;
  code: string;
  name = 'ApplicationError';
  constructor(error: Error, code = 'errors.unknown') {
    super(`${code} > ${ApplicationError.getMessages(error)}`);
    this.code = code;
    this.originalError = error;
  }

  unWrapError(): Error {
    if (this.originalError instanceof ApplicationError) {
      return this.unWrapError();
    }
    return this.originalError;
  }

  static getMessages(error: Error): string {
    if (error instanceof ApplicationError) {
      return error.messages;
    }
    return `${error.name}: ${error.message}`;
  }

  get messages(): string {
    if (this.originalError instanceof ApplicationError) {
      return `${this.code} > ${this.originalError.messages}`;
    }
    return this.originalError.message;
  }
}

export class InvalidInputError extends Error {
  code: string;
  constructor(message: string, code: string = 'errors.invalidInput') {
    super(`InvalidInputError: ${message}`);
    this.code = code;
  }
}
export class BackendValidationError extends Error {
  code: string;
  field?: string;
  constructor(code: string = 'errors.invalidValue', field?: string) {
    super(`BackendValidationError: ${code}`);
    this.code = code;
    this.field = field;
  }
}
export class AuthError extends Error {
  type: string;
  constructor(
    type: AuthErrorTypes = AuthErrorTypes.UNAUTHORIZED_USER,
    message: string,
  ) {
    super(`AuthError: ${type}`);
    this.type = type;
    this.message =
      AuthErrorMap?.[type] !== 'errors.auth.somethingWentWrong'
        ? AuthErrorMap[type]
        : message;
  }
}

export class PrivilegedTokenNeedsRenewError extends Error {
  message = 'PrivilegedTokenNeedsRenewError';
}
export class MarketClosedError extends Error {
  message = 'MarketClosedError';
}

export class NoConnectionError extends Error {
  message = 'NoConnectionError';
}
export class ClearTokenError extends Error {
  message = 'ClearTokenError';
}

export class NavigationNotMountedError extends Error {
  constructor(message: string) {
    super(`NavigationNotMountedError: ${message}`);
  }
}

export class HandledError extends Error {
  constructor(handleLocation: string) {
    super(handleLocation);
    logger.info(`HandledError: ${handleLocation}`);
  }
}
export class UploadFileError extends Error {
  constructor(name: string) {
    super(name);
    logger.info(`UploadFileError: ${name}`);
  }
}
export class AccountOpeningFormUpdateError extends Error {
  message = 'AccountOpeningFormUpdateError';
}
