export enum STOCK_PRICE_RANK {
  SIGNIFICANTLY_UNDERVALUED = 'SIGNIFICANTLY_UNDERVALUED',
  UNDERVALUED = 'UNDERVALUED',
  FAIR = 'FAIR',
  SIGNIFICANTLY_OVERVALUED = 'SIGNIFICANTLY_OVERVALUED',
  OVERVALUED = 'OVERVALUED',
}

export interface IGetAnalytics {
  company_id: string;
  last_updated_time: string;
  fair_value: number;
  potential: number;
  rate: STOCK_PRICE_RANK;
  analyst_report: {
    name: string;
    title: string;
    last_updated_time: string;
    bull_say_list: string[];
    bear_say_list: string[];
  };
  report_url?: string;
}
export enum STOCK_REVIEW_TYPE {
  GOOD = 'GOOD',
  BAD = 'BAD',
}

export interface IGetAnalyticsReport {
  report_url: string;
}

export interface IGetAnalyticsReportPayload {
  assetId: string;
  token: string;
}

export const GoldIndexId = '101ffe7a-1024-4772-a20a-7d04a82f0ee4';

export enum COMMODITIES_LIST_KEYS {
  GOLD = 'MGED.CA',
}
