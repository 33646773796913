import type { SystemFlagValues } from '@/constants/flags';

import { Config, derived } from '@/store';
import { systemFlagValues } from '@/constants/flags';
import { MARKETS } from '@/constants/market';

export type ISettingsState = {
  activeTheme: 'light' | 'dark' | undefined | string;
  passphraseEnabled: boolean;
  isLoaded: boolean;
  systemFlagValues: SystemFlagValues;
  userFlagValues: Partial<SystemFlagValues>;
  flagValues: Partial<SystemFlagValues>;
  virtualMarket: boolean;
};

export const state: ISettingsState = {
  activeTheme: 'light',
  passphraseEnabled: true,
  isLoaded: false,
  virtualMarket: true,
  systemFlagValues,
  userFlagValues: {},
  flagValues: derived<ISettingsState, Config['state'], SystemFlagValues>(
    (_) => {
      return {
        ..._.systemFlagValues,
        ..._.userFlagValues,
      };
    },
  ),
};
