import axios from 'axios';
import { BASEURL } from './base-url';
import { getToken } from './tokens';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { currentSelectedLanguage } from '::platform';

const thndrApi = axios.create({
  baseURL: BASEURL,
});
thndrApi.interceptors.request.use(
  async (config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept = 'application/json';
    config.headers.authorization = `Bearer ${await getToken()}`;
    config.headers.sessionId = (global as any).sessionID;
    config.headers['X-Language'] = currentSelectedLanguage;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

thndrApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // here we can have a switch statement to handle different errors
    if (error.response.status === 500) {
      // we could show the user a generic error here
      console.log('error', error);
    }
    return Promise.reject(error);
  }
);

export default thndrApi;
