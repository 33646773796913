import { initializeApp, getApps, getApp } from 'firebase/app';
const firebaseConfig = {
  apiKey: 'AIzaSyBL6Jw0d9CfsfNyrmQTBNIrEOvrRA3d1-I',
  authDomain: 'thndr-api.firebaseapp.com',
  databaseURL: 'https://thndr-api.firebaseio.com',
  projectId: 'thndr-api',
  storageBucket: 'thndr-api.appspot.com',
  messagingSenderId: '639172574829',
  appId: '1:639172574829:web:1327cecba400576172c964',
  measurementId: 'G-DVGF4HGG45',
};

export const firebase = {
  initialize() {
    // Initialize Firebase
    if (getApps().length < 1) {
      initializeApp(firebaseConfig);
    }
  },
  app() {
    return getApp();
  },
};
