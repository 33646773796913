import { logger } from './logger';

export const exceptions = {
};

function handleJS(error: Error, isFatal: boolean) {
  logger.info('Un-cought exception', isFatal);
  if (isFatal) {
    logger.recordError(error);
  } else {
    logger.info(error.message);
  }
}
function handleNative(exceptionString: string) {
  logger.recordError(Error(exceptionString));
}
