import { IMarketStocks, IStockDetail } from '@/models/types';
import { derived, Config } from '@/store';
import { IAdvancedChart, IBidsAndAsks, IChart } from '@/models/market';

type MarketState = {
  isLoading: boolean;
  data: Partial<IMarketStocks>;
  currentSymbol: string | null;
  currentStockDetail: IStockDetail | null;
  chartLabels: Array<string>;
  chartData: Array<string>;
  chartOption: string | null;
  charts: { [assetId: string]: { [interval: string]: IChart } };
  marketDepth: {
    isLoading: boolean;
    data: { [id: string]: IBidsAndAsks };
  };
};

export const state: MarketState = {
  isLoading: false,
  data: {},
  charts: {},
  marketDepth: {
    isLoading: false,
    data: {},
  },
  chartOption: null,
  chartData: [],
  chartLabels: [],
  currentSymbol: null,
  currentStockDetail: derived<
    MarketState,
    Config['state'],
    IStockDetail | null
  >((_state) =>
    _state.currentSymbol ? _state.data[_state.currentSymbol] || null : null,
  ),
};
