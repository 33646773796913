import { useActions, useStates } from '@/store';
import { CHART_INTERVALS } from '@/constants/market';
import { useMarket } from '@thndr/namespace/market';
import { MarketName } from '@thndr/namespace/market';

export const useCharts = () => {
  const { newAssets } = useStates();
  const { newAssets: assetActions } = useActions();

  const getCharts = async ({
    asset_id,
    interval,
    market,
  }: {
    market: MarketName;
    asset_id: string;
    interval: CHART_INTERVALS;
  }) => {
    await assetActions.getChart({ asset_id, interval, market });
  };
  const getAdvancedTVChart = async ({
    asset_id,
    resolution,
    from_timestamp,
    to_timestamp,
  }: {
    asset_id: string;
    resolution: string;
    from_timestamp: number;
    to_timestamp: number;
  }) => {
    const advanced_charts = await assetActions.getAdvancedTVChart({
      asset_id,
      resolution,
      from_timestamp,
      to_timestamp,
    });
    return advanced_charts;
  };

  return {
    charts: newAssets.charts,
    getCharts,
    getAdvancedTVChart,
  };
};
