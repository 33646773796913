import { useActions, useStates } from '::store';

const useSimulator = () => {
  const {
    newMarket: { disableSimulator, enableSimulator },
  } = useActions();

  const {
    newMarket: { simulatorEnabled },
  } = useStates();

  return {
    disableSimulator,
    enableSimulator,
    simulatorEnabled,
  };
};

export default useSimulator;
