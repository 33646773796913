import { derived, Config } from '::store';
import { ISubscriptionResponse } from '../types';
type ISubscriptionState = {
  subscriptions: ISubscriptionResponse[];
  canRealTime: boolean;
};

export const state: ISubscriptionState = {
  canRealTime: derived<ISubscriptionState, Config['state'], boolean>(
    (s) => s.subscriptions.length > 0,
  ),
  subscriptions: [],
};
