// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useStates, useEffects } from '::store';
import { useThndrMutation } from '@thndr/services/api';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SUBS_QUERY_KEYS } from '../../query-keys';
import { getPaymentMethod } from '../api/payment-method';
import { StripePayload, PAYMENT_METHOD_STATUS } from '../types';
type createPayementMethodResponse = {
  client_secret: string;
};
export const usePaymentMethod = ({
  isEnabled = true,
}: { isEnabled?: boolean } = {}) => {
  const {
    navigation: { back },
  } = useEffects();
  const query = useQueryClient();

  const {
    data,
    isError: isPaymentInfoError,
    isLoading: isLoadingPaymentInfo,
  } = useQuery(SUBS_QUERY_KEYS.paymentMethod, getPaymentMethod, {
    retry: 1,
    enabled: isEnabled,
  });

  const paymentMethod = data?.data?.payment_method;

  const setPaymentMethod = async (callback?: () => void) => {
    const result = await getPaymentMethod();
    const id = result?.data?.payment_method?.id;
    if (id !== paymentMethod?.id) {
      callback && callback();
      setIsLoading(false);
      query.invalidateQueries(SUBS_QUERY_KEYS.paymentMethod);
      return;
    } else {
      setTimeout(async () => {
        return await setPaymentMethod(callback);
      }, 3000);
    }
  };
  const [hasError, setHasError] = useState<{ isError: boolean; key: string }>({
    isError: false,
    key: '',
  });
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useStates();
  const {
    mutateAsync: mutatePaymentMethod,
    isLoading: isCreatingPaymentMethod,
    isError: isPaymentMethodError,
  } = useThndrMutation<createPayementMethodResponse>({
    path: 'payment-service/v2/subscribers/payment-method',
    type: 'post',
  });

  const createPaymentMethod = async (
    payload: StripePayload,
    checkout: boolean
  ) => {
    try {
      setIsLoading(true);
      const paymentMethod = await payload.stripe.createPaymentMethod({
        type: 'card',
        card: payload.elements.getElement(payload.CardElement),
        billing_details: {
          name: user.name
            ? user.name
            : ((user.first_name + ' ' + user.last_name) as string),
          email: user.email as string,
          phone: user.phone as string,
        },
      });
      if (paymentMethod.error) {
        setHasError({ isError: true, key: 'changePayment.invalid' });
        return null;
      }
      const paymentMethodId = paymentMethod['paymentMethod']['id'];
      const clientSecret = (await mutatePaymentMethod({}))?.client_secret;
      const setupIntent = await payload.stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethodId,
      });
      if (setupIntent?.setupIntent?.status == PAYMENT_METHOD_STATUS.OK) {
        //setSuccess(true);
        if (checkout) {
          await setPaymentMethod();
        } else {
          await setPaymentMethod(back);
        }
        return paymentMethodId;
      } else {
        setHasError({ isError: true, key: 'paymentMethodError.setup' });
        setIsLoading(false);
      }
      return null;
    } catch (error) {
      setIsLoading(false);

      console.error(error);
      setHasError({ isError: true, key: 'paymentMethodError.creation' });
    }
  };

  return {
    paymentMethodInfo: data?.data?.payment_method,
    subscriberData: data?.data,
    isCreatingPaymentMethod,
    isPaymentMethodError,
    isPaymentInfoError,
    isLoadingPaymentInfo,
    createPaymentMethod,
    hasError,
    success,
    isLoading,
  };
};
