import { Action, AsyncAction, Context, json } from '@/store';
import { ApplicationError } from '@/util/errors';
import Routes from '@/constants/routes';
import { i18n } from '@/store/effects';
import { UserCredential } from 'firebase/auth';

export const clear: Action = ({ state }: Context) => {
  Object.assign(state.accountAccess, {
    preLoginState: null,
    postLoginState: null,
    isLoadingFacebook: false,
    isLoadingGoogle: false,
    isLoadingApple: false,
    isLoadingRequestEmailVerification: false,
    isLoadingVerifyEmail: false,
    isLoadingUpdatingAccountInfo: false,
    signedInWithSocial: false,
    signedInWithEmail: false,
    emailVerifyId: null,
    email: '',
    name: '',
    photoURL: '',
  });
};

export const capturePreLoginState: AsyncAction = async ({
  state,
  actions,
  effects,
}) => {
  const { redirect, ...query } = effects.navigation.getQuery();
  state.accountAccess.preLoginState = {
    redirectUrl: redirect,
    query: query,
  };

  const queryTest = Object.keys(query)[0];
  if (queryTest === Routes.COMPENSATIONS) {
    state.accountAccess.postLoginState = Routes.COMPENSATIONS;
    localStorage.setItem('postLoginState', Routes.COMPENSATIONS);
  } else if (redirect && REDIRECT_TO_ACTION_STRATEGY[redirect]) {
    state.accountAccess.postLoginState = REDIRECT_TO_ACTION_STRATEGY?.[
      redirect
    ](query) ?? {
      action: 'navigateToGenericRoute',
      payload: {
        redirectUrl: redirect,
        query,
      },
    };
    if (await actions.auth.hasFirebaseToken()) {
      actions.accountAccess.continueToNextPage();
    }
  }
};

export const continueWithEmail: AsyncAction<string> = async (
  { state, effects },
  email,
) => {
  state.accountAccess.isLoadingRequestEmailVerification = true;
  try {
    state.accountAccess.email = email;
    const { id } = await effects.accountAccess.postRequestVerificationEmail({
      email,
    });
    state.accountAccess.emailVerifyId = id;
  } catch (error) {
    console.log(error?.response?.data);
    if (error?.response?.data?.detail?.msg === 'User not found.') {
      state.accountAccess.isNewUser = true;
    } else {
      effects.notice.showErrorMessage({
        title: i18n?.t('errors.accountAccess.failedToCreateUser'),
      });
    }
    throw new ApplicationError(error, 'errors.accountAccess.continueWithEmail');
  } finally {
    state.accountAccess.isLoadingRequestEmailVerification = false;
  }
};
export const resendEmail: AsyncAction = async ({ state, effects }) => {
  state.accountAccess.isLoadingRequestEmailVerification = true;
  try {
    const { id } = await effects.accountAccess.postRequestVerificationEmail({
      email: state.accountAccess.email,
    });
    state.accountAccess.emailVerifyId = id;
  } catch (error) {
    throw new ApplicationError(error, 'errors.accountAccess.resendEmail');
  } finally {
    state.accountAccess.isLoadingRequestEmailVerification = false;
  }
};

export const verifyEmail: AsyncAction<string> = async (
  { state, effects, actions },
  code,
) => {
  state.accountAccess.isLoadingVerifyEmail = true;
  try {
    const { firebase_auth_token } = await effects.accountAccess.postVerifyEmail(
      {
        id: state.accountAccess.emailVerifyId,
        code,
      },
    );
    const firebaseUser = await effects.auth.signInWithToken(
      firebase_auth_token,
    );
    await actions.accountAccess.continueWithFirebaseCredential(firebaseUser);
  } catch (error) {
    if (error?.originalError?.response?.status === 401) {
      effects.notice.showErrorMessage({
        title: i18n?.t('errors.accountAccess.invalidCode'),
        description: error?.originalError?.response?.data?.detail?.msg,
      });
      state.accountAccess.isLoadingVerifyEmail = false;
    } else {
      throw new ApplicationError(error, 'errors.accountAccess.verifyEmail');
    }
  }
};

export const setIsAppleSigninPossible: Action<boolean> = (
  { state },
  isPossible,
) => {
  state.accountAccess.isAppleSigninPossible = isPossible;
};

export const continueWithGoogle: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  state.accountAccess.isLoadingGoogle = true;
  try {
    const firebaseUser = await effects.auth.signInWithGoogle();
    state.accountAccess.email = firebaseUser.user.email;
    state.accountAccess.signedInWithSocial = true;
    await actions.accountAccess.continueWithFirebaseCredential(firebaseUser);
  } catch (error) {
    throw new ApplicationError(
      error,
      'errors.accountAccess.continueWithGoogle',
    );
  } finally {
    state.accountAccess.isLoadingGoogle = false;
  }
};

export const continueWithFacebook: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  state.accountAccess.isLoadingFacebook = true;
  try {
    const firebaseUser = await effects.auth.signInWithFacebook();
    state.accountAccess.signedInWithSocial = true;
    await actions.accountAccess.continueWithFirebaseCredential(firebaseUser);
  } catch (error) {
    throw new ApplicationError(
      error,
      'errors.accountAccess.continueWithFacebook',
    );
  } finally {
    state.accountAccess.isLoadingFacebook = false;
  }
};

export const continueWithApple: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  state.accountAccess.isLoadingApple = true;
  if (
    !(await effects.notice.binaryConfirm({
      title: i18n.t('continueWithApple', { ns: 'accountAccess' }),
      message: i18n.t('confirmHasAppleAccountBody', { ns: 'accountAccess' }),
    }))
  ) {
    state.accountAccess.isLoadingApple = false;
    return;
  }

  try {
    const firebaseUser = await effects.auth.signInWithApple();
    state.accountAccess.email = firebaseUser.user.email;
    state.accountAccess.signedInWithSocial = true;
    await actions.accountAccess.continueWithFirebaseCredential(firebaseUser);
  } catch (error) {
    throw new ApplicationError(error, 'errors.accountAccess.continueWithApple');
  } finally {
    state.accountAccess.isLoadingApple = false;
  }
};

export const redirectToLogin: AsyncAction = async ({
  state,
  actions,
  effects,
}) => {
  actions.accountAccess.populateRedirectState();
  effects.navigation.push(Routes.ACCOUNT_ACCESS);
};

export const navigateToCorporate: AsyncAction = async ({ effects }) => {
  effects.navigation.push(Routes.CORPORATE);
};

export const navigateToCommunity: AsyncAction = async ({ effects }) => {
  window.location.pathname = Routes.COMMUNITY;
};
export const navigateToGenericRoute: AsyncAction<{ redirectUrl }> = async (
  { effects },
  { redirectUrl },
) => {
  effects.navigation.push(redirectUrl);
};

export const setSocialAttribute: Action<boolean> = ({ state }, socialQuery) => {
  state.accountAccess.social = socialQuery;
};

export const continueWithFirebaseCredential: AsyncAction<
  UserCredential
> = async ({ state, actions, effects }, credentials) => {
  try {
    await effects.auth.getFirebaseToken();
    const user = await actions.user.loadUser();
    user.email && actions.feature.setEmail(user.email);
    state.accountAccess.email = credentials.user.email || state.user.email;
    state.accountAccess.name = credentials.user.displayName || state.user.name;
    actions.accountAccess.continueToNextPage();
  } catch (err) {
    if (err?.originalError?.response?.status === 404) {
      // actions.accountAccess.continueToNextPage();
      await actions.auth.clearAuthData();
      state.accountAccess.isNewUser = true;
    }
  }
};

export const syncAccountInfo: AsyncAction = async ({
  state,
  actions,
  effects,
}) => {
  await actions.user.syncUserInfo();
  const firebaseUser = await effects.auth.getFirebaseUser();
  state.accountAccess.email = firebaseUser?.email || state.user.email;
  state.accountAccess.name = firebaseUser?.displayName || state.user.name;
};

interface updateAccountInfo {
  name?: string;
  photoURL?: string;
  email?: string;
  first_name: string;
  last_name: string;
}
export const updateAccountInfo: AsyncAction<updateAccountInfo> = async (
  { state, actions, effects }: Context,
  payload,
) => {
  state.accountAccess.isLoadingUpdatingAccountInfo = true;
  try {
    const {
      shouldUpdateUser,
      userPayload,
      shouldUpdateFirebase,
      firebasePayload,
    } = getUpdateAccountPayloads(payload);
    state.accountAccess.email = payload.email || state.accountAccess.email;
    state.accountAccess.name = payload.name || state.accountAccess.name;
    await Promise.all([
      shouldUpdateUser && actions.user.postInfo(userPayload),
      // actions.user.updateUsername(userPayload?.username),
      shouldUpdateFirebase && effects.auth.updateFirebaseUser(firebasePayload),
    ]);
    await actions.user.loadUser();
  } finally {
    state.accountAccess.isLoadingUpdatingAccountInfo = false;
  }
};

function getUpdateAccountPayloads({
  email,
  name,
  first_name,
  last_name,
}: updateAccountInfo) {
  const userPayload = Object.assign({}, { email }, { first_name, last_name });
  const shouldUpdateUser = Object.keys(userPayload).length > 0;
  const firebasePayload = Object.assign({}, { displayName: name });
  const shouldUpdateFirebase = Object.keys(firebasePayload).length > 0;

  return {
    shouldUpdateUser,
    userPayload,
    shouldUpdateFirebase,
    firebasePayload,
  };
}

export const redirectAfterUpdateAccountInfo: AsyncAction = async ({
  state,
  actions,
  effects,
}) => {
  const isWebView = state.auth.isWebView;
  if (!isWebView && state.accountAccess.hasMissingInfo) {
    actions.accountAccess.populateRedirectState();
    effects.navigation.push(Routes.ACCOUNT_ACCESS_CONFIRM_INFO);
  }
};

export const populateRedirectState: Action = ({ state, effects }) => {
  const query = effects.navigation.getQuery();
  const redirect = effects.navigation.pathname as Routes;
  state.accountAccess.preLoginState = {
    redirectUrl: redirect,
    query: query,
  };
  if (REDIRECT_TO_ACTION_STRATEGY[redirect]) {
    state.accountAccess.postLoginState =
      REDIRECT_TO_ACTION_STRATEGY[redirect](query);
  }
};

export const continueToNextPage: AsyncAction = async ({
  state,
  actions,
  effects,
}) => {
  const isCompenstation =
    localStorage.getItem('postLoginState') === Routes.COMPENSATIONS;

  if (state.accountAccess.hasMissingInfo) {
    effects.navigation.push(Routes.ACCOUNT_ACCESS_CONFIRM_INFO);
  } else if (isCompenstation) {
    await effects.navigation.replace(Routes.COMPENSATIONS);
    localStorage.removeItem('postLoginState');
  } else if (!state.accountAccess.postLoginState) {
    await effects.navigation.replace(Routes.INVEST);
    state.accountAccess.isLoadingVerifyEmail = false;
  } else if (state.accountAccess.postLoginState) {
    if (state.accountAccess.postLoginState === Routes.COMPENSATIONS)
      effects.navigation.replace(Routes.COMPENSATIONS);
    else {
      const redirectState = json(state.accountAccess.postLoginState);
      state.accountAccess.postLoginState = null;
      actions.accountAccess[redirectState.action](redirectState.payload);
    }
  }
};

const REDIRECT_TO_ACTION_STRATEGY = {
  [Routes.SUBSCRIPTIONS_TRIAL]: (payload) => ({
    action: 'navigateToNewSubscription',
    payload,
  }),
  [Routes.SUBSCRIPTIONS]: (payload) => ({
    action: 'navigateToManageSubscriptions',
    payload,
  }),
  [Routes.SUBSCRIPTIONS_CONFIRM]: (payload) => ({
    action: 'navigateToConfirm',
    payload,
  }),
  [Routes.CORPORATE]: (payload) => ({
    action: 'navigateToCorporate',
    payload,
  }),
  [Routes.COMMUNITY]: (payload) => ({
    action: 'navigateToCommunity',
    payload,
  }),
};
