import { IAsset } from '../types';
import { thndrApi } from '@thndr/services/api';

export const getTrendingSecurities = async ({
  market,
}: {
  market: string;
}): Promise<{ results: Partial<IAsset[]> }> => {
  const { data } = await thndrApi.get(
    `/assets-service/assets/trending?market=${market}&feed_detail=true&include_feed=true`
  );
  return data;
};
