import * as navigationActions from './actions/navigation';

import { derived, Config } from '@/store';
import Routes from '@/constants/routes';

export type AccountAccessNavigationActions = keyof typeof navigationActions;

export type preLoginState = {
  redirectUrl?: Routes;
  query?: object;
};
export type postLoginState = {
  action: AccountAccessNavigationActions;
  payload?: object;
};

export type IAccountAccessState = {
  preLoginState: null | preLoginState;
  postLoginState: null | postLoginState;
  isLoadingFacebook: boolean;
  isLoadingGoogle: boolean;
  isLoadingApple: boolean;
  isLoadingRequestEmailVerification: boolean;
  isLoadingVerifyEmail: boolean;
  isLoadingUpdatingAccountInfo: boolean;
  signedInWithSocial: boolean;
  signedInWithEmail: boolean;
  emailVerifyId: string | null;
  email: string | null;
  username: string | null;
  name: string | null;
  first_name: string | null;
  last_name: string | null;
  firstName: string | null;
  lastName: string | null;
  photoURL: string | null;
  isNameSet: boolean;
  isAppleSigninPossible: boolean;
  hasMissingInfo: boolean;
  social: boolean;
  isNewUser: boolean;
};

export const state: IAccountAccessState = {
  isNewUser: false,
  preLoginState: null,
  postLoginState: null,
  isLoadingFacebook: false,
  isLoadingGoogle: false,
  isLoadingApple: false,
  isLoadingRequestEmailVerification: false,
  isLoadingVerifyEmail: false,
  isLoadingUpdatingAccountInfo: false,
  isAppleSigninPossible: false,
  signedInWithSocial: false,
  signedInWithEmail: false,
  emailVerifyId: null,
  email: '',
  username: '',
  name: '',
  first_name: '',
  last_name: '',
  photoURL: '',
  social: true,
  firstName: derived<IAccountAccessState, Config['state'], string>(
    (s) => s.name?.split(' ')?.[0],
  ),
  lastName: derived<IAccountAccessState, Config['state'], string>((s) =>
    s.name?.split(' ').slice(1).join(' '),
  ),
  isNameSet: derived<IAccountAccessState, Config['state'], boolean>((s) =>
    Boolean(s.name),
  ),
  hasMissingInfo: derived<IAccountAccessState, Config['state'], boolean>(
    (s) => !s.name || !s.email,
  ),
};
