import {
  IGetPortfolioPayload,
  IGetPortfolioResponse,
  IGetPurchasePowerPayload,
  IGetPurchasePowerResponse,
} from './interfaces';

import { http } from '@/store/effects';
import { ApplicationError } from '@/util/errors';
import { AsyncRequestWithToken } from '@/util/types';

// gets investor portfolio
export const getPortfolio = async (
  token: string,
  { market }: IGetPortfolioPayload,
) => {
  try {
    const { data } = await http.getWithToken<IGetPortfolioResponse | undefined>(
      {
        token,
        url: '/market-service/accounts/portfolio-info',
        sendLang: true,
        config: {
          params: {
            market,
          },
        },
      },
    );

    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.investor.getPortfolio');
  }
};
