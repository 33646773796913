import { PaymentTokenPurpose } from '@/models/types';

enum Routes {
  'INDEX' = '/',
  'ACCOUNT_ACCESS' = '/account-access',
  'ACCOUNT_ACCESS_VERIFY_EMAIL' = '/account-access/verify-email',
  'ACCOUNT_ACCESS_CONFIRM_INFO' = '/account-access/confirm-info',
  'SUBSCRIPTIONS' = '/subscriptions',
  'SUBSCRIPTIONS_TRIAL' = '/subscriptions/Subscribe',
  'SUBSCRIPTIONS_COMPARE' = '/subscriptions/compare',
  'SUBSCRIPTIONS_RESUBSCRIBE' = '/subscriptions/Resubscribe',
  'SUBSCRIPTIONS_CHANGEPAYMENT' = '/subscriptions/ChangePayment',
  'SUBSCRIPTIONS_CONFIRM' = '/subscriptions/confirm',
  'PRICING' = '/pricing',
  'STOCKS' = '/stocks/[symbol]',
  'CORPORATE' = '/corporate',
  'COMMUNITY' = '/api/community',
  'INVEST' = '/invest',
  'CHECKOUT' = '/subscriptions/checkout',
  'SUBSCRIPTIONS_V2_CHANGEPAYMENT' = '/subscriptions/change-payment',
  'SUBSCRIPTIONS_V2' = '/subscriptions',
  'COMPENSATIONS' = '/orders041223',
}

export default Routes;

export interface ISubscriptionTrialParams {
  product?: string;
  purpose?: PaymentTokenPurpose;
  txn_response_code?: string;
}
export interface IChangePaymentMethodParams {
  product: string;
}
export interface IConfirmQueryParams {
  is_capture: boolean; // false
  is_refunded: boolean; // false
  refunded_amount_cents: number; //0
  'source_data.type': string; //card
  currency: string; //EGP
  amount_cents: number; //1500
  order: number; //5456327
  hmac: string; // 1b98c22a58580588bdd6493f0b04b559e798273ebe2ff14e245c7446d7cef5073f79320e2804597a60630c9d883fdec086d8dce6f986b173082c9bd3feafe770
  pending: false;
  error_occured: boolean; //false
  'source_data.pan': string; // 8769
  merchant_order_id: string; // 9ea3e028-dde8-4eeb-8339-7f8ddebf2e30
  profile_id: number; //14237
  is_refund: boolean; // false
  acq_response_code: number; //00
  is_voided: boolean; // false
  created_at: string; /// 2020-07-20T18%3A30%3A41.369270
  id: number; // 3095190
  is_3d_secure: boolean; // false
  'data.message': string; // 'Approved'
  is_void: boolean; // false
  has_parent_transaction: boolean; // false
  success: boolean; // true
  is_auth: boolean; // false
  integration_id: number; // 23781
  is_standalone_payment: boolean; // true
  owner: number; // 17056
  txn_response_code: 'APPROVED';
  'source_data.sub_type': 'Visa' | 'Master';
  captured_amount: number; // 0
}

export interface IAccessAccountQueryParams {
  redirect?: string;
  noSocial?: boolean;
}
