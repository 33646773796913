export const getSecurityLogoUrl = (symbol: string) =>
  `https://storage.googleapis.com/images.thndr.app/logos/rounded/${symbol}.png`;
export const getUSSecurityLogoUrl = (symbol: string) =>
  `https://s3.polygon.io/logos/${symbol.toLowerCase()}/logo.png`;

export const getNewsLogoUrl = (sourceNameModified: string) =>
  `https://storage.googleapis.com/images.thndr.app/logos/news/${sourceNameModified}.png`;

export const getTotalInvestment = (
  initialInvestment: number,
  monthlyInvestment: number,
  years: number
) => {
  return initialInvestment + monthlyInvestment * 12 * years;
};

export const getMonthlyProfit = (
  monthlyInvestment: number,
  years: number,
  percent: number
) => {
  return (
    (monthlyInvestment / (percent / 1200)) *
      (Math.pow(1 + percent / 1200, years * 12) - 1) -
    monthlyInvestment * years * 12
  );
};

export const getYearlyProfit = (
  initialInvestment: number,
  years: number,
  percent: number
) => {
  return (
    initialInvestment * Math.pow(1 + percent / 100, years) - initialInvestment
  );
};

export const getTotalProfit = (
  initialInvestment: number,
  monthlyInvestment: number,
  years: number,
  percent: number
) => {
  return (
    getYearlyProfit(initialInvestment, years, percent) +
    getMonthlyProfit(monthlyInvestment, years, percent)
  );
};

export const getTotalReturn = (
  initialInvestment: number,
  monthlyInvestment: number,
  years: number,
  percent: number
) => {
  return (
    getTotalProfit(initialInvestment, monthlyInvestment, years, percent) +
    getTotalInvestment(initialInvestment, monthlyInvestment, years)
  );
};

export const calculateStockLastChangePercentage = ({
  open,
  close,
}: {
  open?: number;
  close?: number;
}) => {
  let percentage;
  if (!open || !close) {
    return;
  }
  if (open > 0) {
    percentage = ((close - open) / open) * 100;
  } else {
    percentage = 0;
  }
  return percentage;
};
export const getTimeTillNextUpdate = (updatedAt: number, interval: 1 | 15) => {
  try {
    const lastUpdateTime = new Date(updatedAt);
    const nextUpdateTime = new Date(updatedAt);

    const diff = lastUpdateTime?.getMinutes() / 60;
    if (interval === 15) {
      if (diff >= 0.75) {
        nextUpdateTime.setMinutes(0);
        nextUpdateTime.setHours(nextUpdateTime.getHours() + 1);
      } else if (diff >= 0.5) {
        nextUpdateTime.setMinutes(45);
      } else if (diff >= 0.25) {
        nextUpdateTime.setMinutes(30);
      } else {
        nextUpdateTime.setMinutes(15);
      }
    } else {
      if (nextUpdateTime?.getMinutes() === 59) {
        nextUpdateTime.setMinutes(0);
        nextUpdateTime.setHours(nextUpdateTime.getHours() + 1);
      } else {
        nextUpdateTime.setMinutes(nextUpdateTime.getMinutes() + 1);
      }
    }
    nextUpdateTime.setSeconds(0);

    const timeTillNextUpdate =
      nextUpdateTime.getTime() - lastUpdateTime.getTime();

    return timeTillNextUpdate;
  } catch (err) {
    return 1000 * 60 * 15;
  }
};
