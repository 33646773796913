import Cookie from 'js-cookie';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

import {
  CAN_SAVE_IN_COOKIES,
  AuthTokenKey,
  AuthTokens,
} from '@/constants/auth';

export function saveTokenInCookiesIfEligible(
  key: AuthTokenKey,
  value: string,
  canSave: string[] = CAN_SAVE_IN_COOKIES,
) {
  if (canSave.includes(key) && value) {
    const { exp } = jwt_decode(value);
    const expires = dayjs(new Date(exp * 1000)).diff(new Date(), 'day') + 1;
    Cookie.set(key, value, { expires, sameSite: 'Strict' });
  }
}
export function loadFromCookies(
  canSave: string[] = CAN_SAVE_IN_COOKIES,
): Partial<AuthTokens> {
  return Object.fromEntries(
    canSave.map((key) => [key, Cookie.get(key) || null]),
  );
}
export function clearFromCookies(canSave: string[] = CAN_SAVE_IN_COOKIES) {
  canSave.forEach((key) => Cookie.remove(key));
}
