import {
  getDatabase,
  ref,
  set,
  update,
  onValue,
  off,
  get,
} from 'firebase/database';

export const realTimeDB = {
  set: async (refUrl: string, data: Object) => {
    // logger.info('set', ref);
    const db = getDatabase();
    return await set(ref(db, refUrl), { ...data });
  },
  update: async (refUrl: string, data: Object) => {
    // logger.info('update', ref);
    const db = getDatabase();
    return await update(ref(db, refUrl), { ...data });
  },
  get: async (refUrl: string) => {
    // logger.info('get', ref);
    const db = getDatabase();
    const data = await get(ref(db, refUrl));
    return data.val();
  },
  on: <Val>(refUrl: string, fn: (val: Val, snapshot?) => void) => {
    // logger.info('on', ref);
    const db = getDatabase();
    return onValue(ref(db, refUrl), (snapshot) => {
      fn(snapshot.val(), snapshot);
    });
  },
  off: (refUrl: string, fn: any) => {
    // logger.info('off', ref);
    const db = getDatabase();
    return off(ref(db, refUrl), fn);
  },
};
