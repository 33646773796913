import { NextRouter } from 'next/router';
import Query from 'query-string';

let _navigator: NextRouter;

const push: NextRouter['push'] = async (url, as, options) => {
  if (await _navigator.push(url, as, options)) {
    return true;
  }
  return false;
};
const replace: NextRouter['replace'] = async (url, as, options) => {
  if (await _navigator.replace(url, as, options)) {
    return true;
  }
  return false;
};
const back: NextRouter['back'] = () => {
  _navigator.back();
};

export const navigation = {
  setTopLevelNavigator(ref: NextRouter) {
    _navigator = ref;
  },
  navigate: push,
  push,
  replace,
  back,
  getQuery<T extends object>() {
    return extractQueryFromRouter(_navigator) as T;
  },
  get pathname() {
    return _navigator?.pathname;
  },
  stringifyQuery,
};

export function stringifyQuery(query) {
  return Query.stringify(query);
}

export function extractQueryFromRouter(router: {
  asPath?: NextRouter['asPath'];
  query: NextRouter['query'];
}) {
  const query = Object.fromEntries(
    Object.entries<any>(router.query).map(([key, value]) => [
      key,
      parseNoneString(value),
    ]),
  );
  const pathQuery = queryFromUrl(router.asPath);
  return {
    ...query,
    ...pathQuery,
  };
}

function queryFromUrl(url?: string) {
  const queryString = url ? (url.includes('?') ? url.split('?')[1] : url) : '';
  return Query.parse(queryString, { parseBooleans: true, parseNumbers: true });
}

function parseNoneString(value) {
  if (typeof Number(value) === 'number') {
    return Number(value);
  } else if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else {
    return value;
  }
}
