import { http } from '@/store/effects';
import { ApplicationError } from '@/util/errors';
import { AsyncRequest } from '@/util/types';

export interface ITokenRequestPayload {
  data: {
    scopes: string[];
  };
  credentials: {
    firebase_token: string;
  };
}
export interface ITokenRequestResponse {
  id: string;
  request_secret: string;
  human_id: string;
  created_at: string;
}

// create new token request
export const requestToken: AsyncRequest<
  ITokenRequestPayload,
  ITokenRequestResponse | undefined
> = async (payload) => {
  try {
    const { data } = await http.instance.post(
      `/auth-service/tokens/request`,
      payload,
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.auth.requestToken');
  }
};

interface IGetTokenRequestStatusPayload {
  request_id: string;
  firebase_token: string;
  request_secret: string;
}

interface IGetTokenRequestStatusResponse {
  status: string;
}

// get token request status
export const getTokenRequestStatus: AsyncRequest<
  IGetTokenRequestStatusPayload,
  IGetTokenRequestStatusResponse | undefined
> = async ({ request_id, request_secret, firebase_token }) => {
  try {
    const { data } = await http.instance.post(
      `/auth-service/tokens/request/${request_id}/status`,
      {
        data: { request_secret },
        credentials: { firebase_token },
      },
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.auth.getRequestTokenStatus');
  }
};

interface IGetRequestedTokenPayload {
  request_id: string;
  request_secret: string;
  firebase_token: string;
}
interface IGetRequestedTokenResponse {
  auth_token: string;
}

// get token
export const getRequestedToken: AsyncRequest<
  IGetRequestedTokenPayload,
  IGetRequestedTokenResponse | undefined
> = async ({ request_id, request_secret, firebase_token }) => {
  try {
    const { data } = await http.instance.post(
      `/auth-service/tokens/request/${request_id}`,
      {
        data: { request_secret },
        credentials: { firebase_token },
      },
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.auth.getToken');
  }
};

interface IGetFullAccessWebPayload {
  firebase_token: string;
  pin: string;
  password: string;
}

export const verifyAppPinAndGetToken: AsyncRequest<
  IGetFullAccessWebPayload,
  IGetRequestedTokenResponse | undefined
> = async ({ firebase_token, pin, password }) => {
  try {
    const { data } = await http.instance.post(
      `auth-service/v2/tokens/full-access-web-login`,
      {
        credentials: { firebase_token, pin, password },
      },
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'effects.auth.getToken');
  }
};
