import jwt_decode from 'jwt-decode';

import { loadFromStorage, storeAuthToken, clearFromStorage } from './storage';
import {
  loadFromCookies,
  saveTokenInCookiesIfEligible,
  clearFromCookies,
} from './cookie';

import { UserAuthTrust, AuthTokenKey, Scope } from '@/constants/auth';

export async function setAuthToken(key: AuthTokenKey, token: string) {
  saveTokenInCookiesIfEligible(key, token);
  return storeAuthToken(key, token);
}

export function deriveTokenState(token: string | null | undefined) {
  const { utype, exp, scopes } = token
    ? jwt_decode<{ utype: UserAuthTrust; exp: number; scopes: Scope[] }>(token)
    : {
        exp: 0,
        utype: 'unverified',
        scopes: [],
      };
  return {
    isLoading: false,
    value: token,
    trust_level: utype,
    expires: new Date(exp * 1000),
    scopes,
  };
}

export async function loadAuthTokens() {
  let tokens = loadFromCookies();
  tokens = await loadFromStorage(tokens);
  return tokens;
}

export async function clearAuthTokens() {
  clearFromCookies();
  await clearFromStorage();
}

export * from './requests';
