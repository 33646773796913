import { http } from '@/store/effects';
import { AsyncRequestWithToken } from '@/util/types';
import { ApplicationError } from '@/util/errors';
import { IManagedFundResponse } from '@/models/types';

export const getManagedFunds = async (token) => {
  try {
    const { data } = await http.getWithToken<IManagedFundResponse>({
      token,
      url: `/api/user/managed-funds`,
    });
    // return data?.results ?? data;
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'ERROR');
  }
};
