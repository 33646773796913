// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { randomTimeWrapper } from '@thndr/services/api';
import { useQuery, useQueryClient } from 'react-query';
import { getSecurityDetail } from '../api/getSecurityDetail';
import { IAsset, StockStatus } from '../types';
import { getTimeTillNextUpdate } from '../util';

const SECURITY_DETAIL_KEY = 'security-detail';
export const useSecurityDetails = ({
  securityId,
  isSubscribed = false,
  includeYearlyReturn = false,
  enabled = true,
}: {
  securityId: string;
  isSubscribed?: boolean;
  enabled?: boolean;
  includeYearlyReturn?: boolean;
}) => {
  const queryClient = useQueryClient();
  const securityDetailsState = queryClient.getQueryState([
    SECURITY_DETAIL_KEY,
    securityId,
  ]);
  const lastUpdatedAt = securityDetailsState?.dataUpdatedAt;
  const securityDetails = securityDetailsState?.data as Partial<
    IAsset | undefined
  >;
  const isStockSuspended =
    securityDetails?.stats?.symbol_state === StockStatus.SUSPENDED &&
    !securityDetails?.is_ipo;
  const timeTillNextUpdate = isStockSuspended
    ? 0.5 * 60 * 1000
    : lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 15)
    : 15 * 60 * 1000;
  const { data, isLoading } = useQuery({
    queryKey: [SECURITY_DETAIL_KEY, securityId],
    queryFn: () =>
      randomTimeWrapper<Partial<IAsset | undefined>>({
        isSubscribed,
        skipDelay: !data,
        callback: async () => {
          const results = await getSecurityDetail({
            securityId,
            includeYearlyReturn,
          });
          return results;
        },
      }),
    staleTime: timeTillNextUpdate,
    cacheTime: timeTillNextUpdate,
    refetchInterval: isStockSuspended ? timeTillNextUpdate : undefined,
    enabled: enabled && Boolean(securityId),
  });
  return {
    data: data as Partial<IAsset>,
    isLoading,
  };
};
