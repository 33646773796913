import { thndrApi } from '@thndr/services/api';
import { MarketName } from '../types';
export type ActiveMarketHoursResponse = {
  is_current_session_active: boolean;
  next_session_open: string;
  next_session_close: string;
};
export type IActiveMarketHoursArgs = {
  market: MarketName;
};
export const getActiveMarketHours = async ({
  market
}: IActiveMarketHoursArgs): Promise<ActiveMarketHoursResponse> => {
  const { data } = await thndrApi.get(
    `market-service/markets/active-hours/${market}`
  );

  return data;
};
