import { MarketName } from '../../../../market/src/lib/types';
import { IGetAnalyticsReport, IGetAnalyticsReportPayload } from '../constants';
import {
  getWithToken,
  deleteWithToken,
  patchWithToken,
} from '@thndr/services/http';
import { AsyncRequest } from '@/util/types';
import {
  IAdvancedChart,
  IAsset,
  IConsensusResponse,
  IFinancials,
  IGetAssetDetailsPayload,
  IGetAssetListQueryParams,
  IGetChartPayload,
  IGetChartResponse,
  IGetMarketDepthResponse,
  INewsResponse,
  ISearchQueryParams,
  ISearchResponse,
} from '../types';

//FIXME: write response types
// get asset recommendations
export const getRecommendations = async ({
  asset_id,
  token,
  recommendations_number,
}: {
  asset_id: string;
  token: string | null;
  recommendations_number?: number;
}) => {
  const params = {
    include_feed: true,
    feed_detail: false,
    recommendations_number,
  };
  const { data } = await getWithToken<string>({
    token: `Bearer ${token}`,
    url: `/assets-service/assets/${asset_id}/recommendations`,
    params,
  });
  return data;
};

export const getAnalysis = async ({
  asset_id,
  token,
}: {
  asset_id: string;
  token: string | null;
}) => {
  const { data } = await getWithToken<string>({
    token: `Bearer ${token}`,
    url: `/assets-service/assets/${asset_id}/analytics`,
  });
  return data;
};

export const getAnalysisReport = async ({
  asset_id,
  token,
}: {
  asset_id: string;
  token: string | null;
}) => {
  const { data } = await getWithToken<string>({
    token: `Bearer ${token}`,
    url: `/assets-service/assets/${asset_id}/analytics-report`,
  });
  return data;
};

export const getAssetsInfo = async ({
  search,
  asset_ids,
  page_count,
  page,
  include_feed = true,
  market,
  token,
}: IGetAssetListQueryParams) => {
  let params: IGetAssetListQueryParams = { include_feed };
  if (search) {
    params.search = search;
  }
  if (page_count) {
    params.page_count = page_count;
  }
  if (asset_ids && asset_ids.length) {
    params.asset_ids = asset_ids;
    params.page_count = asset_ids.length;
  }
  if (page) {
    params.page = page;
  }
  if (market) {
    params.market = market;
  }

  const { data } = await getWithToken<IAsset[]>({
    token: `Bearer ${token}`,
    url: `/assets-service/assets`,
    params,
  });
  return data;
};

export const getAssetInfo = async ({
  token,
  asset_id,
}: IGetAssetDetailsPayload) => {
  const params = { include_feed: true, feed_detail: true };
  const { data } = await getWithToken<IAsset>({
    token: `Bearer ${token}`,
    url: `/assets-service/assets/${asset_id}`,
    params,
    sendLang: true,
  });
  return data;
};

export const getAssetWatchlist = async (token: string | null) => {
  const { data } = await getWithToken<any>({
    token: `Bearer ${token}`,
    url: `/assets-service/watchlist`,
    sendLang: true,
  });

  return data;
};

export const watchAsset = async ({
  token,
  asset_id,
}: {
  token: string | null;
  asset_id: string;
}) => {
  const { data } = await patchWithToken<any>(
    `Bearer ${token}`,
    `/assets-service/watchlist`,
    { asset_id },
    {}
  );
  return data;
};

export const unwatchAsset = async ({
  token,
  asset_id,
}: {
  token: string | null;
  asset_id: string;
}) => {
  const { data } = await deleteWithToken<any>({
    token: `Bearer ${token}`,
    url: `/assets-service/watchlist`,
    config: {
      data: {
        asset_id,
      },
    },
  });
  return data;
};

export const getMarketDepth = async (
  token: string | null,
  { asset_id }: { asset_id: string }
) => {
  const { data } = await getWithToken<IGetMarketDepthResponse>({
    token: `Bearer ${token}`,
    url: `/assets-service/market-depth/${asset_id}`,
  });
  return data;
};

export const getChart = async (
  token: string | null,
  { asset_ids, interval, candlesticks, market }: IGetChartPayload
) => {
  const params = {
    asset_ids: asset_ids,
    option: interval,
    candlesticks: Boolean(candlesticks),
    market: market,
  };

  const { data } = await getWithToken<IGetChartResponse>({
    token: `Bearer ${token}`,
    url: `/assets-service/charts`,
    params,
  });
  return data;
};

interface IGetAdvancedChartPayload {
  asset_id: string;
  resolution: string;
  from_timestamp: number;
  to_timestamp: number;
  next_date?: number;
}

interface IGetAdvancedChartResponse {
  points: IAdvancedChart[];
  next_date?: number;
}

export const getAdvancedChart = async (
  token: string | null,
  {
    asset_id,
    resolution,
    from_timestamp,
    to_timestamp,
  }: IGetAdvancedChartPayload
) => {
  const params = {
    asset_id,
    resolution,
    from_timestamp: from_timestamp * 1000,
    to_timestamp: to_timestamp * 1000,
  };
  const { data } = await getWithToken<IGetAdvancedChartResponse>({
    token: `Bearer ${token}`,
    url: `/assets-service/charts/advanced`,
    params,
  });
  return data;
};

export const getConsensus = async (
  token: string | null,
  { asset_id }: { asset_id: string }
) => {
  const { data } = await getWithToken<IConsensusResponse>({
    token: `Bearer ${token}`,
    url: `assets-service/analysis/consensus/${asset_id}`,
  });
  return data;
};

export const getFinancials = async (
  token: string | null,
  { asset_id }: { asset_id: string }
) => {
  const { data } = await getWithToken<IFinancials>({
    token: `Bearer ${token}`,
    url: `assets-service/analysis/financial/${asset_id}`,
  });
  return data;
};

export const getSearch = async (
  token: string | null,
  { query, page, page_count, market }: ISearchQueryParams
) => {
  let params = {} as ISearchQueryParams;
  if (query) {
    params.query = query;
  }
  if (page) {
    params.page = page;
  }
  if (page_count) {
    params.page_count = page_count;
  }
  if (market) {
    params.market = market;
  }

  const { data } = await getWithToken<ISearchResponse>({
    token: `Bearer ${token}`,
    url: '/assets-service/assets/search',
    sendLang: true,
    params,
  });

  return data;
};

export const getNews = async (token: string | null) => {
  const { data } = await getWithToken<INewsResponse>({
    token: `Auth ${token}`,
    url: `/api/post/news`,
  });
  return data.results;
};

export const getStockNews = async (
  token: string | null,
  { symbol, page, locale }: { symbol: string; page: number; locale?: string }
) => {
  const params = {
    stock: symbol,
    page_size: 10,
    page,
    locale,
  };
  const { data } = await getWithToken<INewsResponse>({
    token: `Auth ${token}`,
    url: `/api/post/news/`,
    params,
  });
  return data;
};
