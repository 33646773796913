import { useQuery } from 'react-query';
import { MARKET_QUERY_KEYS } from '../query-keys';
import { getMarketDepth } from '../api/marketDepth';
import { MarketDepth } from '../types';

type UseMarketDepthPayload = {
  assetId: string;
  onError?: (error: any) => void;
  enabled?: boolean;
};
const initialValue: MarketDepth = {
  asks_per_price: [
    {
      order_price: 0,
      split: 0,
      volume_traded: 0,
      volume_traded_cum_sum: 0,
      total_ask: 0,
      total_bids: 0
    }
  ],
  bids_per_price: [
    {
      order_price: 0,
      split: 0,
      volume_traded: 0,
      volume_traded_cum_sum: 0,
      total_ask: 0,
      total_bids: 0
    }
  ],
  total_bids_and_asks: {
    total_asks: 0,
    total_bids: 0
  }
};

export const useMarketDepth = ({
  assetId,
  onError,
  enabled = true
}: UseMarketDepthPayload) => {
  const {
    data: marketDepth = initialValue,
    isLoading,
    isFetching,
    error,
    refetch
  } = useQuery({
    queryKey: [MARKET_QUERY_KEYS.marketDepth, assetId],
    queryFn: () => getMarketDepth({ assetId }),
    refetchInterval: 2000,
    staleTime: 5000,
    onError: (error) => {
      onError?.(error);
    },
    enabled
  });
  return {
    marketDepth,
    isLoading,
    isFetching,
    error,
    refetch
  };
};
