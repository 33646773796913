import { thndrApi } from '@thndr/services/api';
import { MarketName } from '../../lib/types';
export interface ITheme {
  id: number;
  market: MarketName;
  slug?: string;
  name: string;
  about: string;
  small_sq_pic?: string;
  small_rect_pic?: string;
  large_rect_pic?: string;
  created_at?: string;
  assets_count: number;
  assets: any[];
  background_color?: string;
  rank: number;
  hidden?: boolean;
}

export const getThndrThemesDetails = async ({
  themeId,
  page,
  market,
}: {
  themeId: number;
  page: number;
  market: MarketName[];
}) => {
  const { data } = await thndrApi.get<ITheme>(
    `/assets-service/tags/${themeId}?market=${market}&page_count=20&page=${page}&feed_detail=true&include_feed=true`
  );
  return data;
};
