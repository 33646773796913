import { useCart } from '../hooks-old/useCart';
import { useMySubs } from '../hooks-old/useMySubs';
import { useSubsPlans } from '../hooks-old/useSubsPlans';
import {
  addCartStatus,
  filterByFrequency,
  removeByProducts,
} from '../logic/get-available-plans';

import { SubscriptionFrequency, SubscriptionPlan } from '../types';
type useAvailablePlansProps = {
  frequency: SubscriptionFrequency;
};
export const useAvailablePlans = ({ frequency }: useAvailablePlansProps) => {
  const {
    cart,
    addSubscriptions,
    removeSubscription,
    total,
    discount,
  } = useCart();

  const {
    isLoading: isCreatingSubs,
    myProducts,
    isError: mySubsIsError,
    hasError,
    success,
  } = useMySubs();

  const {
    activePlans,
    isLoading: plansIsLoading,
    isError: plansIsError,
  } = useSubsPlans();

  const isLoading = plansIsLoading;
  const isError = mySubsIsError && plansIsError;

  if (isLoading) {
    return { isLoading: true };
  }

  if (isError) {
    return { isError: true };
  }

  const frequencyPlans: { [planId: string]: SubscriptionPlan } =
    filterByFrequency({
      activePlans: removeByProducts({
        products: myProducts,
        plans: activePlans,
      }),
      frequency: frequency,
    });

  const availablePlans = addCartStatus({
    cartItems: cart,
    plans: frequencyPlans,
  });
  return {
    cartItems: cart,
    availablePlans,
    isLoading,
    isError,
    addSubscriptions,
    removeSubscription,
    total,
    discount,
    hasError,
    isCreatingSubs,
    success,
  };
};
