import { IEligibilityModalProps } from '@/components/common/EligibilityModal/EligibalityModal';

export interface IEligibleProduct {
  user_id: string;
  product_name: ProductName;
  eligibility_status: EligibilityStatus;
  registration_status: string;
}

export enum EligibilityStatus {
  ELIGIBLE = 'ELIGIBLE',
  ACCOUNT_FORM_NOT_ACCEPTED = 'ACCOUNT_FORM_NOT_ACCEPTED',
  NOT_EVALUATED = 'NOT_EVALUATED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  NOT_SIGNED = 'NOT_SIGNED',
  NOT_SIGNED_NOT_BOOKED = 'NOT_SIGNED_NOT_BOOKED',
  NOT_SIGNED_BOOKED = 'NOT_SIGNED_BOOKED',
  UNDERAGE = 'UNDERAGE',
  NO_EGX_CODE = 'NO_EGX_CODE',
  NO_THNDR_CODE = 'NO_THNDR_CODE',
  NO_EGX_CODE_BLOCKED = 'NO_EGX_CODE_BLOCKED',
  SUSPENDED = 'SUSPENDED',
}

export enum RegistrationStatus {
  NOT_ATTEMPTED = 'NOT_ATTEMPTED',
  NOT_QUALIFIED = 'NOT_QUALIFIED',
  REGISTERED = 'REGISTERED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  DISABLED = 'DISABLED',

  NOT_SIGNED = 'NOT_SIGNED',
  NOT_SIGNED_NOT_BOOKED = 'NOT_SIGNED_NOT_BOOKED',
  NOT_SIGNED_BOOKED = 'NOT_SIGNED_BOOKED',
  NO_EGX_CODE = 'NO_EGX_CODE',
  NO_THNDR_CODE = 'NO_THNDR_CODE',
  NO_EGX_CODE_BLOCKED = 'NO_EGX_CODE_BLOCKED',
}

export enum ProductName {
  EGX = 'EGX',
  ALPACA = 'Alpaca',
  EGYPTIAN_MUTUAL_FUNDS = 'Egyptian Mutual Funds',
}

export enum ComplianceFormStatus {
  NEW = 'NEW',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum EligibilityLoadingStatus {
  'EMPTY' = 'EMPTY',
  'LOADING' = 'LOADING',
  'ERROR' = 'ERROR',
  'LOADED' = 'LOADED',
}
export enum ComplianceFormLoadingStatus {
  'EMPTY' = 'EMPTY',
  'LOADING' = 'LOADING',
  'ERROR' = 'ERROR',
  'LOADED' = 'LOADED',
}

export interface IAccountState {
  eligibilityLoadingStatus: EligibilityLoadingStatus;
  eligibilities: IEligibleProduct[];
  eligibilityStates: Record<ProductName, EligibilityStatus>;
  eligibilityRegistrationStates: Record<ProductName, RegistrationStatus>;
  eligibilityModalData: IEligibilityModalProps | null;
  agreementFormLoadingStatus: ComplianceFormLoadingStatus;
  agreementFormStatus: ComplianceFormStatus;
  hasBookedAppointment: boolean;
  // isAgreementFormAccepted: boolean;
  isAgreementFormRejected: boolean;
  isAgreementFormUnderReview: boolean;
}

// does not belong here
export interface IComplianceForm {
  id?: string;
  step: string;
  first_name: string;
  last_name: string;
  local_name?: string;
  birthdate: string;
  nationality: string;
  phone_country_code: string;
  phone_number: string;
  email: string;
  address: string;
  city: string;
  country: string;
  company_name: string;
  employment_status: string;
  tax_id: string;
  tax_id_type: string;
  tax_id_absence_reason: string;
  is_control_person: boolean;
  is_affiliated_exchange_or_finra: boolean;
  is_politically_exposed: boolean;
  user_id: string;
  alpaca_id: string;
  id_number: string;
  id_type: string;
  id_issuance_date: string;
  id_expiry_date: string;
  industry: string;
  egx_trading_code: string;
  egx_unified_code: string;
  status: ComplianceFormStatus;
  documents_accepted: string[];
  agreement_ip_address: string;
  has_booked_appointment: boolean;
  rejection_reason: string;
  created_at: string;
  updated_at: string;
  submitted_at: string;
}
