const timerIds: { [id: string]: number } = {};

export const startTimer = (fn: Function, interval: number) => {
  const id = `${Date.now()}${Math.random() * 1000}`;
  timerIds[id] = Date.now() - interval - 1;
  const intervalLoop = () => {
    if (timerIds[id] + interval < Date.now()) {
      fn();
      timerIds[id] = Date.now();
    }
    if (timerIds[id]) {
      requestAnimationFrame(intervalLoop);
    }
  };
  intervalLoop();
  return id;
};

export const clearTimer = (intervalId: string) => {
  delete timerIds[intervalId];
};
