import { thndrApi } from '@thndr/services/api';
import { IAsset } from '../types';

export const getRecommendedSecurities = async ({
  market,
  assetId,
  count = 4
}: {
  market: string;
  assetId: string;
  count?: number;
}): Promise<{ results: Partial<IAsset[]> }> => {
  const { data } = await thndrApi.get(
    `/assets-service/assets/${assetId}/recommendations?include_feed=true&feed_detail=true&market=${market}&recommendations_number=${count}`
  );
  return data;
};
