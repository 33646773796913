/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { derived, Config } from '@/store';
import {
  AuthTokenKey,
  FIREBASE_TOKEN,
  ThndrAPIToken,
  Scope,
  UserAuthTrustLevels,
} from '@/constants/auth';
import { IUserInfo, USER_INFO_STATUS, USER_TYPES } from './user/models';

export type IAuthState = {
  isLoadingStoredTokens: boolean;
  isLoadingAuthToken: boolean;

  hasFirebaseToken: boolean;
  hasLoadedFirebaseUser: boolean;
  hasLoadedStoredTokens: boolean;
  needsToAuthenticate: boolean;

  base_trust_level: UserAuthTrustLevels;

  isPassphraseModalVisible: boolean;
  passphraseModalUnlocking: string | null;
  privilegedTokenNeedsRenew: boolean;
  isPrivilegedTokenRenewing: boolean;
  isInRecovery: boolean;
  isUnverified: boolean;
  isRecovered: boolean;
  isVerified: boolean;
  shouldLockUI: boolean;
  authTokens: {
    [key in AuthTokenKey]: {
      isLoading: boolean;
      value: ThndrAPIToken | null;
      trust_level?: UserAuthTrustLevels;
      expires?: Date;
      scopes?: Scope[];
      waitingRequests: Function[];
    };
  };
  tokenRequest: {
    isRequesting: boolean;
    isFetchingToken: boolean;
    isCheckingStatus: boolean;
    autoCancelCount: number;
    data: {
      id: string;
      request_secret: string;
      human_id: string;
      created_at: string;
    } | null;
  };
  showSMSButton: boolean;
  requestSMS: boolean;
  requestingOTP: boolean;
  otpVerificationSuccess: boolean;
  isSendingCode: boolean;
  isVerifyingPinCode: boolean;
  userInfo: {
    status: USER_INFO_STATUS;
    data: IUserInfo;
    form: {
      name: string;
      username: string;
      isSubmitting: boolean;
    };
  };
  authServerOffset: number;
  isWebView: boolean;
  shouldShowSubsWebview: boolean;
};

export const state: IAuthState = {
  isLoadingStoredTokens: false,
  isLoadingAuthToken: derived<IAuthState, Config['state'], boolean>(
    fromAuthTokensIsLoading,
  ),
  hasFirebaseToken: false,
  hasLoadedFirebaseUser: false,
  hasLoadedStoredTokens: false,
  needsToAuthenticate: false,
  base_trust_level: derived<IAuthState, Config['state'], UserAuthTrustLevels>(
    (state, rootState) =>
      rootState.authentication?.authTokens?.APP_TOKEN?.trust_level ??
      UserAuthTrustLevels.unverified,
  ),
  authTokens: initialAuthTokenStates(),
  isPassphraseModalVisible: false,
  passphraseModalUnlocking: null,
  privilegedTokenNeedsRenew: true,
  isPrivilegedTokenRenewing: false,
  isInRecovery: false,
  isUnverified: derived<IAuthState, Config['state'], boolean>(
    (_state) => _state.base_trust_level === UserAuthTrustLevels.unverified,
  ),
  isRecovered: derived<IAuthState, Config['state'], boolean>(
    (_state) => _state.base_trust_level === UserAuthTrustLevels.recovered,
  ),
  isVerified: derived<IAuthState, Config['state'], boolean>(
    (_state) => _state.base_trust_level === UserAuthTrustLevels.verified,
  ),
  shouldLockUI: derived<IAuthState, Config['state'], boolean>(
    (_state, globalState) =>
      !globalState.newMarket.simulatorEnabled &&
      (_state.privilegedTokenNeedsRenew ||
        globalState.authentication.authTokens['APP_TOKEN']?.scopes?.length <
          30),
  ),
  tokenRequest: {
    isRequesting: false,
    isFetchingToken: false,
    isCheckingStatus: false,
    autoCancelCount: 0,
    data: null,
  },
  showSMSButton: false,
  requestSMS: false,
  requestingOTP: false,
  otpVerificationSuccess: false,
  isSendingCode: false,
  isVerifyingPinCode: false,
  userInfo: {
    status: USER_INFO_STATUS.IDLE,
    data: {
      email: '',
      has_active_recovery: false,
      name: '',
      phone_number: '',
      type: USER_TYPES.UNVERIFIED,
      uid: '',
      username: null,
      has_pin: false,
      has_password: false,
      has_security_question: false,
      alpaca_id: '',
    },
    form: {
      name: '',
      username: '',
      isSubmitting: false,
    },
  },
  authServerOffset: null,
  isWebView: false,
  shouldShowSubsWebview: false,
};

export function initialAuthTokenStates() {
  return {
    [FIREBASE_TOKEN]: initialAuthTokenState(FIREBASE_TOKEN),
    'application-read-token': initialAuthTokenState('application-read-token'),
    'application-write-token': initialAuthTokenState('application-write-token'),
    'subs-service-token': initialAuthTokenState('subs-service-token'),
    'notifications-service-token': initialAuthTokenState(
      'notifications-service-token',
    ),
    'simulator-read-token': initialAuthTokenState('simulator-read-token'),
    'simulator-write-token': initialAuthTokenState('simulator-write-token'),
    'privileged-token': initialAuthTokenState('privileged-token'),
    APP_TOKEN: initialAuthTokenState('APP_TOKEN'),
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function initialAuthTokenState(key: AuthTokenKey) {
  return {
    isLoading: false,
    value: null,
    waitingRequests: [],
  };
}

function fromAuthTokensIsLoading(_state: IAuthState) {
  return _state.authTokens
    ? Object.values(_state.authTokens).some(({ isLoading }) => isLoading)
    : false;
}
