import {
  AuthTokenSettings,
  Scope,
  ThndrAPIToken,
  UserAuthTrustLevels,
} from '@/constants/auth';
import { Config, derived } from '.';

export enum USER_TYPES {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}
export interface IUserInfo {
  email: string;
  has_active_recovery: boolean;
  name: string;
  phone_number: string;
  type: USER_TYPES;
  uid: string;
  username: string | null;
  has_pin: boolean;
  has_password: boolean;
  has_security_question: boolean;
  alpaca_id: string | null;
}

type State = {
  activeModule: string;
  globalError: string;
  hasLoadedAccount: boolean;
  authentication: {
    userInfo: {
      data: IUserInfo;
    };
    authTokens: {
      APP_TOKEN: {
        isLoading: boolean;
        value: ThndrAPIToken | null;
        trust_level?: UserAuthTrustLevels;
        expires?: Date;
        scopes?: Scope[];
        waitingRequests: Function[];
        isPrivileged: boolean;
      };
      'firebase-token': {
        isLoading: boolean;
        value: ThndrAPIToken | null;
        trust_level?: UserAuthTrustLevels;
        expires?: Date;
        scopes?: Scope[];
        waitingRequests: Function[];
      };
    };
  };
};

export const state: State = {
  authentication: {
    userInfo: {
      data: {
        email: '',
        has_active_recovery: false,
        name: '',
        phone_number: '',
        type: USER_TYPES.UNVERIFIED,
        uid: '',
        username: null,
        has_pin: false,
        has_password: false,
        has_security_question: false,
        alpaca_id: '',
      },
    },
    authTokens: {
      APP_TOKEN: {
        isLoading: false,
        value: null,
        waitingRequests: [],
        isPrivileged: derived<State, Config['state'], boolean>(
          (_state, rootState) =>
            rootState.authentication?.authTokens['APP_TOKEN']?.scopes?.length >=
            30, //FIXME: better solution
        ),
      },
      'firebase-token': {
        isLoading: false,
        value: null,
        waitingRequests: [],
      },
    },
  },
  activeModule: 'User',
  globalError: null,
  hasLoadedAccount: derived<State, Config['state'], boolean>(
    (_, globalState) =>
      globalState.auth.hasLoadedStoredTokens &&
      globalState.auth.hasFirebaseToken &&
      !globalState.accountAccess.hasMissingInfo,
  ),
};

function initialAuthTokenState() {
  return {
    isLoading: false,
    value: null,
    waitingRequests: [],
  };
}
