import { thndrApi } from '@thndr/services/api';
import { MarketDepth } from '../types';

type IPriceDepthPayload = {
  assetId: string;
};

export const getMarketDepth = async ({ assetId }: IPriceDepthPayload) => {
  const { data } = await thndrApi.get<MarketDepth>(
    `/assets-service/market-depth/${assetId}`
  );
  return data;
};
