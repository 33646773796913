import { http } from '@/store/effects';
import { IEligibleProduct, IComplianceForm } from './types';
import { AsyncRequestWithToken } from '@/util/types';

type IEligibleProductsResponse = { eligibilities: IEligibleProduct[] };
type IUpdateComplianceFormResponse = Partial<IComplianceForm>;

export const getUserEligibility = async (token: string) => {
  const { data } = await http.instance.get<IEligibleProductsResponse>(
    `/compliance-service/eligibilities`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        sessionId: global.sessionID,
      },
    },
  );
  return data;
};

export const getComplianceForm = async (token: string) => {
  try {
    const { data } = await http.instance.get<IUpdateComplianceFormResponse>(
      '/compliance-service/account-forms',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          sessionId: global.sessionID,
        },
      },
    );
    return data;
  } catch (e) {}
};
