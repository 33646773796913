import { AccountType } from '@/constants/user';
import { Config, derived } from '@/store';

export type IUserState = {
  isLoadingUser: boolean;
  isUpdatingUser: boolean;
  isSyncingUser: boolean;
  hasLoadedBackend: boolean;
  watchlist: string[];
  id: string | null;
  user_id: string | null;
  name: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: string | null;
  username: string | null;
  account_type: AccountType;
  profile_picture: string | null;
  notification_id: string | null;
  created_at: string | null;
  error: Error | null;
  isInvestor: boolean;
  isBasic: boolean;
  tags: { name: string; created_at: string; updated_at: string }[];
  phone_number: string;
  is_available: boolean;
};

export const state: IUserState = {
  watchlist: [],
  isSyncingUser: false,
  isLoadingUser: false,
  isUpdatingUser: false,
  hasLoadedBackend: false,
  id: null,
  user_id: null,
  name: null,
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  username: null,
  account_type: null,
  notification_id: null,
  profile_picture: null,
  created_at: null,
  error: null,
  phone_number: '',
  tags: [],
  is_available: false,
  isInvestor: derived<IUserState, Config['state'], boolean>(
    (s) => s.account_type === AccountType.investor,
  ),
  isBasic: derived<IUserState, Config['state'], boolean>(
    (s) => s.account_type === AccountType.basic,
  ),
};
