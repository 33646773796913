import { namespaced } from 'overmind/config';

import * as settings from './settings';
import * as auth from './auth';
import * as accountAccess from './account-access';
import * as user from './user';
import * as market from './market';
import * as managedFunds from './managed-funds';
import * as investor from './investor';
import * as orders from './orders';
import * as eligibility from './eligibility';
import * as feature from './unleash';
import * as analytics from '../../services/analytics';
import { newSubscription } from '@thndr/namespace/subscriptions';
import { newMarket } from '@thndr/namespace/market';
import { newAssets } from '@thndr/namespace/assets';

export default namespaced({
  settings,
  auth,
  accountAccess,
  user,
  market,
  managedFunds,
  investor,
  orders,
  eligibility,
  feature,
  newSubscription,
  newMarket,
  newAssets,
  analytics,
});
