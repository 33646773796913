import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { instance } from './instance';

type DeleteWithTokenArgs = {
  url: string;
  token: string;
  config?: AxiosRequestConfig;
  params?: any;
};

export const deleteWithToken = <Response = any>({
  token,
  url,
  config: { headers, ...config } = {},
  params,
}: DeleteWithTokenArgs) => {
  const allHeaders = {
    // FIXME: now we manually set token type [Auth, Bearer], but it should be refactored somehow
    Authorization: token,
    sessionId: global.sessionID,
    ...headers,
  };
  return instance.delete<Response>(url, {
    headers: allHeaders,
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    ...config,
  });
};
