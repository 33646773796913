import { useQuery, useMutation } from 'react-query';
import thndrApi from './thndr-api';

const baseUrl = process.env['NEXT_PUBLIC_API_URL']+'/';
const mockBaseUrl = 'https://63555f5f483f5d2df3b31783.mockapi.io/';

const getToken = async () => {
  const token = await localStorage.getItem('token');
  return `Bearer ${token}`;
};

type useQueryProps = {
  key: string;
  path: string;
  mock?: boolean;
};

type useMutationProps = {
  path: string;
  mock?: boolean;
  type: 'post' | 'patch' | 'delete';
};

export const useThndrQuery = <T>({ key, path, mock }: useQueryProps) => {
  const axiosUrl = mock
    ? `${mockBaseUrl}${path.replaceAll('/', '-')}`
    : `${baseUrl}${path}`;

  const fetchdata = async (): Promise<T> => {
    const response = await thndrApi.get(axiosUrl);
    return response.data;
  };

  return useQuery(key, fetchdata);
};

export const useThndrMutation = <T>({ path, mock, type }: useMutationProps) => {
  const axiosUrl = mock
    ? `${mockBaseUrl}${path.replaceAll('/', '-')}`
    : `${baseUrl}${path}`;

  const mutateData = async (body: Record<string, any>): Promise<T> => {
    const response = await thndrApi[type](axiosUrl, body);
    return response.data;
  };

  return useMutation(mutateData);
};
