import { useQuery, useQueryClient } from 'react-query';
import { getTrendingSecurities } from '../api/getTrendingSecurities';
import { MarketName } from '::root/libs/namespace/market/src';
import { getTimeTillNextUpdate } from '../util';
import { randomTimeWrapper } from '@thndr/services/api';

const TRENDING_KEY = 'trending-securities';
const useTrendingSecurities = ({
  market,
  enabled = true,
  isSubscribed = false,
}: {
  market: MarketName;
  enabled: boolean;
  isSubscribed?: boolean;
}) => {
  const queryClient = useQueryClient();
  const lastUpdatedAt = queryClient.getQueryState([
    TRENDING_KEY,
    market,
  ])?.dataUpdatedAt;
  const timeTillNextUpdate = lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 15)
    : 15 * 60 * 1000;
  const { data, isLoading } = useQuery({
    queryKey: [TRENDING_KEY, market],
    queryFn: () =>
      randomTimeWrapper({
        isSubscribed,
        skipDelay: data ? false : true,
        callback: async () => {
          const { results } = await getTrendingSecurities({ market });
          return results;
        },
      }),

    staleTime: timeTillNextUpdate,
    cacheTime: timeTillNextUpdate,
    enabled: enabled,
  });
  return {
    data,
    isLoading,
  };
};

export { useTrendingSecurities };
