import { ORDER_SETTLEMENT, ORDER_TYPES } from '@/constants/orders';
import { IOrderForm } from '@/models/orders';
import { IOwnedAsset } from '@thndr/namespace/portfolio';

export enum Custodian {
  AUB = 'AUB',
  THN = 'THN',
  OTHER = 'OTHER',
}

const CUSTODIAN_SETTLEMENT_KEY_MAP = {
  [ORDER_SETTLEMENT.T0]: 'qty_t0_per_custodian',
  [ORDER_SETTLEMENT.T1]: 'qty_t1_per_custodian',
  [ORDER_SETTLEMENT.T2]: 'qty_settled_per_custodian',
} as const;

export const getSortedCustodians = () => {
  // might need to ad logic here if we decide to change priority
  return [Custodian.AUB, Custodian.THN];
};

// checks if a stock is available under multiple custodians given the owned asset info of the stock
export const splitOrder = ({
  order,
  ownedSecurityInfo,
}: {
  order: IOrderForm;
  ownedSecurityInfo: IOwnedAsset;
}) => {
  if (!order) {
    return [];
  }
  if (!ownedSecurityInfo) {
    return [order];
  }
  // return false if buy order
  if (order?.order_type === ORDER_TYPES.BUY) {
    return [order];
  }
  // get correct key for check based on settlement e.g. T0 -> qty_t0_per_custodian, T2 -> qty_t1_per_custodian
  const custodianCheckKey =
    CUSTODIAN_SETTLEMENT_KEY_MAP[order?.settlement ?? ORDER_SETTLEMENT.T2];
  // get owned stock qty per custodian based on settlement in the form of {"THN": 2}
  const qtyPerCustodian: any = ownedSecurityInfo?.[custodianCheckKey]?.reduce(
    (custodians, custodian) => {
      if (custodian?.qty > 0) {
        return { ...custodians, [custodian?.custodian]: custodian?.qty };
      } else {
        return { ...custodians };
      }
    },
    {},
  );
  // get allowed custodians sorted based on order settlement
  const [primaryCustodian, secondaryCustodian] = getSortedCustodians();
  // check if stock is available under multiple custodians
  const multipleCustodians =
    qtyPerCustodian?.[primaryCustodian] &&
    qtyPerCustodian?.[secondaryCustodian];
  const ownedCustodian = qtyPerCustodian && Object.keys(qtyPerCustodian)?.[0];
  // check if owned quantity under primary custodian is sufficient for chosen settlement
  const missingQty = order.amount - qtyPerCustodian?.[primaryCustodian];
  if (multipleCustodians && missingQty > 0) {
    // check if missing quantity is available under secondary custodian
    if (qtyPerCustodian[secondaryCustodian] < missingQty) {
      return [{ ...order, custodian: primaryCustodian }];
    }
    return [
      {
        ...order,
        custodian: primaryCustodian,
        amount: qtyPerCustodian[primaryCustodian],
        idempotent_id: `${order?.idempotent_id ?? ''}1`,
      },
      {
        ...order,
        custodian: secondaryCustodian,
        amount: missingQty,
        idempotent_id: `${order?.idempotent_id ?? ''}2`,
      },
    ];
  }
  return [{ ...order, custodian: ownedCustodian ?? primaryCustodian }];
};
