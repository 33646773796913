import { getStorageItem, setStorageItem } from '@thndr/services/local-storage';
import { LOCAL_STORAGE_KEYS } from './constants';

export const getToken = async () => {
  try {
    return await getStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  } catch (error) {
    console.log('Error in getToken', error);
    return null;
  }
};

export const setToken = async (value: string) => {
  try {
    await setStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, value);
  } catch (error) {
    console.log('Error in setToken', error);
  }
};
