import React, { useEffect } from 'react';
import { useActions, useStates, useEffects } from '@/store';
import { AssetClasses, IStock, LOADING_STATUS } from '../types';
import { CHART_INTERVALS } from '@/constants/market';
import { arrayToDictionary } from '@/util/formatters';

export const useSearch = () => {
  const { newAssets } = useStates();

  const { newAssets: assetActions } = useActions();

  const search = async (query: string) => {
    await assetActions.getSearch(query);
  };
  const updateisVisible = (isVisible: boolean) => {
    assetActions.updateisVisible(isVisible);
  };

  return {
    assets: newAssets.search.assets,
    isLoading: newAssets.search.status === LOADING_STATUS.LOADING,
    count: newAssets.search.count,
    query: newAssets.search.query,
    search,
    updateisVisible,
    isVisible: newAssets.search.isVisible,
  };
};
