import { Action, AsyncAction } from '@/store';
import { IFee } from '@/models/investor';
import { FEE_TYPES } from '@/constants/fees';
import { AuthTokenKey } from '@/constants/auth';
import { MarketName } from '@thndr/namespace/market';

export const getCurrentMarketToken: Action<'read' | 'write', AuthTokenKey> = (
  { state },
  actionType,
) => {
  const market = state.newMarket.market;
  if (market === MarketName.EGYPT) {
    return actionType === 'read'
      ? 'simulator-read-token'
      : 'simulator-write-token';
  } else {
    return 'privileged-token';
  }
};

export const getInvestorData: AsyncAction = async ({ state, actions }) => {
  if (!state.investor.isLoadingInvestorData) {
    state.investor.isLoadingInvestorData = true;
    if (
      state.authentication.authTokens['APP_TOKEN'].scopes?.length < 30 &&
      !state.newMarket.simulatorEnabled
    ) {
      state.auth.privilegedTokenNeedsRenew = true;
      return;
    }

    const investorsData = [actions.orders.getOrders()];
    try {
      await Promise.allSettled(investorsData);
    } finally {
      state.investor.isLoadingInvestorData = false;
    }
  }
};

const getFeeValue = (cost: number, fee: IFee) => {
  return Math.max((cost * fee.percentage) / 100, fee.minimum);
};

type IFees = {
  custody: number;
  government: number;
};

export const calculateFees: Action<number, IFees> = ({ state }, cost) => {
  const fees = state.investor.fees.data;
  let governmentFees = 0;
  let custodyFees = 0;
  fees?.map((fee) => {
    const feeValue = getFeeValue(cost, fee);
    if (fee.name === FEE_TYPES.CUSTODY) {
      custodyFees = feeValue;
    } else {
      governmentFees += feeValue;
    }
  });
  return { custody: custodyFees, government: governmentFees };
};
