import { getWithToken } from '@thndr/services/http';
import { IGetMarketHoursPayload, IGetMarketHoursResponse } from '../types';

export const getMarketHours = async (
  token: string | null,
  { market }: IGetMarketHoursPayload
) => {
  const params = {
    market,
  };

  const { data } = await getWithToken<IGetMarketHoursResponse>({
    token: `Bearer ${token}`,
    url: `/market-service/markets/hours`,
    params,
  });
  return data;
};
