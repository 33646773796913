import { AsyncAction, Action, json } from '@/store';
import { FeatureLoadingState } from '@/services/feature/types';
import { EVENTS } from 'unleash-proxy-client';

export const initialize: Action = ({ effects, actions, state }) => {
  effects.feature.unleash.on(EVENTS.UPDATE, () => {
    actions.feature.setToggles();
  });

  effects.feature.unleash.on(EVENTS.INIT, () => {
    actions.feature.featuresReady();
  });
};

export const updateProperties: Action<object> = ({ state, effects }, props) => {
  Object.assign(state.feature.context.properties, props);
  effects.feature.updateContext(json(state.feature.context));
};

// export const updateDeviceProperties: AsyncAction = async ({
//   state,
//   effects,
// }) => {
//   Object.assign(state.feature.context.properties, {
//     bundleVersion: await effects.device.getBundleVersion(),
//   });
//   effects.feature.updateContext(json(state.feature.context));
// };

export const setUserId: Action<string> = ({ state, effects }, userId) => {
  state.feature.context.userId = userId;
  effects.feature.updateContext(json(state.feature.context));
};
export const setEmail: Action<string> = (
  { state, effects, actions },
  email,
) => {
  actions.feature.updateProperties({
    email,
  });
  // state.feature.context.properties.email = email;
  // effects.feature.updateContext(json(state.feature.context));
};
export const setUserTags: Action<string[]> = ({ state, effects }, tags) => {
  state.feature.context.properties.tags = tags;
  effects.feature.updateContext(json(state.feature.context));
};

export const clear: Action = ({ state, effects }) => {
  delete state.feature.context.userId;
  delete state.feature.context.sessionId;
  state.feature.context.properties.tags = [];
  effects.feature.updateContext(json(state.feature.context));
};

export const setSessionId: Action<string> = ({ state, effects }, sessionId) => {
  state.feature.context.sessionId = sessionId;
  effects.feature.updateContext(json(state.feature.context));
};
export const setToggles: Action = ({ state, effects }) => {
  state.feature.features = effects.feature.getAllFeatures();
  state.feature.lastFeatureUpdate = new Date();
};
export const featuresLoadTimeOut: Action = ({ state }) => {
  state.feature.didFeaturesLoad = FeatureLoadingState.Timeout;
};
export const featuresReady: Action = ({ state }) => {
  state.feature.didFeaturesLoad = FeatureLoadingState.Ready;
};
export const subscribeToFeatureUpdates: AsyncAction = async ({
  state,
  effects,
}) => {
  await effects.feature.start();
  state.feature.didFeaturesLoad = FeatureLoadingState.Loaded;
};

export const onInitializeOvermind = async ({ actions }) => {
  actions.feature.initialize();
};
