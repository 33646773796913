import { Button } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { withTranslation } from 'next-i18next';
import styled from 'styled-components';
import React from 'react';
import { logger } from '@thndr/services/logger';
const NextImage = styled.img`
  width: 500px;
  height: 350px;
  filter: var(--filter-img);
  margin-bottom: 15px;
`;

const H1 = styled.h1`
  color: var(--color-text-dark);
  // margin-bottom: 0px;
  line-height: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const SubContainer = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: var(--color-bg-widget);
  box-shadow: 0px 0px 5px var(--color-shade4);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
`;

const Paragraph = styled.p`
  color: var(--color-text-light);
  margin-bottom: 15px;
`;

interface AppErrorBoundaryProps {
  t: any;
}

class AppErrorBoundary extends React.Component<AppErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  constructor(props) {
    super(props);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error, errorInfo);
    super.componentDidCatch(error, errorInfo);
  }

  handleTryAgainClick = () => {
    window.location.reload();
  };

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <Container>
          <SubContainer>
            <NextImage src="/wrong.jpeg" />
            <H1>{t('wopsie')}</H1>
            <H1>{t('wrong')}</H1>
            <Paragraph>{t('wrongDescription')}</Paragraph>
            <Button
              style={{
                alignSelf: 'stretch',
                fontWeight: 'bold',
                backgroundColor: 'var(--color-green)',
                borderColor: 'var(--color-green)',
              }}
              onClick={this.handleTryAgainClick}
              type="primary"
              icon={<RedoOutlined />}
            >
              {t('tryAgain')}
            </Button>
          </SubContainer>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withTranslation('common')(AppErrorBoundary);
