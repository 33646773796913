import { ApplicationError } from '@/util/errors';
import { ISubscriptionResponse } from '../types';
import { getWithToken } from '@thndr/services/http';

type ISubscriptionsResponse = { subscriptions: ISubscriptionResponse[] };

export const getSubscriptionSettings = async (token: string | null) => {
  try {
    const { data } = await getWithToken<ISubscriptionsResponse>({
      url: `payment-service/subscriptions`,
      token: `Bearer ${token}`,
    });
    return data.subscriptions;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return [];
    } else {
      throw new ApplicationError(
        error,
        'errors.subscriptions.getSubscriptionSettings'
      );
    }
  }
};
