import { useContext } from 'react';
import { CartContext, SubsPlan } from '..';
import { CartState } from '../Context/Cart/CartContext';
type Context = {
  addSubscriptions: (subscription: SubsPlan[]) => void;
  removeSubscription: (subscription: string) => void;
  setSubscriptions: (subscription: SubsPlan[]) => void;
  clearCart: () => void;
  state: CartState;
};
export const useCart = () => {
  const {
    addSubscriptions,
    removeSubscription,
    setSubscriptions,
    clearCart,
    state,
  }: Context = useContext(CartContext);
  const cart = state.cart;
  return {
    addSubscriptions,
    removeSubscription,
    setSubscriptions,
    clearCart,
    cart,
    total: state.total,
    discount: state.discount,
  };
};
