import { useStates, useActions } from '::store';
import { MarketName } from '../types';

const useMarket = () => {
  const {
    newMarket: { market, marketHours },
  } = useStates();
  const { newMarket: marketActions } = useActions();
  const getMarketHours = async (market: MarketName) => {
    await marketActions.getMarketHours({ market });
  };

  return {
    market,
    marketHours,
    getMarketHours,
  };
};

export default useMarket;
