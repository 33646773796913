import {
  OrderModes,
  OrderSettlement,
  OrderSteps,
  OrderSubmissionStatus,
} from './types';

export enum ORDER_TYPE {
  BUY = 'BUY',
  SELL = 'SELL',
}
export enum ORDER_PRICE_TYPES {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
}
export const CUSTODY_FEES = 0.0005;
export const ORDER_BUFFER = 0.002;
export const MARKET_ORDER_MARGIN = 0.03;
export const MINIMUM_BUFFER = 10;
export const THNDR_COMMISSION = 2;
export const WAIVED_THNDR_COMMISSION = 0;
export const MCDR_FEES = 0.0001;
export const MAXIMUM_MCDR = 5000;
export const MINIMUM_FRA = 1;
export const FRA_FEES = 0.00005;
export const MAXIMUM_FRA = 250;
export const RISK_FEES = 0.00005;
export const MAXIMUM_RISK = 5000;
export const EGX_FEES = 0.0001;
export const MAXIMUM_EGX = 5000;

export const initialOrderViewState = {
  orderDirection: null,
  assetClass: null,
  step: OrderSteps.OrderDetails,
  assetId: null,
  shares: 0,
  price: 0,
  amount: 0,
  orderType: null,
  isVisible: false,
  orderSubmission: OrderSubmissionStatus.Loading,
  timeInForce: null,
  timeInForceDate: null,
  settlement: OrderSettlement.T2,
  orderMode: OrderModes.Shares,
  errorMessage: null,
};
