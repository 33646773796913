import { AxiosResponse } from 'axios';

import { getFirebaseToken } from './firebase';

import { AsyncRequest } from '@/util/types';
import { http } from '@/store/effects';
import { Scope, ThndrAPIToken } from '@/constants/auth';

export interface IGetAuthTokenResponse {
  auth_token: ThndrAPIToken;
}

export const getAPIToken: AsyncRequest<
  Scope[],
  ThndrAPIToken,
  {
    getCredentialToken?:
      | typeof getFirebaseToken
      | jest.Mock<ReturnType<typeof getFirebaseToken>>;
    post?:
      | typeof http.instance.post
      | jest.Mock<Promise<AxiosResponse<IGetAuthTokenResponse>>>;
  }
> = async (
  scopes,
  { getCredentialToken = getFirebaseToken, post = http.instance.post } = {},
) => {
  const firebase_token = await getCredentialToken();
  const {
    data: { auth_token },
  } = await post<IGetAuthTokenResponse>('auth-service/tokens/authorization', {
    data: { scopes },
    credentials: { firebase_token },
  });
  return auth_token as ThndrAPIToken;
};

interface IGetUserTokenPayload {
  data: {
    scopes: string[];
  };
  credentials: { firebase_token: string };
}

type ThndrUserToken = string;

// export const getUserToken: AsyncRequest<IGetUserTokenPayload, ThndrUserToken> =
//   async (payload) => {
//     logger.warn(
//       'deprecated: should refactor user getAPIToken instead and the respective effect',
//     );
//     const { data: token } = await http.instance.post<ThndrUserToken>(
//       'auth-service/tokens/authorization',
//       payload,
//     );
//     return token;
//   };
