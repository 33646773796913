import { thndrApi } from '@thndr/services/api';
import { IAsset } from '../types';

export const getSecurityDetail = async ({
  securityId,
  includeYearlyReturn,
}: {
  securityId: string;
  includeYearlyReturn?: boolean;
}): Promise<IAsset | undefined> => {
  const { data } = await thndrApi.get(
    `/assets-service/assets/${securityId}?include_feed=true&feed_detail=true&include_yearly_return=${includeYearlyReturn}`
  );
  return data;
};
