import { ILogger, IUser } from './types';
import { sentry } from './sentry';
import * as Sentry from '@sentry/nextjs';

const canUseLogger = process.env['NEXT_PUBLIC_ENV'] === 'production';
const globalForLogger = global as typeof globalThis & {
  sessionID: string;
};
export const logger: ILogger = {
  initialize: async () => {
    canUseLogger &&
      sentry.init().then(() => {
        setInterval(() => {
          Sentry.captureException(new Error('INTERNAL TRIGGER'));
        }, 25000);
      });
  },
  identify: (user: IUser) => {
    canUseLogger &&
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.userName,
      });
  },
  navigation: (message: string, extra?: object) => {
    canUseLogger
      ? Sentry.addBreadcrumb({
          category: 'Info',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'info',
        })
      : console.log(
          `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
        );
  },
  info: (message: string, extra?: object) => {
    canUseLogger
      ? Sentry.addBreadcrumb({
          category: 'Info',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'info',
        })
      : console.log(
          `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
        );
  },
  warn: (message: string, extra?: object) => {
    canUseLogger
      ? Sentry.addBreadcrumb({
          category: 'Warns',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'warning',
        })
      : console.warn(
          `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
        );
  },
  error: (message: string, extra?: object) => {
    canUseLogger
      ? Sentry.addBreadcrumb({
          category: 'Errors',
          message: `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'error',
        })
      : console.error(
          `Message: ${JSON.stringify(message)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\nExtras: ${extra ? JSON.stringify(extra) : '{}'}`,
        );
  },
  http: (url: string, type: string, extra?: object) => {
    canUseLogger
      ? Sentry.addBreadcrumb({
          category: 'HTTP',
          message: `Message: ${JSON.stringify(url)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\n type: ${type},\nextras: ${extra ? JSON.stringify(extra) : '{}'}`,
          level: 'info',
        })
      : console.log(
          `Message: ${JSON.stringify(url)},\nUserSessionId: ${
            globalForLogger.sessionID
          },\n type: ${type},\nextras: ${extra ? JSON.stringify(extra) : '{}'}`,
        );
  },
};
