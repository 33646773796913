import { useThndrMutation } from '@thndr/services/api';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SUBS_QUERY_KEYS } from '../../query-keys';
import { getSubscriptions } from '../api/subscriptions';
import { getPlanIds, getProducts } from '../logic/mySubs';
import {
  PAYMENT_METHOD_STATUS,
  StripePayload,
  Subscription,
  SubscriptionCurrency,
  SubscriptionFrequency,
  SubscriptionProduct,
  BackEndErrors,
} from '../types';

enum SubsStatus {
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  TRIALING = 'TRIALING',
  ACTIVE = 'ACTIVE',
  PAST_DUE = 'PAST_DUE',
  CANCELED = 'CANCELED',
  UNPAID = 'UNPAID',
}

type CreateSubscriptionsResponse = {
  client_secret: string | null;
  subscription: Subscription;
};
type CreateSubscriptionPayload = {
  products: {
    product: SubscriptionProduct;
    frequency: SubscriptionFrequency;
    currency: SubscriptionCurrency;
  }[];
  promoCode: string;
  stripePayload: StripePayload;
  paymentMethodId?: string | null;
};

export const useMySubs = (subsId?: string) => {
  const { data, isError } = useQuery(
    SUBS_QUERY_KEYS.subscriptions,
    getSubscriptions
  );
  const query = useQueryClient();
  const mySubs = data?.data?.results as Subscription[];
  const myPlanIds = mySubs ? getPlanIds({ mySubs: mySubs }) : [];
  const myProducts = mySubs ? getProducts({ mySubs: mySubs }) : [];
  const { mutateAsync: mutateUpgradeSubscription } =
    useThndrMutation<Subscription>({
      path: `payment-service/v2/subscriptions/${subsId}/upgrade`,
      type: 'post',
    });

  const {
    mutateAsync: mutateCreateSubscription,
    isError: isSubscriptionError,
  } = useThndrMutation<CreateSubscriptionsResponse>({
    path: 'payment-service/v2/subscriptions',
    type: 'post',
  });

  const [hasError, setHasError] = useState<{ isError: boolean; key: string }>({
    isError: false,
    key: '',
  });
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isPastDue = mySubs?.map((subscription) => {
    return subscription?.status == 'PAST_DUE';
  })[0];
  const createSubsctiption = async ({
    products,
    promoCode,
    stripePayload,
    paymentMethodId,
  }: CreateSubscriptionPayload) => {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        if (!paymentMethodId) throw Error('payment_method');
        const response = await mutateCreateSubscription({
          items: products,
          promotion_code: promoCode,
        });
        if (response?.client_secret == null) {
          if (
            response?.subscription?.status == SubsStatus.TRIALING ||
            response?.subscription?.status == SubsStatus.ACTIVE ||
            response?.subscription?.discount_prc == 100
          ) {
            query.invalidateQueries(SUBS_QUERY_KEYS.subscriptions);
            query.invalidateQueries(SUBS_QUERY_KEYS.paymentMethod);
            setSuccess(true);
            setHasError({ isError: false, key: '' });
            setIsLoading(false);
            if (typeof window !== 'undefined') {
              localStorage.removeItem('userCart');
              localStorage.removeItem('userCartTotal');
            }
          } else {
            setHasError({ isError: true, key: 'subscribe.failure' });
            setIsLoading(false);
          }
        } else {
          const result = await stripePayload.stripe.confirmCardPayment(
            response?.client_secret as string,
            {
              payment_method: paymentMethodId as string,
            }
          );
          if (
            result.paymentIntent?.status == PAYMENT_METHOD_STATUS.OK ||
            response?.subscription?.discount_prc == 100
          ) {
            setHasError({ isError: false, key: '' });
            setIsLoading(false);
            if (typeof window !== 'undefined') {
              localStorage.removeItem('userCart');
              localStorage.removeItem('userCartTotal');
            }
            setTimeout(() => {
              query.invalidateQueries(SUBS_QUERY_KEYS.subscriptions);
              query.invalidateQueries(SUBS_QUERY_KEYS.paymentMethod);
              setSuccess(true);
            }, 5000);
          } else {
            setHasError({
              isError: true,
              key: 'subscribe.failure',
            });
            setIsLoading(false);
          }
        }
        return { isSubscriptionError };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (
          error?.response?.data?.detail?.type === BackEndErrors.INVALID_BODY
        ) {
          setHasError({ isError: true, key: 'subscribe.promoError' });
        } else if (error?.message === 'payment_method') {
          setHasError({ isError: true, key: 'paymentMethodError.setup' });
        } else {
          setHasError({ isError: true, key: 'subscribe.failure' });
        }
        setIsLoading(false);
      }
    }, 4000);
  };
  const upgradeSubscription = async () => {
    try {
      setIsLoading(true);
      const response = await mutateUpgradeSubscription({});
      if (response?.frequency == 'YEARLY') {
        setSuccess(true);
        setHasError({ isError: false, key: '' });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setHasError({ isError: true, key: 'subscribe.upgradingFailure' });
      }
      query.invalidateQueries(SUBS_QUERY_KEYS.subscriptions);
      query.invalidateQueries(SUBS_QUERY_KEYS.paymentMethod);
    } catch (error) {
      if (
        error?.response?.data?.detail?.type === BackEndErrors.PAYMENT_DECLINED
      ) {
        setHasError({ isError: true, key: 'errors.PAYMENT_DECLINED' });
      } else if (
        error?.response?.data?.detail?.type === BackEndErrors.CARD_EXPIRED
      ) {
        setHasError({ isError: true, key: 'errors.CARD_EXPIRED' });
      } else if (
        error?.response?.data?.detail?.type === BackEndErrors.INSUFFICIENT_FUNDS
      ) {
        setHasError({ isError: true, key: 'errors.INSUFFICIENT_FUNDS' });
      } else {
        setHasError({ isError: true, key: 'subscribe.upgradingFailure' });
      }
      console.error(error);
      setIsLoading(false);
    }
  };
  const cancelSubsctiption = () => {
    return true;
  };

  return {
    myProducts,
    myPlanIds,
    mySubs,
    subscriptionsProvider: data?.data?.provider,
    isPastDue,
    isError,
    isLoading,
    hasError,
    success,
    setSuccess,
    setHasError,
    cancelSubsctiption,
    createSubsctiption,
    upgradeSubscription,
  };
};
