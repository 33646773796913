const baseTheme = {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  sideBarWidth: 65,
  headerHeight: 64,
  minWidgetHeight: 245,
  padding: 20,
  borderRadius: [0, 7, 10, 15, 30],
  background: '#F7F7F7',
  space: [2, 4, 8, 16, 32, 50, 64, 128, 256],
  fontFamily: {
    black: 'Tajawal, Modernist-regular, sans-serif',
    bold: 'Tajawal, Modernist-bold, sans-serif',
    regular: 'Tajawal, Modernist-regular, sans-serif',
    mainText: 'Tajawal, Modernist-bold, sans-serif',
    extraBold: 'Tajawal, Modernist-bold, sans-serif',
    extraThin: 'Tajawal, Modernist-regular, sans-serif',
    light: 'Tajawal, Modernist-regular, sans-serif',
    semiBold: 'Tajawal, Modernist-bold, sans-serif',
    thin: 'Tajawal, Modernist-regular, sans-serif',
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Tajawal, Modernist-regular, monospace',
  },
  newFontFamily: {
    black: ' RecoletaRegular, sans-serif',
    bold: ' RecoletaBold, sans-serif',
    regular: ' RecoletaRegular, sans-serif',
    mainText: 'RecoletaBold, sans-serif',
    extraBold: 'RecoletaBold, sans-serif',
    extraThin: ' RecoletaRegular, sans-serif',
    light: ' RecoletaRegular, sans-serif',
    semiBold: ' RecoletaRegular, sans-serif',
    thin: 'RecoletaRegular, sans-serif',
    body: 'DM Sans, sans-serif',
    heading: 'inherit',
    monospace: 'RecoletaRegular, monospace',
  },
  fontFamilyArabic: {
    black: ' KalligArbRegular, sans-serif',
    bold: ' NaveidBold, sans-serif',
    regular: ' KalligArbRegular, sans-serif',
    mainText: 'NaveidBold, sans-serif',
    extraBold: 'NaveidBold, sans-serif',
    extraThin: ' KalligArbRegular, sans-serif',
    light: ' KalligArbRegular, sans-serif',
    semiBold: ' NaveidBold, sans-serif',
    thin: 'KalligArbRegular, sans-serif',
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'KalligArbRegular, monospace',
  },
  fontWeights: {
    light: 300,
    body: 400,
    heading: 700,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {},
  text: {},
};

export const lightTheme = {
  ...baseTheme,
  colors: {
    background: '#F9F9F9',
    widget: '#FFFFFF',
    inverted: '#000000',
    header: '#FFFFFF',
    sideBar: '#FFFFFF',
    inActiveSideBarIcon: '#666',

    popup: '#FFFFFF',

    base0: '#FFFFFF',
    base1: '#f8f8f8',
    base2: '#EEEEEE',
    green600: '#6DCC6E',
    green40: '#BCFFBC',
    greyMedium: '#8D8D8D',
    peach: 'rgba(255, 150, 107, 0.5)',
    peachDark: '#CC4D00',
    textLight: '#666666',
    textDark: '#000000',
    textInverted: '#FFFFFF',

    green: '#065849',
    greenLight: '#CCFCf3',
    greenDark: '#13806A',
    thndrGreen: '#8BD1C3',

    darkGray: '#525B60',
    cyan: '#8BD1C3',
    red: '#F25138',
    redLight: '#FFC6C9',
    redDark: '#662015',

    orange: '#FF580A',

    blue: '#1266ED',
    blueLight: '#3db5ff',
    white: '#fff',
    blueDark: '#1266ed',
    yellow: '#FFC90A',

    redChartGradient: '#ffeae8',
    greenChartGradient: '#e6fff8',
    greyChartGradient: '#EEEEEE',
    defaultChart: '#1890ff',

    shades: ['#F8F8F8', '#EEEEEE', '#EEEEEE', '#DDDDDD', '#BBBBBB'],
  },
};

export const darkTheme = {
  ...baseTheme,
  colors: {
    background: '#111111',
    widget: '#151515',
    inverted: '#FFFFFF',
    sideBar: '#151515',
    header: '#151515',
    inActiveSideBarIcon: '#888888',

    popup: '#FFFFFF',

    base0: '#FFFFFF',
    base1: '#1A1A1A',
    base2: '#222222',

    textLight: '#BBBBBB',
    textDark: '#FFFFFF',
    textInverted: '#FFFFFF',

    green: '#065849',
    greenLight: '#d1fff6',
    greenDark: '#13806A',
    thndrGreen: '#8BD1C3',

    darkGray: '#525B60',
    cyan: '#8BD1C3',
    red: '#FF7081',
    redLight: '#FFC6C9',
    redDark: '#662015',

    orange: '#D03C06',

    blue: '#6BA4FF',
    blueLight: '#3db5ff',
    white: '#fff',
    blueDark: '#141842',
    yellow: '#FFC90A',

    redChartGradient: '#471811',
    greenChartGradient: '#102e25',
    greyChartGradient: '#333333',
    defaultChart: '#2b7bf6',

    shades: ['#1A1A1A', '#222222', '#333333', '#555555', '#888888'],
  },
};

export const fontsArabic = {
  smallRegular: {
    fontSize: baseTheme.fontSizes[0],
    fontFamily: baseTheme.fontFamilyArabic.regular,
  },

  smallBold: {
    fontSize: baseTheme.fontSizes[0],
    fontFamily: baseTheme.fontFamilyArabic.bold,
  },

  mediumRegular: {
    fontSize: baseTheme.fontSizes[1],
    fontFamily: baseTheme.fontFamilyArabic.regular,
  },

  mediumBold: {
    fontSize: baseTheme.fontSizes[1],
    fontFamily: baseTheme.fontFamilyArabic.semiBold,
  },

  largeRegular: {
    fontSize: baseTheme.fontSizes[2],
    fontFamily: baseTheme.fontFamilyArabic.regular,
  },

  largeBold: {
    fontSize: baseTheme.fontSizes[2],
    fontFamily: baseTheme.fontFamilyArabic.bold,
  },

  xlargeRegular: {
    fontSize: baseTheme.fontSizes[3],
    fontFamily: baseTheme.fontFamilyArabic.regular,
    marginBottom: 5,
  },

  xlargeBold: {
    fontSize: baseTheme.fontSizes[4],
    fontFamily: baseTheme.fontFamilyArabic.bold,
    marginBottom: 5,
  },
};
export const fonts = {
  smallRegular: {
    fontSize: baseTheme.fontSizes[0],
    fontFamily: baseTheme.fontFamily.regular,
  },

  smallBold: {
    fontSize: baseTheme.fontSizes[0],
    fontFamily: baseTheme.fontFamily.bold,
  },

  mediumRegular: {
    fontSize: baseTheme.fontSizes[1],
    fontFamily: baseTheme.fontFamily.regular,
  },

  mediumBold: {
    fontSize: baseTheme.fontSizes[1],
    fontFamily: baseTheme.fontFamily.semiBold,
  },

  largeRegular: {
    fontSize: baseTheme.fontSizes[2],
    fontFamily: baseTheme.fontFamily.regular,
  },

  largeBold: {
    fontSize: baseTheme.fontSizes[2],
    fontFamily: baseTheme.fontFamily.bold,
  },

  xlargeRegular: {
    fontSize: baseTheme.fontSizes[3],
    fontFamily: baseTheme.fontFamily.regular,
    marginBottom: 5,
  },

  xlargeBold: {
    fontSize: baseTheme.fontSizes[4],
    fontFamily: baseTheme.fontFamily.bold,
    marginBottom: 5,
  },
};

export const newFonts = {
  smallRegular: {
    fontSize: baseTheme.fontSizes[0],
    fontFamily: baseTheme.newFontFamily.body,
  },

  smallBold: {
    fontSize: baseTheme.fontSizes[0],
    fontFamily: baseTheme.newFontFamily.bold,
  },

  mediumRegular: {
    fontSize: baseTheme.fontSizes[1],
    fontFamily: baseTheme.newFontFamily.regular,
  },

  mediumBold: {
    fontSize: baseTheme.fontSizes[1],
    fontFamily: baseTheme.newFontFamily.extraBold,
  },
  semiBold: {
    fontSize: baseTheme.fontSizes[1],
    fontFamily: baseTheme.newFontFamily.semiBold,
  },
  largeRegular: {
    fontSize: baseTheme.fontSizes[2],
    fontFamily: baseTheme.newFontFamily.regular,
  },

  largeBold: {
    fontSize: baseTheme.fontSizes[2],
    fontFamily: baseTheme.newFontFamily.bold,
  },

  xlargeRegular: {
    fontSize: baseTheme.fontSizes[3],
    fontFamily: baseTheme.newFontFamily.regular,
    marginBottom: 5,
  },

  xlargeBold: {
    fontSize: baseTheme.fontSizes[4],
    fontFamily: baseTheme.newFontFamily.bold,
    marginBottom: 5,
  },
};
const defaultTheme = lightTheme;

export type Theme = typeof defaultTheme & {
  rtl: boolean;
};

export default defaultTheme;
