import { CardElementComponent } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js/types/stripe-js/stripe';
import { StripeElements } from '@stripe/stripe-js';

export interface ISubscriptionResponse {
  product: string;
  status: string;
  next_billing_date: string;
}

export enum ProductsEnum {
  EG_RACER = 'EG_RACER',
  SPARK = 'SPARK',
  Express_Egypt = 'EG_EXPRESS',
}

export type Product = `${ProductsEnum}`;

export type SubscriptionFrequency = 'MONTHLY' | 'YEARLY';

export type SubscriptionProduct = 'EG_EXPRESS' | 'SPARK' | 'EG_RACER';

export type SubscriptionCurrency = 'EGP' | 'USD' | 'AED';

export type SubscriptionStatus =
  | 'ACTIVE'
  | 'PAST_DUE'
  | 'PENDING_CANCELLATION'
  | 'PENDING_PAYMENT'
  | 'TRIALING';

export enum BackEndErrors {
  INVALID_BODY = 'INVALID_BODY',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  CARD_EXPIRED = 'CARD_EXPIRED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}
export type SubscriptionFeature = {
  name: string;
  subtitle: string;
  platforms: string[];
  hide?: boolean;
};
export enum discount_reason {
  MIGRATED_FROM_RACER = 'MIGRATED_FROM_RACER',
}
export enum SubscriptionProvider {
  STRIPE = 'STRIPE',
  WALLET = 'WALLET',
}
export type Subscription = {
  id: string;
  products: SubscriptionProduct[];
  plan_ids: string[];
  plans?: SubscriptionPlan[];
  status: SubscriptionStatus;
  frequency: SubscriptionFrequency;
  current_period_end: string;
  created_at: string;
  discount_prc: number;
  discount_amount: number;
  discount_reason?: discount_reason | string | null;
  cancel_at_period_end?: string;
};

export type SubscriptionPlan = {
  id: string;
  product_name: SubscriptionProduct;
  frequency: SubscriptionFrequency;
  currency: SubscriptionCurrency;
  price: number;
  is_deprecated: boolean;
  inCart?: boolean;
};

export type GetProductsResponse = {
  results: SubscriptionPlan[];
  count: number;
};

export type PaymentMethodResponse = {
  id: string;
  user_id: string;
  has_trialed: boolean;
  name: string;
  email: string;
  currency: string;
  payment_method: {
    id: string;
    card: {
      last_4_digits: string;
      brand: string;
      expires: {
        month: number;
        year: number;
      };
    };
  };
};

export type IUserSubscriptionResponse = {
  client_secret: string;
};

export interface StripePayload {
  elements: StripeElements;
  stripe: Stripe;
  CardElement: CardElementComponent;
}

export interface ICreateSubscriptionParams {
  items: {
    product: SubscriptionProduct;
    frequency: SubscriptionFrequency;
    currency: SubscriptionCurrency;
  }[];
  promotion_code: string;
}
export enum PAYMENT_METHOD_STATUS {
  OK = 'succeeded',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_ACTION = 'requires_action',
  CANCELED = 'canceled',
  requires_confirmation = 'requires_confirmation',
  RESET = 'Set',
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
  FAIL = 'FAILED',
}
