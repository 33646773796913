import { http } from '@/store/effects';
import {
  ITagsResponse,
  IUserResponse,
  IValidateUsernameResponse,
  IValidateUsernamePayload,
  IUserInfo,
} from '@/models/types';
import { AsyncRequestWithToken } from '@/util/types';
import { ApplicationError } from '@/util/errors';

export const getUserInfo: AsyncRequestWithToken<string, IUserResponse> = async (
  token,
  user_id,
) => {
  try {
    const { data } = await http.getWithToken<IUserResponse>({
      token: `Bearer ${token}`,
      url: `/users-service/users/${user_id}`,
    });
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'errors.user.getUserInfo');
  }
};

export const getUserTags: AsyncRequestWithToken<void, ITagsResponse> = async (
  token,
) => {
  try {
    const { data } = await http.getWithToken<ITagsResponse>({
      token: 'Bearer ' + token,
      url: `/users-service/tags`,
    });
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'errors.user.getUserTags');
  }
};

export const postUserInfo: AsyncRequestWithToken<
  Partial<IUserResponse>,
  IUserResponse
> = async (token, payload) => {
  try {
    const { data } = await http.postWithToken<IUserResponse>(
      `Bearer ${token}`,
      `/users-service/users`,
      payload,
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'errors.user.patchUserInfo');
  }
};

export const patchUserInfo: AsyncRequestWithToken<
  Partial<IUserResponse>,
  IUserResponse
> = async (token, payload) => {
  try {
    const { data } = await http.patchWithToken<IUserResponse>(
      `Bearer ${token}`,
      `/users-service/users`,
      payload,
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'errors.user.patchUserInfo');
  }
};

export const validateUsername: AsyncRequestWithToken<
  IValidateUsernamePayload,
  IValidateUsernameResponse
> = async (token, payload) => {
  try {
    const { data } = await http.postWithToken<IValidateUsernameResponse>(
      `Bearer ${token}`,
      `/auth-service/v2/users/check-username`,
      payload,
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'errors.user.patchUserInfo');
  }
};

export interface IUserInfoPayload {
  firebaseToken: string;
  token?: string;
  username?: string;
}
interface IUpdateUserInfoResponse extends IUserInfo {}
// update username in auth-service
export async function updateUsername({
  token,
  firebaseToken,
  username,
}: IUserInfoPayload) {
  const { data } = await http.postWithToken<IUpdateUserInfoResponse>(
    `Bearer ${token}`,
    `auth-service/v2/users`,
    {
      data: { username },
      credentials: {
        firebase_token: firebaseToken,
      },
    },
    {},
  );
  return data;
}

export async function getUserInfoV2({ firebaseToken }: IUserInfoPayload) {
  try {
    const { data } = await http.instance.post<IUserResponse>(
      `/auth-service/v2/users/info`,
      {
        credentials: {
          firebase_token: firebaseToken,
          sessionId: global.sessionID,
        },
      },
      {},
    );
    return data;
  } catch (error) {
    throw new ApplicationError(error, 'errors.user.getUserInfoV2');
  }
}
