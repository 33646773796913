import { Product, Subscription } from '../types';

export const getPlanIds = ({ mySubs }: { mySubs: Subscription[] }) => {
  return mySubs?.reduce((acc: string[], sub: Subscription) => {
    return [...acc, ...sub?.plan_ids];
  }, []);
};
export const getProducts = ({ mySubs }: { mySubs: Subscription[] }) => {
  return mySubs?.reduce((acc: Product[], sub: Subscription) => {
    return [...acc, ...sub?.products];
  }, []);
};
