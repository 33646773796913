import {
  CHART_INTERVALS,
  IAdvancedChart,
  IAsset,
  LOADING_STATUS,
} from '../types';
import { Action, AsyncAction } from '::store';
import { arrayToDictionary } from '@/util/formatters';
import { MarketName } from '@thndr/namespace/market';
import { COMMODITIES_LIST_KEYS } from '@thndr/namespace/assets';
import { GoldIndexId } from '../constants';

export const getRecommendations: AsyncAction<{
  asset_id: string;
  recommendations_number?: number;
}> = async (
  { effects, state, actions },
  { asset_id, recommendations_number }
) => {
  try {
    state.newAssets.recommendations.status = LOADING_STATUS.LOADING;
    const token = state.authentication.authTokens.APP_TOKEN.value;
    //FIXME: add type for recommendations
    const recommendations: any = await effects.newAssets.getRecommendations({
      asset_id,
      token,
      recommendations_number,
    });
    const recommendationsFiltered = recommendations.results?.filter(
      (asset: Partial<IAsset>) => {
        return (
          asset.symbol !== COMMODITIES_LIST_KEYS.GOLD &&
          asset.id !== GoldIndexId &&
          !asset.is_right &&
          !asset.is_ipo
        );
      }
    );

    const asset_ids = recommendationsFiltered.map((asset: any) => {
      return asset.id;
    });
    state.newAssets.recommendations.data[asset_id] = asset_ids;

    let assetStateUpdate: { [asset_id: string]: IAsset } = {};
    recommendations.results?.map((asset: IAsset) => {
      if (asset.id) {
        assetStateUpdate[asset.id] = asset;
      }
    });
    actions.newAssets.updateAssetsState(assetStateUpdate);
    state.newAssets.recommendations.status = LOADING_STATUS.LOADED;
  } catch (error: any) {
    effects.logger.recordError(error, { fn: 'getRecommendations' });
    state.newAssets.recommendations.status = LOADING_STATUS.FAILURE;
  }
};

export const getAnalysis: AsyncAction<{ asset_id: string }> = async (
  { effects, state },
  { asset_id }
) => {
  try {
    state.newAssets.recommendations.status = LOADING_STATUS.LOADING;
    const token = state.authentication.authTokens.APP_TOKEN.value;
    //FIXME: add type for anaylsis
    const response: any = await effects.newAssets.getAnalysis({
      asset_id,
      token,
    });
    state.newAssets.anaylsis.data[asset_id] = response;
    state.newAssets.recommendations.status = LOADING_STATUS.LOADED;
  } catch (error: any) {
    effects.logger.recordError(error, { fn: 'getAnalytics' });
    state.newAssets.recommendations.status = LOADING_STATUS.FAILURE;
  }
};

export const getAnalysisReport: AsyncAction<{
  asset_id: string;
}> = async ({ effects, state }, { asset_id }) => {
  try {
    state.newAssets.recommendations.status = LOADING_STATUS.LOADING;
    const token = state.authentication.authTokens.APP_TOKEN.value;
    //FIXME: add type for anaylsis report
    const link: any = await effects.newAssets.getAnalysisReport({
      asset_id,
      token,
    });
    state.newAssets.anaylsis.data[asset_id] = {
      ...state.newAssets.anaylsis.data[asset_id],
      report_url: link?.report_url,
    };
    state.newAssets.recommendations.status = LOADING_STATUS.LOADED;
  } catch (error: any) {
    effects.logger.recordError(error, { fn: 'getAnalyticsReport' });
    state.newAssets.recommendations.status = LOADING_STATUS.FAILURE;
  }
};

//Asset specific actions

export const updateAssetsState: Action<{
  [asset_id: string]: IAsset;
}> = ({ state }, newAssets) => {
  Object.keys(newAssets).map((asset_id) => {
    if (state.newAssets.assetInfo.data[asset_id]) {
      Object.assign(
        state.newAssets.assetInfo.data[asset_id],
        newAssets[asset_id]
      );
    } else {
      state.newAssets.assetInfo.data[asset_id] = newAssets[asset_id];
    }
  });
};
export const getAssetInfo: AsyncAction<{ asset_id: string }, void> = async (
  { state, effects, actions },
  { asset_id }
) => {
  try {
    state.newAssets.assetInfo.status = LOADING_STATUS.LOADING;
    const token = state.authentication.authTokens.APP_TOKEN.value;
    let assetInfo = await effects.newAssets.getAssetInfo({
      asset_id,
      token,
    });
    if (assetInfo) {
      actions.newAssets.updateAssetsState({ [assetInfo.id]: assetInfo });
    }
    state.newAssets.assetInfo.status = LOADING_STATUS.LOADED;
  } catch (err) {
    effects.logger.recordError(err, { fn: 'getAssetInfo' });
    state.newAssets.assetInfo.status = LOADING_STATUS.FAILURE;
  }
};

export const setSelectedAsset: Action<string> = ({ state }, asset_id) => {
  state.newAssets.selectedAssetID = asset_id;
};
export const updateAssetWatchlistFullData: Action<{
  [asset_id: string]: IAsset;
}> = ({ state }, newAssets) => {
  Object.keys(newAssets).map((asset_id) => {
    if (state.newAssets.watchlistFullData.data[asset_id]) {
      Object.assign(
        state.newAssets.watchlistFullData.data[asset_id],
        newAssets[asset_id]
      );
    } else {
      state.newAssets.watchlistFullData.data[asset_id] = newAssets[asset_id];
    }
  });
};

export const getWatchlist: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  try {
    state.newAssets.watchlist.status = LOADING_STATUS.LOADING;
    const token = state.authentication.authTokens.APP_TOKEN.value;
    const watchlist = await effects.newAssets.getAssetWatchlist(token);
    const watchlistFiltered = watchlist.filter(
      (asset: Partial<IAsset>) =>
        asset.symbol != COMMODITIES_LIST_KEYS.GOLD &&
        asset.id !== GoldIndexId &&
        !asset.is_right &&
        !asset.is_ipo
    );
    let assetsWatchlistFullData: { [asset_id: string]: IAsset } = {};
    let assetsWatchlist: Set<string> = new Set();
    watchlistFiltered?.map((asset: any) => {
      if (asset.id) {
        assetsWatchlistFullData[asset.id] = asset;
        assetsWatchlist.add(asset.id);
      }
    });
    state.newAssets.watchlist.data = Array.from(assetsWatchlist);
    actions.newAssets.updateAssetWatchlistFullData(assetsWatchlistFullData);
    state.newAssets.watchlist.status = LOADING_STATUS.LOADED;
  } catch (error) {
    effects.logger.recordError(error, { fn: 'getAssetWatchlist' });
    state.newAssets.watchlist.status = LOADING_STATUS.FAILURE;
  }
};

export const watchAsset: AsyncAction<{ asset_id: string }> = async (
  { state, effects, actions },
  { asset_id }
) => {
  const token = state.authentication.authTokens.APP_TOKEN.value;
  state.newAssets.watchlist.data = [
    ...state.newAssets.watchlist.data,
    asset_id,
  ];
  try {
    await effects.newAssets.watchAsset({ token, asset_id });
  } catch (error) {
    effects.logger.recordError(error, { fn: 'watchAsset' });
  }
};

export const unwatchAsset: AsyncAction<{ asset_id: string }> = async (
  { state, effects },
  { asset_id }
) => {
  const updatedWatchlist = state.newAssets.watchlist.data.filter(
    (watchedAsset) => watchedAsset !== asset_id
  );
  state.newAssets.watchlist.data = updatedWatchlist;
  try {
    const token = state.authentication.authTokens.APP_TOKEN.value;
    await effects.newAssets.unwatchAsset({ token, asset_id });
  } catch (error) {
    effects.logger.recordError(error, { fn: 'unwatchAsset' });
  }
};

export const getMarketDepth: AsyncAction<{
  asset_id: string;
}> = async ({ state, actions, effects }, { asset_id }) => {
  state.newAssets.marketDepth.status = LOADING_STATUS.LOADING;
  const token = state.authentication.authTokens.APP_TOKEN.value;

  const marketDepthData = await effects.newAssets.getMarketDepth(token, {
    asset_id,
  });
  state.newAssets.marketDepth.data[asset_id] = marketDepthData;
  state.newAssets.marketDepth.status = LOADING_STATUS.LOADED;
};

export const getChart: AsyncAction<{
  asset_id: string;
  interval: CHART_INTERVALS;
  market: MarketName;
}> = async ({ state, actions, effects }, { asset_id, interval, market }) => {
  try {
    const token = state.authentication.authTokens.APP_TOKEN.value;

    const chart = await effects.newAssets.getChart(token, {
      market,
      asset_ids: [asset_id],
      interval,
    });
    if (state.newAssets.charts[asset_id]) {
      state.newAssets.charts[asset_id][interval] = chart[asset_id];
    } else {
      state.newAssets.charts[asset_id] = {
        [interval]: chart[asset_id],
      };
    }
  } catch (error) {
    effects.logger.recordError(error, { fn: 'getChart' });
  }
};
// @ts-ignore
export const getAdvancedTVChart: AsyncAction<
  {
    asset_id: string;
    resolution: string;
    from_timestamp: number;
    to_timestamp: number;
  },
  {
    points: IAdvancedChart[];
    next_date?: number;
  }
> = async (
  { state, actions, effects },
  { asset_id, resolution, from_timestamp, to_timestamp }
) => {
  const token = state.authentication.authTokens.APP_TOKEN.value;
  const resolutionBackend =
    resolution === '1D'
      ? 'day'
      : resolution === '1W' || resolution === '1M'
      ? 'week'
      : Number(resolution) >= 60
      ? 'hour'
      : 'minute';
  try {
    const chart = await effects.newAssets.getAdvancedChart(token, {
      asset_id,
      resolution: resolutionBackend,
      from_timestamp,
      to_timestamp,
    });
    return chart;
  } catch (error) {
    effects.logger.recordError(error, { fn: 'getChart' });
  }
};

export const getConsensus: AsyncAction<{ asset_id: string }> = async (
  { state, effects, actions },
  { asset_id }
) => {
  try {
    state.newAssets.consensus.status = LOADING_STATUS.LOADING;
    const token = state.authentication.authTokens.APP_TOKEN.value;
    const consensus = await effects.newAssets.getConsensus(token, {
      asset_id,
    });
    state.newAssets.consensus.data[asset_id] = consensus.consensus_data;
    state.newAssets.consensus.status = LOADING_STATUS.LOADED;
  } catch (error) {
    effects.logger.recordError(error, { fn: 'getConsensus' });
    state.newAssets.consensus.status = LOADING_STATUS.FAILURE;
  }
};

export const getFinancials: AsyncAction<{ asset_id: string }> = async (
  { state, effects, actions },
  { asset_id }
) => {
  try {
    state.newAssets.financials.status = LOADING_STATUS.LOADING;
    const token = state.authentication.authTokens.APP_TOKEN.value;
    const financials = await effects.newAssets.getFinancials(token, {
      asset_id,
    });
    state.newAssets.financials.data[asset_id] = financials;
    state.newAssets.financials.status = LOADING_STATUS.LOADED;
  } catch (err) {
    state.newAssets.financials.status = LOADING_STATUS.FAILURE;
  }
};

export const getSearch: AsyncAction<string, void> = async (
  { state, actions, effects },
  query
) => {
  try {
    state.newAssets.search.status = LOADING_STATUS.LOADING;
    state.newAssets.search.query = query;
    if (!query.length) {
      state.newAssets.search.assets = [];
    } else {
      const token = state.authentication.authTokens.APP_TOKEN.value;
      const response = await effects.newAssets.getSearch(token, {
        query,
        market: ['egypt', 'us'],
      });
      const searchFiltered = response.assets.filter(
        (asset: Partial<IAsset>) =>
          asset.symbol != COMMODITIES_LIST_KEYS.GOLD &&
          asset.id !== GoldIndexId &&
          !asset.is_right &&
          !asset.is_ipo
      );
      const assetsUpdate = arrayToDictionary(searchFiltered, 'id');
      //FIXME: set assets to state
      // actions.assets.updateAssetsState(assetsUpdate);
      state.newAssets.search.assets = Object.keys(assetsUpdate);
      state.newAssets.search.count = response.count;
    }
    state.newAssets.search.status = LOADING_STATUS.LOADED;
  } catch (error) {
    state.newAssets.search.status = LOADING_STATUS.FAILURE;
  }
};
export const updateisVisible: Action<boolean> = (
  { state },
  isVisible: boolean
) => {
  state.newAssets.search.isVisible = isVisible;
};

export const getNews: AsyncAction = async ({ state, effects, actions }) => {
  state.newAssets.news.status = LOADING_STATUS.LOADING;
  try {
    const token = state.authentication.authTokens.APP_TOKEN.value;
    const news = await effects.newAssets.getNews(token);
    state.newAssets.news.data = news;
    state.newAssets.news.status = LOADING_STATUS.LOADED;
  } catch (error) {
    state.newAssets.news.status = LOADING_STATUS.FAILURE;
  }
};

export const getStockNews: AsyncAction<{
  symbol: string;
  page?: number;
  locale?: string;
}> = async ({ state, effects }, { symbol, page = 1, locale }) => {
  state.newAssets.news.status = LOADING_STATUS.LOADING;
  try {
    // only continue to request the endpoint if you dont have the data, and if you have the data just fill the currentPageNews state with the data
    if (state.newAssets.news.stockNews[symbol]?.result?.[page]) {
      state.newAssets.news.stockNews[symbol] = {
        ...state.newAssets.news.stockNews[symbol],
        currentPageNews: state.newAssets.news.stockNews[symbol]?.result?.[page],
      };
      return;
    }
    const token = state.authentication.authTokens.APP_TOKEN.value;
    const { results, count } = await effects.newAssets.getStockNews(token, {
      symbol,
      page,
      locale,
    });
    state.newAssets.news.stockNews[symbol] = {
      ...state.newAssets.news.stockNews[symbol],
      count,
      result: {
        ...state.newAssets.news.stockNews[symbol]?.result,
        [page]: [...results],
      },
    };

    state.newAssets.news.stockNews[symbol] = {
      ...state.newAssets.news.stockNews[symbol],
      currentPageNews: state.newAssets.news.stockNews[symbol]?.result?.[page],
    };

    state.newAssets.news.status = LOADING_STATUS.LOADED;
  } catch (error) {
    console.log(error);
    state.newAssets.news.status = LOADING_STATUS.FAILURE;
  }
};

export const resetCurrentPageNews: Action<{ symbol: string }> = (
  { state },
  { symbol }
) => {
  state.newAssets.news.stockNews[symbol] = {
    ...state.newAssets.news.stockNews[symbol],
    currentPageNews: state.newAssets.news.stockNews[symbol]?.result?.[1],
  };
};
