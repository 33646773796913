export const SAFETY_MARGIN = 0.03;
export const GOV_MARGIN = 0.001;
export const CUSTODY_MARGIN = 0.0005;

export enum MARKETS {
  EGYPT_SIMULATOR = 'simulator',
  EGYPT = 'egypt',
  US = 'us',
}

export enum CHART_INTERVALS {
  '1D' = '1d',
  '1W' = '1w',
  '1M' = '1M',
  '6M' = '6M',
  '1Y' = '1y',
  'all' = 'all',
}

export const SECURITIES_TYPES = {
  INDEX: 'INDX',
  STOCK: 'NOPL',
  STOCK2: 'SME',
};
