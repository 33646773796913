import { AsyncAction, Action, json } from '@/store';
import { i18n } from '@/store/effects';
import { MARKETS } from '@/constants/market';
export const saveSettings: AsyncAction = async ({ state, effects }) => {
  const settings = json(state.settings);
  delete settings.systemFlagValues;
  delete settings.flagValues;
  delete settings.isLoaded;
  await effects.storage.setItem('settings', settings);
};

export const getAppAssetMarket: Action<string, MARKETS> = (
  { state },
  assetId,
) => {
  return MARKETS.EGYPT;
  //to be filled accordingly when we transition the settings namespace to a submodule
};

export const loadSettings: AsyncAction = async ({ state, effects }) => {
  const settings = await effects.storage.getItem('settings');
  Object.assign(state.settings, settings);
  state.settings.isLoaded = true;
};

export const enableSimulator: Action = ({ state }) => {
  state.newMarket.simulatorEnabled = true;
};

export const disableSimulator: Action = ({ state }) => {
  state.newMarket.simulatorEnabled = false;
};

export const setSimulatorEnabled: Action<boolean> = (
  { state, actions, effects },
  isEnabled,
) => {
  state.newMarket.simulatorEnabled = isEnabled;
  //FIXME: get data when switching markets
  // if (isEnabled) {
  //   actions.investor.getInvestorData();
  // } else {
  //   if (!actions.auth.privilegedTokenNeedsRenew()) {
  //     actions.investor.getInvestorData();
  //   }
  // }
};

export const themeToggle: Action<string> = (
  { state },
  theme: 'light' | 'dark',
) => {
  state.settings.activeTheme = theme;
};

export const setUserFlagValue: Action<{ key: string; value: any }> = (
  { state },
  { key, value },
) => {
  state.settings.userFlagValues[key] = value;
};

export const resetUserFlagValue: Action<string> = ({ state }, key) => {
  delete state.settings.userFlagValues[key];
};

export const resetAllUserFlagValues: Action<string> = ({ state }) => {
  state.settings.userFlagValues = {};
};

export const onInitializeOvermind: Action = async (
  { actions },
  { reaction }: any,
) => {
  actions.settings.loadSettings();
  reaction(
    (state) => {
      return [
        state.newMarket.simulatorEnabled,
        state.settings.passphraseEnabled,
        state.settings.userFlagValues,
      ];
    },
    () => {
      actions.settings.saveSettings();
    },
  );
};
