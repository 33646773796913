import { AsyncAction, Action, Context } from '../../../../src/store/index';
import { IEligibilityModalProps } from '@/components/common/EligibilityModal/EligibalityModal';
import { EligibilityLoadingStatus, ComplianceFormLoadingStatus } from './types';
import { getComplianceForm, getUserEligibility } from './effects';

export const getUserEligibilityProducts: AsyncAction<{
  ignoreCache?: boolean;
}> = async ({ state, actions, effects }: Context, { ignoreCache } = {}) => {
  const status = state.eligibility.eligibilityLoadingStatus;
  if (
    (!ignoreCache && status === EligibilityLoadingStatus.LOADED) ||
    status === EligibilityLoadingStatus.LOADING
  ) {
    //TODO: add time based way of breaking cache
    return;
  }
  state.eligibility.eligibilityLoadingStatus = EligibilityLoadingStatus.LOADING;
  try {
    const token = await actions.auth.getAuthToken('application-read-token');
    const { eligibilities } = await getUserEligibility(token);
    if (eligibilities) {
      state.eligibility.eligibilities = eligibilities;
      state.eligibility.eligibilityLoadingStatus =
        EligibilityLoadingStatus.LOADED;
    }
  } catch {
    state.eligibility.eligibilityLoadingStatus = EligibilityLoadingStatus.ERROR;
  }
};

export const getAgreementFormStatus: AsyncAction<{
  ignoreCache?: boolean;
}> = async ({ state, actions }, { ignoreCache } = {}) => {
  const status = state.eligibility.agreementFormLoadingStatus;
  if (
    (!ignoreCache && status === ComplianceFormLoadingStatus.LOADED) ||
    status === ComplianceFormLoadingStatus.LOADING
  ) {
    //TODO: add time based way of breaking cache
    return;
  }
  state.eligibility.agreementFormLoadingStatus =
    ComplianceFormLoadingStatus.LOADING;
  try {
    const token = await actions.auth.getAuthToken('application-read-token');
    const complianceForm = await getComplianceForm(token);
    if (complianceForm?.status) {
      state.eligibility.agreementFormStatus = complianceForm?.status;
      state.eligibility.hasBookedAppointment =
        complianceForm?.has_booked_appointment ?? false;
    }
    state.eligibility.agreementFormLoadingStatus =
      ComplianceFormLoadingStatus.LOADED;
  } catch {
    // TODO: handle form failure
    state.eligibility.agreementFormLoadingStatus =
      ComplianceFormLoadingStatus.ERROR;
  }
};

export const closeEligibilityModal: Action = ({ state }) => {
  state.eligibility.eligibilityModalData = null;
};

export const openEligibilityModal: Action<IEligibilityModalProps> = (
  { state },
  data,
) => {
  state.eligibility.eligibilityModalData = data;
};
