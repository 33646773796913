import { ProductName } from '@/store/namespaces/eligibility/types';
import { MarketName } from '@thndr/namespace/market';

export enum ASSET_CLASSES {
  STOCK = 'STOCK',
  ETF = 'ETF',
  INDEX = 'INDEX',
  MANAGED_FUND = 'FUND',
}

export interface IAssetClass {
  id: string;
  asset_class: string | ASSET_CLASSES;
  market: MarketName;
  product: ProductName;
  name: string;
  about: string;
  logo: string;
  is_visible: boolean;
  tags: string[];
  created_at: string;
  symbol: string;
  has_financials: boolean;
  has_analysis: boolean;
  has_consensus: boolean;
  is_ipo: boolean;
  ipo_share_price_multiplier: number;
  ipo_max_shares: number;
  ipo_min_shares: number;
  ipo_price: number;
  info_link: string;
  is_tradable: boolean;
  is_3dp: boolean;
}

export interface IETF extends IAssetClass {
  isin: string;
  manager: string;
  currency_id: number;
  currency: string;
  min_value: number;
  max_value: number;
  is_tradable: boolean;
  feed: null;
}

export interface IIndex extends IAssetClass {
  symbol: string;
  constituents: IAssetClass[];
  feed: IFeed;
}

export interface IStock extends IAssetClass {
  isin: string;
  symbol: string;
  industry: string;
  country_id: number;
  country: string;
  currency_id: number;
  currency: string;
  is_tradable: boolean;
  feed: IFeed;
}

export interface IManagedFund extends IAssetClass {
  manager: string;
  currency_id: number;
  currency: string;
  min_value: number;
  max_value: number;
  is_tradable: boolean;
  feed: null;
}

export type IFeed = Partial<{
  symbol_code: string;
  last_trade_price: number;
  last_trade_volume: number;
  total_trades: number;
  total_volume: number;
  bid_volume: number;
  ask_volume: number;
  total_bids: number;
  total_asks: number;
  last_change: number;
  last_change_prc: number;
  avg_change: number;
  avg_change_prc: number;
  last_trade_date: string;
  previous_close: number;
  ref_price: number;
  high_price_limit: number;
  low_price_limit: number;
  total_buy: number;
  total_sell: number;
  avg_5day: number;
  avg_30day: number;
  avg_90day: number;
  listed_shares: number;
  currency: string;
  eps: number;
  symbol: string;
  market_cap: number;
  type: string;
  open: number;
  close: number;
  high: number;
  low: number;
  high_52w: number;
  low_52w: number;
  pe: number;
  div_yield_prc: number;
  value: number;
  highest_bid: number;
  lowest_ask: number;
  full_name: string;
  category: string;
  lowest_bid: number;
  highest_ask: number;
  beta: number;
  avg_value: number;
  last_unit_price: number;
  annualized_return: number;
  manager?: string;
  min_value?: number;
  max_value?: number;
  name?: string;
  price?: number;
  day_trade_limit?: number;
}>;

export type IAsset = IStock | IIndex | IETF | IManagedFund;
