import { i18n } from '@/store/effects';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { notification } from 'antd';

export const notice = {
  alert(title: string, message: string = '') {
    alert(i18n?.t(message));
  },
  tAlert(title: string, message: string = '') {
    notice.alert(i18n?.t(title), i18n?.t(message));
  },
  prompt(title: string, message: string = '') {
    prompt(i18n?.t(title), i18n?.t(message));
  },
  async binaryConfirm({
    title,
    message,
    okText =  i18n.t( 'messages.confirm.proceed',{ns:'accountAccess'}) as string,
    noText = i18n.t( 'messages.confirm.cancel',{ns:'accountAccess'}) as string,
    payload = true,
  }) {
    return new Promise((res) =>
      confirmAlert({
        title: i18n?.t(title),
        message: i18n?.t(message),
        buttons: [
          {
            label: i18n?.t(okText) ,
            onClick: () => res(payload),
          },
          {
            label: i18n?.t(noText),
            onClick: () => res(null),
          },
        ],
      }),
    );
  },

  showErrorMessage: ({
    title,
    description,
  }: {
    title: string;
    description?: string;
  }) => {
    notification['error']({
      message: title,
      description,
    });
  },

  showInfoMessage: ({
    title,
    description,
  }: {
    title: string;
    description?: string;
  }) => {
    notification['info']({
      message: title,
      description,
    });
  },

  showSuccessMessage: ({
    title,
    description,
  }: {
    title: string;
    description?: string;
  }) => {
    notification['success']({
      message: title,
      description,
    });
  },
};
