interface BugsJoyProps {
  event: any;
  url: string;
  key: string;
}
const globalForLogger = global as typeof globalThis & {
  sessionID?: number;
};
export const sendDataToBugsjoy = ({ event, url, key }: BugsJoyProps) => {
  const data = JSON.stringify({
    user_id: event?.user?.id || '',
    session_id: globalForLogger.sessionID || '',
    session_tags: {
      deviceDetails: event.request?.headers['User-Agent'],
      release: event.release,
      ...event.tags,
    },
    is_web: true,
    content: {
      breadcrumbs: [
        ...event.breadcrumbs,
        {
          timestamp: event.timestamp,
          category:
            event.exception?.values?.[0]?.value === 'INTERNAL TRIGGER'
              ? 'Trigger'
              : 'Expections',
          level:
            event.exception?.values?.[0]?.value === 'INTERNAL TRIGGER'
              ? 'Info'
              : 'error',
          message: event.exception?.values?.[0]?.value,
        },
      ],
    },
  });
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      joykey: key,
    },
    body: data,
  }).catch((err) => {
    console.log(err);
  });
};
