import { thndrApi } from '@thndr/services/api';
import { useQueryClient } from 'react-query';
import { SUBS_QUERY_KEYS } from '../../query-keys';

export const useReactivateubscription = () => {
  const query = useQueryClient();
  const reactivateubscription = async (subscriptionId: string) => {
    try {
      await thndrApi.post(
        `payment-service/v2/subscriptions/${subscriptionId}/reactivate`
      );
      query.invalidateQueries(SUBS_QUERY_KEYS.subscriptions);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    reactivateubscription,
  };
};
