import { getTimeTillNextUpdate } from '::root/libs/namespace/assets/src';
import { useMarket } from '::root/libs/namespace/market/src';
import { thndrApi } from '::root/libs/services/api/src';
import { useQuery, useQueryClient } from 'react-query';

const WatchlistKey = 'WATCHLIST';

const getWatchlist = async ({ market }: { market: string }) => {
  const params = { market };
  const { data } = await thndrApi.get(`/assets-service/watchlist`, {
    params,
  });
  return data;
};

export const patchWatchlist = async (asset_id: string) => {
  const { data } = await thndrApi.patch(`/assets-service/watchlist`, {
    asset_id,
  });
  return data;
};
export const deleteWatchlist = async (asset_id: string) => {
  const { data } = await thndrApi.delete(`/assets-service/watchlist`, {
    data: { asset_id },
  });
  return data;
};

export const useWatchlist = (enabled = true) => {
  const { market } = useMarket();

  const queryClient = useQueryClient();
  const lastUpdatedAt = queryClient.getQueryState([
    WatchlistKey,
    market,
  ])?.dataUpdatedAt;

  const timeTillNextUpdate = lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 15)
    : 15 * 60 * 1000;

  const {
    data,
    isLoading,
    isError,
    error,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery({
    queryKey: [WatchlistKey, market],
    queryFn: () => getWatchlist({ market }),
    staleTime: timeTillNextUpdate,
    cacheTime: timeTillNextUpdate,
    enabled,
  });

  const watchAsset = async (asset_id: string) => {
    await patchWatchlist(asset_id);
    queryClient.invalidateQueries([WatchlistKey, market]);
  };

  const unwatchAsset = async (asset_id: string) => {
    await deleteWatchlist(asset_id);
    queryClient.invalidateQueries([WatchlistKey, market]);
  };

  return {
    data,
    isLoading,
    isError,
    error,
    isFetching,
    isPreviousData,
    watchAsset,
    unwatchAsset,
    refetch,
  };
};
