export const AUTH_TOKENS = 'auth-tokens';
export const FIREBASE_TOKEN: 'firebase-token' = 'firebase-token';
export const APPLICATION_READ_TOKEN = 'application-read-token';

// ONLY ALLOW READ TOKEN TO PREVENT CSRF
export const CAN_SAVE_IN_COOKIES = [FIREBASE_TOKEN, APPLICATION_READ_TOKEN];

export const TOKEN_REQUEST_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
};

export enum Scope {
  user_read = 'user:read',
  user_write = 'user:write',
  subscription_read = 'subscription:read',
  subscription_write = 'subscription:write',
  feed_read = 'feed:read',
  notifications_read = 'notifications:read',
  notifications_write = 'notifications:write',
  post_read = 'post:read',
  post_write = 'post:write',
  investor_read = 'investor:read',
  investor_write = 'investor:write',
  order_read = 'order:read',
  order_write = 'order:write',
  market_us_read = 'market_us:read',
  market_egypt_read = 'market_egypt:read',
  market_egypt_write = 'market_egypt:write',
  market_simulator_read = 'market_simulator:read',
  market_simulator_write = 'market_simulator:write',
  kyc_challenge_read = 'kyc_challenge:read',
  kyc_challenge_write = 'kyc_challenge:write',
  files_write = 'files:write',
  document_write = 'document:write',
  funding_write = 'funding:write',
  funding_read = 'funding:read',
  assets_read = 'assets:read',
  assets_write = 'assets:write',
  analysis_read = 'analysis:read',
  charts_read = 'charts:read',
  watchlist_read = 'watchlist:read',
  watchlist_write = 'watchlist:write',
  market_depth_read = 'market_depth:read',
}

export type UserAuthTrust = 'unverified' | 'verified' | 'trusted' | 'recovered';
export enum UserAuthTrustLevels {
  unverified = 'unverified',
  verified = 'verified',
  trusted = 'trusted',
  recovered = 'recovered',
}

export const InitialAuthTokens = {
  [FIREBASE_TOKEN]: null,
  [APPLICATION_READ_TOKEN]: null,
  'application-write-token': null,
  'subs-service-token': null,
  'notifications-service-token': null,
  'simulator-read-token': null,
  'simulator-write-token': null,
  'privileged-token': null,
  APP_TOKEN: null,
};

export type AuthTokenKey = keyof typeof InitialAuthTokens;
export type AuthTokens = Record<AuthTokenKey, string | null>;

export const AuthTokenSettings = {
  [FIREBASE_TOKEN]: {
    name: 'Credential Token',
    scopes: [],
    description: 'token required to make any request to auth service',
    headerPrefix: '',
    requiresTrust: false,
  },
  [APPLICATION_READ_TOKEN]: {
    name: 'Basic Read',
    scopes: [
      Scope.user_read,
      Scope.feed_read,
      Scope.post_read,
      Scope.post_write,
      Scope.kyc_challenge_read,
      Scope.subscription_read,
    ],
    description: 'basic user read scopes',
    headerPrefix: 'Auth',
    requiresTrust: false,
  },
  'application-write-token': {
    name: 'Basic Write',
    scopes: [
      Scope.user_write,
      Scope.post_write,
      Scope.files_write,
      Scope.document_write,
      Scope.kyc_challenge_write,
    ],
    description: 'basic user read scopes',
    headerPrefix: 'Auth',
    requiresTrust: false,
  },
  'notifications-service-token': {
    name: 'Notification Service',
    scopes: [
      Scope.notifications_read,
      Scope.notifications_write,
      Scope.assets_write,
      Scope.assets_read,
      Scope.analysis_read,
      Scope.charts_read,
      Scope.watchlist_read,
      Scope.watchlist_write,
      Scope.market_depth_read,
    ],
    description: 'user notifications token',
    headerPrefix: 'Bearer',
    requiresTrust: false,
  },
  'subs-service-token': {
    name: 'Subscription',
    scopes: [Scope.subscription_read, Scope.subscription_write],
    headerPrefix: 'Bearer',
    description: 'token for read subscription status',
    requiresTrust: false,
  },
  'simulator-read-token': {
    name: 'Simulator Read',
    scopes: [Scope.market_simulator_read],
    headerPrefix: 'Bearer',
    description: 'token for reading simulator portfolio and market data',
    requiresTrust: false,
  },
  'simulator-write-token': {
    name: 'Simulator Write',
    scopes: [Scope.market_simulator_write],
    headerPrefix: 'Bearer',
    description: 'token for submitting order on the simulator',
    requiresTrust: false,
  },
  'privileged-token': {
    name: 'Investor Read',
    scopes: [
      Scope.notifications_read,
      Scope.notifications_write,
      Scope.assets_write,
      Scope.assets_read,
      Scope.analysis_read,
      Scope.charts_read,
      Scope.watchlist_read,
      Scope.watchlist_write,
      Scope.market_depth_read,
      Scope.user_read,
      Scope.feed_read,
      Scope.post_read,
      Scope.post_write,
      Scope.kyc_challenge_read,
      Scope.user_write,
      Scope.files_write,
      Scope.document_write,
      Scope.kyc_challenge_write,
      Scope.subscription_read,
      Scope.subscription_write,
      Scope.market_simulator_read,
      Scope.market_simulator_write,
      Scope.order_read,
      Scope.order_write,
      Scope.investor_read,
      Scope.investor_write,
      Scope.market_egypt_read,
      Scope.market_egypt_write,
      Scope.funding_read,
      Scope.funding_write,
    ],
    description: 'token for actions that require password',
    headerPrefix: 'Bearer',
    requiresTrust: true,
  },
  APP_TOKEN: {
    name: 'App Token',
    scopes: [
      Scope.notifications_read,
      Scope.notifications_write,
      Scope.assets_write,
      Scope.assets_read,
      Scope.analysis_read,
      Scope.charts_read,
      Scope.watchlist_read,
      Scope.watchlist_write,
      Scope.market_depth_read,
      Scope.user_read,
      Scope.feed_read,
      Scope.post_read,
      Scope.post_write,
      Scope.kyc_challenge_read,
      Scope.user_write,
      Scope.files_write,
      Scope.document_write,
      Scope.kyc_challenge_write,
      Scope.subscription_read,
      Scope.subscription_write,
      Scope.market_simulator_read,
      Scope.market_simulator_write,
    ],
    description: 'token for actions other than ones require password',
    headerPrefix: 'Bearer',
    requiresTrust: false,
  },
};

export type ThndrAPIToken = string;
