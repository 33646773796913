import { clearTimer, startTimer } from '@/util/timer';

export const startPolling = (action: Function, pollingInterval: number) => {
  return startTimer(action, pollingInterval);
};

export const stopPolling = (pollingId: string) => {
  return clearTimer(pollingId);
};

export const poll = (action: Function, pollingInterval: number) => {
  const pollingId = startPolling(action, pollingInterval);
  return () => stopPolling(pollingId);
};
