import { useActions, useStates } from '::store';
import { useRouter } from 'next/router';
import { LOADING_STATUS } from '../types';
export const useAssets = () => {
  const { newAssets } = useStates();
  const { newAssets: assetActions } = useActions();
  const { locale } = useRouter();

  //divide by functionality
  const getAssetInfo = async ({ asset_id }: { asset_id: string }) => {
    await assetActions.getAssetInfo({ asset_id });
  };
  const getConsensus = async ({ asset_id }: { asset_id: string }) => {
    await assetActions.getConsensus({ asset_id });
  };
  const getFinancials = async ({ asset_id }: { asset_id: string }) => {
    await assetActions.getFinancials({ asset_id });
  };

  const getRecommendations = async (
    asset_id: string,
    recommendations_number: number
  ) => {
    await assetActions.getRecommendations({
      asset_id,
      recommendations_number,
    });
  };

  const getAnalysis = async (asset_id: string) => {
    await assetActions.getAnalysis({ asset_id });
  };

  const getAnalysisReport = async (asset_id: string) => {
    await assetActions.getAnalysisReport({ asset_id });
  };

  const getMarketDepth = async (asset_id: string) => {
    await assetActions.getMarketDepth({ asset_id });
  };

  const setSelectedAsset = async (asset_id: string) => {
    await assetActions.setSelectedAsset(asset_id);
  };

  const getNews = async () => {
    await assetActions.getNews();
  };
  const getStockNews = async (symbol: string, page?: number) => {
    await assetActions.getStockNews({ symbol, page, locale });
  };
  const resetCurrentPageNews = (symbol: string) => {
    assetActions.resetCurrentPageNews({ symbol });
  };

  return {
    assets: newAssets.assetInfo.data,
    assetsLoading: newAssets.assetInfo.status === LOADING_STATUS.LOADING,
    selectedAsset: newAssets.selectedAssetID,
    marketDepth: newAssets.marketDepth.data,
    marketDepthLoading: newAssets.marketDepth.status === LOADING_STATUS.LOADING,
    consensus: newAssets.consensus.data,
    consensusLoading: newAssets.consensus.status === LOADING_STATUS.LOADING,
    financials: newAssets.financials.data,
    financialsLoading: newAssets.financials.status === LOADING_STATUS.LOADING,
    news: newAssets.news?.data,
    stockNews: newAssets.news?.stockNews,
    newsLoading: newAssets.news?.status === LOADING_STATUS.LOADING,
    assetIds: newAssets.recommendations?.data[newAssets.selectedAssetID],
    analysis: newAssets.anaylsis.data,
    getNews,
    getStockNews,
    resetCurrentPageNews,
    getAssetInfo,
    getAnalysis,
    getAnalysisReport,
    getRecommendations,
    getFinancials,
    getMarketDepth,
    getConsensus,
    setSelectedAsset,
  };
};
