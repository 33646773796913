import { AsyncAction } from '::store';

export const getNewSubscriptionSettings: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  try {
    const token = await actions.auth.getAuthToken('subs-service-token');
    const subscriptions = await effects.newSubscription.getSubscriptionSettings(
      token
    );
    state.newSubscription.subscriptions = [...subscriptions];
  } catch (e) {
    console.log(e, 'getting subscriptions');
  } finally {
  }
};

export const onInitializeOvermind = async ({ actions }, { reaction }) => {
  // reaction(
  //   (state) => [state.auth.hasLoadedFirebaseUser, state.user.id],
  //   ([hasLoadedFirebaseUser, id]) => {
  //     if (hasLoadedFirebaseUser && id) {
  //       actions.newSubscription.getNewSubscriptionSettings();
  //     }
  //   }
  // );
};
