import { thndrApi } from '@thndr/services/api';
import { MarketName } from '../../lib/types';
export interface ITheme {
  id: number;
  slug?: string;
  name?: string;
  about?: string;
  small_sq_pic?: string;
  small_rect_pic?: string;
  large_rect_pic?: string;
  created_at?: string;
  assets_count?: number;
  assets?: any[];
  background_color?: string;
  rank: number;
  hidden?: boolean;
  page?: number;
}
export interface IGetThemeThemesResponse {
  results: ITheme[];
  count: number;
}

export const getThndrThemes = async ({
  random,
  page_count,
  market,
}: {
  random?: boolean;
  page_count: number;
  market: MarketName[];
}) => {
  const { data } = await thndrApi.get<IGetThemeThemesResponse>(
    `/assets-service/tags?random=${random}&page_count=${page_count}&market=${market}`
  );
  return data.results;
};
