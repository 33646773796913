import dayjs from 'dayjs';
import { IMarketHoursState, MarketName } from './types';

export const getAssetMarket = (
  settingMarket: MarketName,
  assetMarket?: MarketName
) =>
  settingMarket === MarketName.SIMULATOR
    ? settingMarket
    : assetMarket ?? settingMarket;
export const isTomorrow = ({
  date,
  currentTime,
}: {
  date: string;
  currentTime: string;
}) => {
  const tomorrow = dayjs(currentTime).add(1, 'day');
  if (tomorrow.format('D') === dayjs(date).format('D')) {
    return true;
  }
  return false;
};
export const isToday = ({
  date,
  currentTime,
}: {
  date: string;
  currentTime: string;
}) => {
  const today = dayjs(currentTime);
  if (today.format('D') === dayjs(date).format('D')) {
    return true;
  }
  return false;
};
export const getMarketHoursState = ({
  currentTime,
  closingTime,
  openingTime,
  isSessionActive,
}: {
  currentTime: string;
  closingTime: string;
  openingTime: string;
  isSessionActive: boolean;
}): IMarketHoursState => {
  const almostClosingTime = dayjs(closingTime).subtract(30, 'minutes');
  if (isSessionActive) {
    if (dayjs(currentTime).isAfter(almostClosingTime)) return 'CLOSES_AT';
    return 'NOW_OPEN';
  }
  if (
    isTomorrow({
      date: openingTime,
      currentTime: currentTime,
    })
  )
    return 'OPENS_TOMORROW';
  if (
    !isToday({
      date: openingTime,
      currentTime: currentTime,
    })
  )
    return 'OPENS_ON_DAY';
  return 'OPENS_AT';
};
