import { ITokenRequestPayload } from '../effects/tokens/requests';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
  updatePhoneNumber,
} from 'firebase/auth';

import { AuthTokenSettings, TOKEN_REQUEST_STATUS } from '@/constants/auth';
import { AsyncAction, Action } from '@/store';

export const createTokenRequest: AsyncAction<void> = async ({
  state,
  effects,
  actions,
}) => {
  state.auth.tokenRequest.isRequesting = true;
  const firebaseToken = state.authentication.authTokens['firebase-token'].value;
  const scopes = AuthTokenSettings['privileged-token'].scopes;
  const isCheckingStatus = state.auth.tokenRequest.isCheckingStatus;
  const payload: ITokenRequestPayload = {
    data: { scopes },
    credentials: { firebase_token: firebaseToken },
  };
  try {
    const tokenRequest = await effects.auth.requestToken(payload);
    if (tokenRequest) {
      state.auth.tokenRequest.data = tokenRequest;
      if (!isCheckingStatus) {
        actions.auth.checkTokenRequestStatus();
      }
    }
  } catch (err) {
    effects.notice.showErrorMessage({
      title: 'Error',
      description: String(err),
    });
  } finally {
    state.auth.tokenRequest.isRequesting = false;
  }
};

export const requestAuthWithOTP: Action = ({ state }) => {
  state.auth.requestSMS = true;
};

export const cancelRequestAuthWithOTP: Action = ({ state }) => {
  state.auth.requestSMS = false;
};

export const showSMSButton: Action = ({ state }) => {
  state.auth.showSMSButton = true;
};

export const getRecapatcha: AsyncAction<void> = async ({}) => {
  const element = document.getElementById('verify-phone-number');
  if (element) {
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      'verify-phone-number',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      auth,
    );
  }
};

export const verifyPhoneNumber: AsyncAction<void> = async ({
  state,
  actions,
  effects,
}) => {
  state.auth.requestingOTP = true;
  try {
    const phoneNumber = state.user.phone_number;
    const appVerifier = window.recaptchaVerifier;
    const auth = getAuth();
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier,
    );
    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    window.confirmationResult = confirmationResult;
    state.auth.requestingOTP = false;
    actions.auth.requestAuthWithOTP();
  } catch (err) {
    state.auth.requestingOTP = false;
    if (err.code === 'auth/too-many-requests') {
      effects.notice.showErrorMessage({
        title: 'Too many requests',
        description: 'You requested too many OTPs, please try again later.',
      });
    } else {
      effects.notice.showErrorMessage({
        title: 'Something went wrong',
        description: 'Please try again later.',
      });
    }

    // Error; SMS not sent
    // window.recaptchaVerifier.render().then(function (widgetId) {
    //   // @ts-ignore
    //   grecaptcha.reset(widgetId);
    // });
  }
};

export const verifyAppPinAndGetToken: AsyncAction<{ pin: string }> = async (
  { state, effects, actions },
  { pin },
) => {
  state.auth.isVerifyingPinCode = true;
  const firebase_token =
    state.authentication.authTokens['firebase-token'].value;
  const { auth_token } = await effects.auth.verifyAppPinAndGetToken({
    firebase_token,
    password: '',
    pin,
  });
  await actions.auth.setAuthToken({
    authKey: 'APP_TOKEN',
    token: auth_token,
  });
  state.auth.privilegedTokenNeedsRenew = false;
  actions.auth.clearTokenRequest();
  actions.auth.cancelRequestAuthWithOTP();
  state.auth.otpVerificationSuccess = false;
  state.auth.isVerifyingPinCode = false;
  state.auth.showSMSButton = false;
};

export const sendCode: AsyncAction<string> = async (
  { state, actions, effects },
  code: string,
) => {
  try {
    state.auth.isSendingCode = true;
    const auth = getAuth();
    var credential = PhoneAuthProvider.credential(
      // @ts-ignore
      confirmationResult.verificationId,
      code,
    );
    await updatePhoneNumber(auth.currentUser, credential);
    // await signInWithCredential(auth, credential);
    state.auth.otpVerificationSuccess = true;
    state.auth.isSendingCode = false;
  } catch (err) {
    state.auth.isSendingCode = false;
    effects.notice.showErrorMessage({
      title: 'OTP is wrong',
      description: 'The OTP you entered is wrong. Please try again',
    });
  }
};

export const clearTokenRequest: Action = ({ state }) => {
  state.auth.tokenRequest.data = null;
  state.auth.tokenRequest.isFetchingToken = false;
  state.auth.tokenRequest.isRequesting = false;
};

export const clearTokenRequestAndReauthenticate: AsyncAction = async ({
  state,
  actions,
}) => {
  state.auth.privilegedTokenNeedsRenew = true;
  await actions.auth.setAuthToken({
    authKey: 'APP_TOKEN',
    token: null,
  });
};

export const tokenAutoCancellation: Action = ({ state, effects, actions }) => {
  actions.auth.clearTokenRequest();
  effects.notice.showInfoMessage({
    title: 'Token request canceled',
    description:
      'Token request took too long and was canceled for security reasons. Please try again.',
  });
  state.auth.tokenRequest.isCheckingStatus = false;
  state.auth.tokenRequest.autoCancelCount = 0;
};

export const checkTokenRequestStatus: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  const request_secret = state.auth.tokenRequest.data?.request_secret;
  const request_id = state.auth.tokenRequest.data?.id;
  const firebase_token =
    state.authentication.authTokens['firebase-token'].value;
  state.auth.tokenRequest.autoCancelCount++;
  state.auth.tokenRequest.isCheckingStatus = true;
  try {
    if (state.auth.tokenRequest.autoCancelCount === 25) {
      actions.auth.tokenAutoCancellation();
    } else if (request_secret && request_id) {
      const { status } = await effects.auth.getTokenRequestStatus({
        firebase_token,
        request_secret,
        request_id,
      });
      if (status !== TOKEN_REQUEST_STATUS.APPROVED) {
        setTimeout(() => {
          actions.auth.checkTokenRequestStatus();
        }, 4000);
      } else {
        actions.auth.fetchRequestedToken();
      }
    }
  } catch (err) {
    effects.notice.showErrorMessage({
      title: 'Error',
      description: String(err),
    });
  } finally {
    state.auth.tokenRequest.isCheckingStatus = false;
  }
};

export const fetchRequestedToken: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  state.auth.tokenRequest.isFetchingToken = true;
  const request_secret = state.auth.tokenRequest.data?.request_secret;
  const request_id = state.auth.tokenRequest.data?.id;
  const firebase_token =
    state.authentication.authTokens['firebase-token'].value;

  try {
    const { auth_token } = await effects.auth.getRequestedToken({
      request_id,
      request_secret,
      firebase_token,
    });
    await actions.auth.setAuthToken({
      authKey: 'APP_TOKEN',
      token: auth_token,
    });
    state.auth.privilegedTokenNeedsRenew = false;
    actions.auth.clearTokenRequest();
  } catch (err) {
    effects.notice.showErrorMessage({
      title: 'Error',
      description: String(err),
    });
  }
};
