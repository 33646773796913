export enum AuthErrorTypes {
  UNAUTHORIZED_USER = 'UNAUTHORIZED_USER',
  UNEXPECTED_USER_TYPE = 'UNEXPECTED_USER_TYPE',
  UNVERIFIED_PHONE_NUMBER = 'UNVERIFIED_PHONE_NUMBER',
  INVALID_FIREBASE_TOKEN = 'INVALID_FIREBASE_TOKEN',
  INVALID_PUBLIC_KEY = 'INVALID_PUBLIC_KEY',
  INVALID_CHALLENGE_TOKEN = 'INVALID_CHALLENGE_TOKEN',
  INVALID_RESPONSE_TOKEN = 'INVALID_RESPONSE_TOKEN',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  EXCEEDED_USER_RATE = 'EXCEEDED_USER_RATE',
  INVALID_USER_ID = 'INVALID_USER_ID',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}

export const AuthErrorMap = {
  [AuthErrorTypes.UNAUTHORIZED_USER]: 'errors.auth.somethingWentWrong',
  [AuthErrorTypes.UNEXPECTED_USER_TYPE]: 'errors.auth.somethingWentWrong',
  [AuthErrorTypes.UNVERIFIED_PHONE_NUMBER]:
    'errors.auth.UNVERIFIED_PHONE_NUMBER',
  [AuthErrorTypes.INVALID_FIREBASE_TOKEN]: 'errors.auth.somethingWentWrong',
  [AuthErrorTypes.INVALID_PUBLIC_KEY]: 'errors.auth.somethingWentWrong',
  [AuthErrorTypes.INVALID_CHALLENGE_TOKEN]: 'errors.auth.somethingWentWrong',
  [AuthErrorTypes.INVALID_RESPONSE_TOKEN]: 'errors.auth.INVALID_RESPONSE_TOKEN',
  [AuthErrorTypes.INVALID_PASSWORD]: 'errors.auth.INVALID_PASSWORD',
  [AuthErrorTypes.EXCEEDED_USER_RATE]: 'errors.auth.somethingWentWrong',
  [AuthErrorTypes.INVALID_USER_ID]: 'errors.auth.somethingWentWrong',
  [AuthErrorTypes.INVALID_PHONE_NUMBER]: 'errors.auth.INVALID_PHONE_NUMBER',
};
