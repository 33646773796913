import Numeral from 'numeral';

export const arrayToDictionary = <
  T extends Record<string, any>,
  K extends keyof T,
>(
  array: T[],
  key: K,
): Partial<Record<T[K], T>> => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    if (!item[key]) {
      console.warn(
        `running arrayToDictionary: key ${String(
          key,
        )} does not exist on item ${JSON.stringify(item)}`,
      );
    }
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const formatPrice = (price: number) =>
  price <= 2 ? Numeral(price).format('0.000') : Numeral(price).format('0.00');
