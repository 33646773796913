import { AuthTokenKey, AuthTokenSettings } from '@/constants/auth';
import { Action, rehydrate, AsyncAction } from '@/store';
import { AsyncRequestWithToken } from '@/util/types';

export const resetGlobalError: Action = ({ state }) => {
  state.globalError = null;
};
export const setGlobalError: Action<string> = ({ state }, error) => {
  state.globalError = error;
};
export const rehydrateState: Action<any[]> = ({ state }, mutations) => {
  rehydrate(state, mutations);
};

export const callEffectWithToken: AsyncAction<
  {
    tokenType: AuthTokenKey;
    effect: AsyncRequestWithToken<any, any>;
    payload?: any;
  },
  any
> = async ({ actions }, { tokenType, effect, payload }) => {
  const authToken = await actions.auth.getAuthToken(tokenType);
  const headerPrefix = AuthTokenSettings[tokenType].headerPrefix;
  const token = `${headerPrefix} ${authToken}`;
  return effect(token, payload);
};

export const onInitializeOvermind = async ({ effects, actions }) => {
  await effects.firebase.initialize();
  effects.http.initialize();
};
