/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { AsyncAction, Action, Context } from '@/store';
import { AuthTokenKey, FIREBASE_TOKEN } from '@/constants/auth';
import { User } from 'firebase/auth';

export const loadAuthTokens: AsyncAction<void> = async ({
  state,
  effects,
  actions,
}) => {
  state.auth.isLoadingStoredTokens = true;

  try {
    const tokens = await effects.auth.loadAuthTokens();
    await Object.keys(tokens)
      .map<AuthTokenKey>((x) => x as AuthTokenKey)
      .map(async (authKey) => {
        const token = tokens[authKey];
        const tokenState = await effects.auth.deriveTokenState(token);
        if (!state.auth.authTokens[authKey]) {
          state.auth.authTokens[authKey] = {
            isLoading: false,
            value: null,
            waitingRequests: [],
          };
        }
        if (tokenState && state.authentication.authTokens[authKey]) {
          Object.assign(state.authentication.authTokens[authKey], tokenState);
        }
      });
    const privilegedTokenNeedsRenew =
      await actions.auth.privilegedTokenNeedsRenew();
    state.auth.privilegedTokenNeedsRenew = privilegedTokenNeedsRenew;
    state.auth.hasLoadedStoredTokens = true;
  } finally {
    state.auth.isLoadingStoredTokens = false;
  }
};

export const setHasFirebaseToken: Action<string> = ({ state }, token) => {
  state.auth.hasFirebaseToken = Boolean(token);
};

export const hasFirebaseToken: AsyncAction<void, boolean> = async ({
  state,
  effects,
  actions,
}) => {
  const token = await effects.auth.getFirebaseToken();
  const firebaseTokenState = state.authentication.authTokens[FIREBASE_TOKEN];
  if (token || firebaseTokenState.value) {
    if (token && (await actions.auth.authTokenNeedRenew(FIREBASE_TOKEN))) {
      // refresh token
      await effects.auth.setAuthToken(FIREBASE_TOKEN, token);
    }
    state.auth.hasFirebaseToken = true;
    return true;
  }
  return false;
};

export const tryLoadingFirebaseUser: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  if (state.authentication.authTokens[FIREBASE_TOKEN]) {
    state.auth.hasFirebaseToken = await actions.auth.authTokenNeedRenew(
      FIREBASE_TOKEN,
    );
    if (state.auth.hasFirebaseToken) {
      const user = await effects.auth.getFirebaseUser();
      if (user) {
        state.auth.hasLoadedFirebaseUser = true;
        return;
      }
    }
  }
  const token = await effects.auth.getFirebaseToken();
  if (token) {
    if (token && (await actions.auth.authTokenNeedRenew(FIREBASE_TOKEN))) {
      // refresh token
      await effects.auth.setAuthToken(FIREBASE_TOKEN, token);
    }
    state.auth.hasFirebaseToken = true;
    state.auth.hasLoadedFirebaseUser = Boolean(
      await effects.auth.getFirebaseUser(),
    );
    return;
  }
  // actions.accountAccess.getCredentialsFromUser();
};

export const refreshFirebaseToken: AsyncAction<User | null> = async (
  { state, effects, actions }: Context,
  user,
) => {
  const isWebView = state.auth.isWebView;
  if (user) {
    const token = await effects.auth.getFirebaseToken();
    if (token) {
      await actions.auth.setAuthToken({
        authKey: FIREBASE_TOKEN,
        token: token,
      });
      state.auth.hasFirebaseToken = true;
      state.auth.hasLoadedFirebaseUser = true;
    }
  } else if (isWebView) {
    const token = await window.localStorage.getItem('web-view-token');
    await actions.auth.setAuthToken({
      authKey: 'APP_TOKEN',
      token: token,
    });
    state.auth.hasFirebaseToken = true;
    state.auth.hasLoadedFirebaseUser = true;
  } else {
    state.auth.hasLoadedFirebaseUser = false;
    actions.auth.logout();
  }
};

export const onInitializeOvermind = async (
  { actions, effects, state }: Context,
  { reaction },
) => {
  const isWebView = window.localStorage.getItem('web-view-token');
  const shouldShowSubsWebview =
    window.localStorage.getItem('web-view-subs-flag');
  state.auth.isWebView = !!isWebView;
  state.auth.shouldShowSubsWebview = shouldShowSubsWebview === 'true';
  await effects.auth.initializeFirebase({ actions });
  await actions.auth.loadAuthTokens();
  reaction(
    (state) => [state.auth.hasLoadedFirebaseUser, state.user.id],
    ([hasLoadedFirebaseUser, id]) => {
      if (hasLoadedFirebaseUser && id) {
        actions.auth.getAuthToken('APP_TOKEN');
      }
    },
  );
};
