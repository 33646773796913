/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useQuery } from 'react-query';
import { ASSETS_QUERY_KEYS } from '../query-keys';
import { thndrApi } from '@thndr/services/api';
import { MOBILE_CHART_INTERVALS } from '../types';
import { MarketName } from '::root/libs/namespace/market/src';

interface IGetAdvancedChartPayload {
  asset_id: string;
  chart_option: MOBILE_CHART_INTERVALS;
  market?: MarketName;
}

export type IAdvancedChart = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume?: number;
};

interface IGetAdvancedChartResponse {
  points: IAdvancedChart[];
  next_date?: number;
}

export const getAdvancedChart = async ({
  asset_id,
  chart_option,
  market,
}: IGetAdvancedChartPayload) => {
  const params = {
    asset_id,
    chart_option,
    market,
  };

  const { data } = await thndrApi.get<IGetAdvancedChartResponse>(
    '/assets-service/charts/candlesticks',
    {
      params,
    }
  );
  return data;
};

export const useAdvancedCharts = ({
  enabled = true,
  asset_id,
  interval,
  market,
}: {
  enabled?: boolean;
  asset_id: string;
  interval: keyof typeof MOBILE_CHART_INTERVALS;
  market?: MarketName;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [ASSETS_QUERY_KEYS.advancedCharts, asset_id, interval],
    queryFn: () =>
      getAdvancedChart({
        asset_id,
        chart_option: MOBILE_CHART_INTERVALS[interval],
        market,
      }),
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    enabled: enabled,
  });

  return {
    data,
    isLoading,
  };
};
