import { useEffects } from '::store';
import { SUBS_COMBO_DISCOUNT } from '../../constants';
import { SubscriptionPlan } from '../../types';
import createContext from '../createContext';

enum CartActions {
  ADD_SUBSCRIPTIONS = 'ADD_SUBSCRIPTIONS',
  REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION',
  CALCULATE_TOTAL = 'CALCULATE_TOTAL',
  SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
  CLEAR_CART = 'CLEAR_CART',
}
interface IActionAdd {
  type: CartActions.ADD_SUBSCRIPTIONS;
  payload: SubscriptionPlan[];
}
interface IActionRemove {
  type: CartActions.REMOVE_SUBSCRIPTION;
  payload: string;
}
interface IActionCalculateTotal {
  type: CartActions.CALCULATE_TOTAL;
  payload: null;
}
interface IActionSetSubscriptions {
  type: CartActions.SET_SUBSCRIPTIONS;
  payload: SubscriptionPlan[];
}
interface IActionClearCart {
  type: CartActions.CLEAR_CART;
  payload: string;
}
export type Action =
  | IActionAdd
  | IActionRemove
  | IActionCalculateTotal
  | IActionSetSubscriptions
  | IActionClearCart
  | IActionSetSubscriptions;
export type CartState = {
  cart: SubscriptionPlan[];
  total: number;
  discount: number;
};
type Dispatch = (action: Action) => void;
const initialState: CartState = {
  cart:
    typeof window !== 'undefined'
      ? localStorage.getItem('userCart')
        ? JSON.parse(localStorage.getItem('userCart') || '{}')
        : []
      : [],
  total:
    typeof window !== 'undefined'
      ? localStorage.getItem('userCartTotal')
        ? Number(localStorage.getItem('userCartTotal'))
        : 0
      : 0,
  discount: 0,
};

const cartReducer = (state: CartState, action: Action) => {
  const { type, payload } = action;
  const { analytics } = useEffects();
  switch (type) {
    case CartActions.ADD_SUBSCRIPTIONS: {
      const newPayload = payload as SubscriptionPlan[];

      const newCart = newPayload.reduce(
        (acc: SubscriptionPlan[], plan: SubscriptionPlan) => {
          if (!state.cart.includes(plan)) {
            analytics.trackEvent('Subscriptions_CartItem_Added', {
              productName: plan?.product_name,
              frequency: plan?.frequency,
            });
            return [...acc, plan];
          }
          return acc;
        },
        []
      );
      return { cart: [...state.cart, ...newCart] };
    }
    case CartActions.REMOVE_SUBSCRIPTION: {
      const filteredCart = state.cart.filter(
        (plan: SubscriptionPlan) => plan.id !== payload
      );
      if (typeof window !== 'undefined') {
        localStorage.setItem('userCart', JSON.stringify(filteredCart));
      }
      return {
        cart: filteredCart ?? [],
      };
    }
    case CartActions.SET_SUBSCRIPTIONS: {
      return { ...state, cart: payload };
    }
    case CartActions.CLEAR_CART: {
      if (typeof window !== 'undefined') {
        localStorage.setItem('userCart', JSON.stringify([]));
      }
      return { ...state, cart: [], total: 0, discount: 0 };
    }
    case CartActions.CALCULATE_TOTAL: {
      const subTotal = state.cart.reduce(
        (acc: number, plan: SubscriptionPlan) => (acc += plan.price),
        0
      );
      const total = subTotal;
      const discount = 0;
     
      return { ...state, total, discount };
    }
    default:
      return state;
  }
};

const addSubscriptions = (dispatch: Dispatch) => {
  return (subscription: SubscriptionPlan[]) => {
    dispatch({ type: CartActions.ADD_SUBSCRIPTIONS, payload: subscription });
    dispatch({ type: CartActions.CALCULATE_TOTAL, payload: null });
  };
};
const removeSubscription = (dispatch: Dispatch) => {
  return (subscription: string) => {
    dispatch({ type: CartActions.REMOVE_SUBSCRIPTION, payload: subscription });
    dispatch({ type: CartActions.CALCULATE_TOTAL, payload: null });
  };
};
const setSubscriptions = (dispatch: Dispatch) => {
  return (subscriptions: SubscriptionPlan[]) => {
    dispatch({ type: CartActions.SET_SUBSCRIPTIONS, payload: subscriptions });
    dispatch({ type: CartActions.CALCULATE_TOTAL, payload: null });
  };
};
const clearCart = (dispatch: Dispatch) => {
  return () => {
    dispatch({ type: CartActions.CLEAR_CART, payload: '' });
  };
};
export const { Provider, Context } = createContext(
  cartReducer,
  {
    addSubscriptions,
    removeSubscription,
    setSubscriptions,
    clearCart,
  },
  initialState
);
