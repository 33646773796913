import { thndrApi } from '@thndr/services/api';
import { MarketName } from '../types';
export type MarketHoursResponse = {
  session_open: string;
  session_close: string;
};
export type IMarketHoursArgs = {
  market: MarketName;
};
export const getMarketHours = async ({
  market
}: IMarketHoursArgs): Promise<MarketHoursResponse> => {
  const { data } = await thndrApi.get(`market-service/markets/hours`, {
    params: {
      market
    }
  });

  return data;
};
