import { IStorage } from './types';

export const LocalStorageWeb: IStorage = {
  getStorageItem: async (key: string) => {
    const value = await localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  },
  setStorageItem: async (key: string, value: any) => {
    const val = localStorage.setItem(key, JSON.stringify(value));
    return val;
  },
};
