import { IQueryObject, IUserThemeDetailsResponse } from '../types';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { EXPLORE_QUERY_KEYS } from '../query-keys';
import { getTimeTillNextUpdate } from '@thndr/namespace/assets';
import { IAsset } from '::root/libs/namespace/assets/src/lib/types';
import { thndrApi } from '@thndr/services/api';

const previewTheme = async ({
  page,
  query,
}: {
  page: number;
  query: IQueryObject;
}): Promise<IUserThemeDetailsResponse> => {
  const results = await thndrApi.post(
    `/assets-service/market-filters/preview?page=${page}&page_count=20&market=us&market=egypt&include_feed=true&feed_detail=true`,
    query
  );
  return results.data;
};

export const usePreviewTheme = ({
  query,
  isSubscribed,
  enabled = true,
}: {
  query: IQueryObject;
  isSubscribed?: boolean;
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();
  const lastUpdatedAt = queryClient.getQueryState([
    EXPLORE_QUERY_KEYS.previewThemeDetails,
    query,
  ])?.dataUpdatedAt;
  const maxRandomTime = isSubscribed ? 4000 : 10000;
  const timeTillNextUpdate = lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 15)
    : 15 * 60 * 1000;
  const cacheTime = timeTillNextUpdate + maxRandomTime;
  const hasQuery = Object.keys(query).length > 0;
  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery<IUserThemeDetailsResponse>({
    queryKey: [EXPLORE_QUERY_KEYS.previewThemeDetails, query],
    queryFn: ({ pageParam = 1 }) => previewTheme({ page: pageParam, query }),
    getNextPageParam: (lastPage, pages) => {
      const { asset_count } = lastPage;
      if (asset_count === allAssetsDetails?.length) {
        return undefined;
      }
      const nextPage = pages.length + 1;
      return nextPage;
    },
    staleTime: cacheTime,
    cacheTime,
    enabled: enabled && hasQuery,
  });

  const allAssetsDetails = data?.pages
    ?.map((page) => page?.['assets'])
    .flat() as IAsset[];
  const themeDetails = data?.pages?.[0];
  const assetsCount = data?.pages?.[0].asset_count;
  return {
    isLoading,
    isError,
    error,
    allAssetsDetails,
    themeDetails,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    assetsCount,
    refetch,
    data,
  };
};
