import React, { createContext, useEffect } from 'react';
import { UnleashClient } from 'unleash-proxy-client';

export const UnleashContext = createContext<UnleashClient | null>(null);

export const UnleashProvider: React.FC<{
  instance: UnleashClient;
  startImmediate?: boolean;
}> = function UnleashProvider({ startImmediate, instance, children }) {
  useEffect(() => {
    if (startImmediate && instance && typeof window !== 'undefined') {
      instance.start();
    }
  }, [instance, startImmediate]);
  return (
    <UnleashContext.Provider value={instance}>
      {children}
    </UnleashContext.Provider>
  );
};

export const UnleashConsumer = UnleashContext.Consumer;
