import { Config, derived } from '::store';
import { MarketName, AVAILABLE_EGYPT, MARKET_HOURS } from '../types';

export type ISettingsState = {
  simulatorEnabled: boolean;
  isLoaded: boolean;
  market: MarketName;
  marketHours: {
    [key in MarketName]: MARKET_HOURS;
  };
  selectedMarket: MarketName | null;
  availableMarkets: MarketName[];
};

export const state: ISettingsState = {
  selectedMarket: MarketName.EGYPT,
  availableMarkets: AVAILABLE_EGYPT,
  simulatorEnabled: false,
  isLoaded: false,
  marketHours: {
    [MarketName.US]: {
      session_open: '',
      session_close: '',
    },
    [MarketName.EGYPT]: {
      session_open: '',
      session_close: '',
    },
    [MarketName.SIMULATOR]: {
      session_open: '',
      session_close: '',
    },
  }, //FIXME: remove simulator from market hours
  market: derived<ISettingsState, Config['state'], MarketName>((s) =>
    s.simulatorEnabled
      ? MarketName.SIMULATOR
      : s.selectedMarket ?? MarketName.SIMULATOR
  ),
};
