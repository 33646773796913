import { AsyncAction, Action } from '@/store';

export const loadManagedFunds: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  state.managedFunds.isLoading = true;
  try {
    const token = await actions.auth.getAuthToken('application-read-token');
    const managedFunds = await effects.managedFunds.getManagedFunds(token);
    if (managedFunds) {
      state.managedFunds.data = managedFunds;
    }
  } finally {
    state.managedFunds.isLoading = false;
  }
};
