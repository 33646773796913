import { useQuery } from 'react-query';
import { SUBS_QUERY_KEYS } from '../../query-keys';
import { cancelSubscription, getSubscriptions } from '../api/subscriptions';

export const useSubscriptions = () => {
  const { data, isError, isLoading } = useQuery({
    queryKey: SUBS_QUERY_KEYS.subscriptions,
    queryFn: getSubscriptions,
  });

  return {
    subscriptions: data?.data?.results,
    provider: data?.data?.provider,
    isError,
    isLoading,
  };
};

export const useSubscriptionInfo = (subscriptionId: string) => {
  const { data, isError, isLoading } = useQuery(
    SUBS_QUERY_KEYS.subscriptions,
    getSubscriptions
  );
  return {
    subscriptionInfo: data?.data?.results.find(
      ({ id }: { id: string }) => id === subscriptionId
    ),
    isError,
    isLoading,
  };
};

export const useSubscriptionCreation = () => {
  return {};
};

export const useSubscriptionCancellation = (subscriptionId) => {
  return {};
};

export const useSubscriptionReactivation = () => {
  return {};
};
