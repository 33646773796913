import {
  AVAILABLE_ALL,
  AVAILABLE_EGYPT,
  IGetMarketHoursPayload,
  MarketName,
} from '../types';
import { AsyncAction, Action, json } from '::store';
import { getAssetMarket } from '../utils';

export const saveMarket: AsyncAction = async ({ state, effects }) => {
  const market = json(state.newMarket) as Partial<typeof state.newMarket>;
  delete market.isLoaded;
  delete market.market;
  await effects.storage.setItem('market', market);
};

export const loadMarket: AsyncAction = async ({ state, effects }) => {
  const market = await effects.storage.getItem('market');
  Object.assign(state.newMarket, market);
  state.newMarket.isLoaded = true;
};

export const disableSimulator: Action = ({ state, actions }) => {
  state.newMarket.simulatorEnabled = false;
  state.settings.isSimulatorEnabled = false;
};

export const enableSimulator: Action = ({ state }) => {
  state.newMarket.simulatorEnabled = true;
  state.settings.isSimulatorEnabled = true;
};

export const initializeAvailableMarkets: Action = ({ state, effects }) => {
  const canViewAllMarkets = effects.feature.isEnabled('can_view_all_markets');
  state.newMarket.availableMarkets = canViewAllMarkets
    ? AVAILABLE_ALL
    : AVAILABLE_EGYPT;
};

export const setMarket: Action<{
  selectedMarket: MarketName;
  userId: string | undefined;
}> = ({ state, actions }, { selectedMarket: market, userId }) => {
  if (market !== state.newMarket.selectedMarket) {
    actions.newMarket.setSelectedMarketCache({
      selectedMarket: market,
      userId,
    });
  }
  state.newMarket.selectedMarket = market;
};

export const setSelectedMarketCache: AsyncAction<{
  selectedMarket: MarketName;
  userId: string | undefined;
}> = async ({ effects }, { selectedMarket, userId }) => {
  effects.realTimeDB.update(`settings/${userId}/`, { selectedMarket });
};

export const getCachedSelectedMarket: AsyncAction<{
  userId: string | undefined;
}> = async ({ effects, actions }, { userId }) => {
  effects.realTimeDB
    .get(`settings/${userId}/selectedMarket`)
    .then((selectedMarket) => {
      if (selectedMarket) {
        actions.newMarket.setMarket({ selectedMarket, userId });
      }
    });
};

export const getAppAssetMarket: Action<string, MarketName> = (
  { state },
  assetId
) => {
  //FIXME: dont use assets namespace directly here
  return getAssetMarket(
    state.newMarket.market,
    state.newAssets.assetInfo.data[assetId]?.market
  );
};

export const getMarketHours: AsyncAction<IGetMarketHoursPayload, void> = async (
  { state, effects },
  { market }
) => {
  try {
    const token = state.authentication.authTokens.APP_TOKEN.value;
    const hours = await effects.newMarket.getMarketHours(token, { market });
    state.newMarket.marketHours[market] = hours;
  } catch (error) {
    effects.logger.recordError(error, { fn: 'getMarketHours' });
  }
};

export const onInitializeOvermind = async ({ actions }, { reaction }) => {
  await actions.newMarket.loadMarket();
  reaction(
    (state) => {
      return [state.newMarket.simulatorEnabled];
    },
    () => {
      actions.newMarket.saveMarket();
    }
  );
};
