import { thndrApi } from '@thndr/services/api';
import { useQueryClient } from 'react-query';
import { SUBS_QUERY_KEYS } from '../../query-keys';
import { Subscription } from '../types';

type CancelSubscriptionResponse = Subscription;
export const useCancelSubscription = () => {
  const query = useQueryClient();
  const cancelSubscription = async (subscriptionId: string) => {
    try {
      await thndrApi.post(
        `payment-service/v2/subscriptions/${subscriptionId}/cancel`
      );
      query.invalidateQueries(SUBS_QUERY_KEYS.subscriptions);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    cancelSubscription,
  };
};
