import { AsyncAction, Action } from '@/store';
import { CHART_INTERVALS } from '@/constants/market';
import { IAdvancedChart, IChart } from '@/models/market';

export const loadMarketData: AsyncAction = async ({
  state,
  effects,
  actions,
}) => {
  state.market.isLoading = true;
  try {
    const market = await actions.callEffectWithToken({
      tokenType: 'application-read-token',
      effect: effects.market.getMarketData,
      payload: {},
    });
    if (market) {
      Object.assign(state.market.data, market);
    }
  } finally {
    state.market.isLoading = false;
  }
};

export const setCurrentSymbol: Action<string> = ({ state }, symbol) => {
  state.market.currentSymbol = symbol;
};

export const getChart: AsyncAction<{
  assetId: string;
  interval: CHART_INTERVALS;
}> = async ({ state, actions, effects }, { assetId, interval }) => {
  try {
    const chart = await actions.callEffectWithToken({
      effect: effects.market.getChart,
      tokenType: 'notifications-service-token',
      payload: { assetIds: assetId, interval },
    });
    if (state.market.charts[assetId]) {
      state.market.charts[assetId][interval] = chart[assetId];
    } else {
      state.market.charts[assetId] = {
        [interval]: chart[assetId],
      };
    }
  } catch (error) {}
};

export const getAdvancedTVChart: AsyncAction<
  {
    asset_id: string;
    resolution: string;
    from_timestamp: number;
    to_timestamp: number;
  },
  {
    points: IAdvancedChart[];
    next_date?: number;
  }
> = async (
  { state, actions, effects },
  { asset_id, resolution, from_timestamp, to_timestamp },
) => {
  const resolutionBackend =
    resolution === '1D'
      ? 'day'
      : resolution === '1W' || resolution === '1M'
      ? 'week'
      : Number(resolution) >= 60
      ? 'hour'
      : 'minute';
  try {
    const chart = await actions.callEffectWithToken({
      effect: effects.market.getAdvancedChart,
      tokenType: 'notifications-service-token',
      payload: {
        asset_id,
        resolution: resolutionBackend,
        from_timestamp,
        to_timestamp,
      },
    });
    return chart;
  } catch (error) {}
};

export const getMarketDepth: AsyncAction<{
  assetId: string;
}> = async ({ state, actions, effects }, { assetId }) => {
  if (!state.market.marketDepth.isLoading) {
    state.market.marketDepth.isLoading = true;
    const marketDepthData = await actions.callEffectWithToken({
      effect: effects.market.getMarketDepth,
      tokenType: 'notifications-service-token',
      payload: { assetId: assetId },
    });
    state.market.marketDepth.data[assetId] = marketDepthData;
    state.market.marketDepth.isLoading = false;
  }
};
