import { resolveAction, untilWeResolve } from '@/util/until-we-resolve';
import {
  AuthTokenKey,
  ThndrAPIToken,
  AuthTokenSettings,
} from '@/constants/auth';
import { AsyncAction, Action } from '@/store';
import {
  InvalidInputError,
  ApplicationError,
  PrivilegedTokenNeedsRenewError,
} from '@/util/errors';
import { MARKETS } from '@/constants/market';
import { setToken } from '@thndr/services/api';
import { getDatabase, off, onValue, ref } from 'firebase/database';
const TEN_SECONDS = 10000;

export const getAuthToken: AsyncAction<
  AuthTokenKey,
  ThndrAPIToken | null
> = async ({ state, effects, actions }, authKeyRequest) => {
  const authKey = 'APP_TOKEN';
  if (
    state.authentication.authTokens['APP_TOKEN'].scopes?.length < 30 &&
    !state.newMarket.simulatorEnabled
  ) {
    state.auth.privilegedTokenNeedsRenew = true;
  }
  // if (!authKey) {
  //   throw new InvalidInputError(`authKey can not be undefined`);
  // }
  // if (!AuthTokenSettings[authKey]) {
  //   throw new InvalidInputError(`authKey ${authKey} does not exist`);
  // }
  const authTokenState = state.authentication.authTokens[authKey];
  if (!(await actions.auth.authTokenNeedRenew(authKey))) {
    state.authentication.authTokens[authKey].value = authTokenState.value;
    await setToken(authTokenState.value);
    return authTokenState.value;
  } else {
    state.auth.isLoadingAuthToken = true;
    try {
      if (await actions.auth.ifRefreshingToken(authKey)) {
        const token = actions.auth.getAuthToken(authKey);
        await setToken(token.value);
        return token;
      }
      if (
        state.authentication.authTokens['APP_TOKEN'].scopes?.length >= 30 &&
        (await actions.auth.privilegedTokenNeedsRenew())
      ) {
        return;
      }
      const token = await effects.auth.getAPIToken(
        AuthTokenSettings['APP_TOKEN'].scopes,
      );
      await actions.auth.setAuthToken({ authKey, token });
      state.authentication.authTokens.APP_TOKEN.value = token;
      await setToken(token);
      return token;
    } catch (error) {
      if (error instanceof PrivilegedTokenNeedsRenewError) {
        actions.auth.releaseWaitingRequests(authKey);
        throw error;
      }
      throw new ApplicationError(error, 'errors.auth.getAuthToken');
    } finally {
      actions.auth.releaseWaitingRequests(authKey);
      state.auth.isLoadingAuthToken = false;
    }
  }
};

export const setAuthToken: AsyncAction<{
  authKey: AuthTokenKey;
  token: ThndrAPIToken;
}> = async ({ state, effects }, { authKey, token }) => {
  await effects.auth.setAuthToken(authKey, token);
  Object.assign(
    state.authentication.authTokens[authKey],
    effects.auth.deriveTokenState(token),
  );
  if (authKey === 'APP_TOKEN') {
    state.authentication.authTokens['APP_TOKEN'].scopes =
      effects.auth.deriveTokenState(token).scopes;
    await setToken(token);
  }
};

export const privilegedTokenNeedsRenew: Action<
  void,
  Promise<boolean>
> = async ({ state, actions }) => {
  const needsRenew =
    (await actions.auth.authTokenNeedRenew('APP_TOKEN')) &&
    state.authentication.authTokens.APP_TOKEN.scopes?.length < 30;
  state.auth.privilegedTokenNeedsRenew = needsRenew;
  return needsRenew;
};
export const getServerTime = async ({ effects, state }) => {
  if (state.auth.authServerOffset === null) {
    return new Promise((resolve) => {
      const db = getDatabase();
      const offsetRef = ref(db, '.info/serverTimeOffset');
      const onOffset = (snap) => {
        const offset = snap.val() ?? 0; // Cache the offset value
        state.auth.authServerOffset = offset; // Cache the offset value
        const estimatedServerTimeMs = new Date().getTime() + offset;
        resolve(estimatedServerTimeMs);
        off(offsetRef, 'value', onOffset);
      };
      return onValue(offsetRef, onOffset);
    });
  } else {
    const estimatedServerTimeMs =
      new Date().getTime() + state.auth.authServerOffset;
    return estimatedServerTimeMs;
  }
};

export const authTokenNeedRenew: AsyncAction<AuthTokenKey, boolean> = async (
  { state, actions },
  authKey,
  scopes?,
) => {
  const authToken = state.authentication.authTokens?.[authKey];
  if (authToken.value === null) {
    return true;
  }
  // const storedScopes = authToken.scopes;
  // const configuredScopes = AuthTokenSettings[authKey].scopes;
  // const scopesChanged = getSetDiff(configuredScopes, storedScopes).size > 0;
  const serverTime = await actions.auth.getServerTime();
  const expiryTime = authToken.expires?.getTime() ?? 0;
  const dateExpired = expiryTime - serverTime < TEN_SECONDS;
  const isExpiredToken = dateExpired || !authToken.value;
  return isExpiredToken;
};

function getSetDiff(a: any[], b: any[] = []) {
  const aSet = new Set(a);
  const bSet = new Set(b);
  return new Set([...aSet].filter((_) => !bSet.has(_)));
}

export const ifRefreshingToken: AsyncAction<AuthTokenKey, boolean> = async (
  { state },
  authKey,
) => {
  const authTokenState = state.authentication.authTokens[authKey];
  if (!authTokenState?.isLoading) {
    if (!authTokenState) {
      state.authentication.authTokens[authKey] = {
        isLoading: true,
        value: null,
        waitingRequests: [],
      };
    } else {
      authTokenState.isLoading = true;
    }
    return false;
  } else {
    const [untilTokenIsRenewed, resolveWaitingRequest] = untilWeResolve();
    authTokenState.waitingRequests.push(() => {
      resolveWaitingRequest();
      return false;
    });
    await untilTokenIsRenewed;
    return true;
  }
};

export const releaseWaitingRequests: Action<AuthTokenKey> = (
  { state },
  authKey,
) => {
  const authTokenState = state.authentication.authTokens[authKey];
  authTokenState.isLoading = false;
  authTokenState.waitingRequests = authTokenState.waitingRequests.filter(
    (waitingRequest) => waitingRequest(),
  );
};

export const resolveRenewPrivilegedToken: Action<boolean> = (
  { state },
  didRenewToken,
) => {
  resolveAction('renew-passphrase-modal', didRenewToken);
  state.auth.isPassphraseModalVisible = false;
};

export const clearToken: Action<AuthTokenKey> = ({ state }, key) => {
  state.authentication.authTokens[key].value = null;
};

export const refreshTrust: AsyncAction = async ({ state, actions }) => {
  //FIXME: function is never used
  const oldTrustLevel = state.auth.base_trust_level;
  actions.auth.clearToken('application-write-token');
  await actions.auth.getAuthToken('application-write-token');
  const appReadToke = state.auth.authTokens['application-write-token'];
  const newTrustLevel = appReadToke.trust_level;
  if (oldTrustLevel !== newTrustLevel) {
    await actions.auth.clearAuthTokens();
    await actions.auth.getAuthToken('application-read-token');
  }
};
