const globalAny: any = global;

export const identify = (userId: string, userProperties: any) => {
  if (process.env.NODE_ENV !== 'development') {
    globalAny.analytics.identify(userId, userProperties);
  } else {
    // will do nothing, or something else in development
  }
};

export const trackEvent = async (
  eventName: string,
  eventProperties?: object,
) => {
  if (process.env.NODE_ENV !== 'development' && eventName) {
    globalAny.analytics.track(eventName, {
      ...eventProperties,
    });
  } else {
    // will do nothing, or something else in development
  }
};

export const trackPage = (screenName: string, params?: any) => {
  if (process.env.NODE_ENV !== 'development' && screenName) {
    //@ts-ignore
    window.analytics.page(screenName, params);
  } else {
    // will do nothing, or something else in development
  }
};
