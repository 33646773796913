export const randomTimeWrapper = <T>({
  isSubscribed,
  skipDelay,
  callback,
}: {
  isSubscribed: boolean;
  skipDelay: boolean;
  callback: () => Promise<Partial<T>>;
}): Promise<Partial<T>> => {
  return new Promise((resolve, reject) => {
    const maxRandomTime = skipDelay ? 0 : 30000;
    const randomTime = Math.floor(Math.random() * maxRandomTime) + 1;
    setTimeout(async () => {
      try {
        const results = await callback();
        resolve(results);
      } catch (err) {
        reject();
      }
    }, randomTime);
  });
};
