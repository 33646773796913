import { AsyncAction } from '@/store';
import Routes from '@/constants/routes';

export const logout: AsyncAction<void> = async ({
  state,
  effects,
  actions,
}) => {
  effects.navigation.replace(Routes.INDEX);
  await Promise.all([
    Promise.all([effects.auth.logoutFirebase()]).then(() => {
      state.auth.hasFirebaseToken = false;
    }),
    actions.auth.clearAuthTokens(),
    actions.user.clear(),
    actions.accountAccess.clear(),
  ]);
  window?.fcWidget?.destroy();
};

export const clearAuthData: AsyncAction<void> = async ({
  effects,
  state,
  actions,
}) => {
  await Promise.all([
    Promise.all([effects.auth.logoutFirebase()]).then(() => {
      state.auth.hasFirebaseToken = false;
    }),
    actions.auth.clearAuthTokens(),
    actions.user.clear(),
  ]);
};

export const clearAuthTokens: AsyncAction<void> = async ({
  state,
  effects,
}) => {
  await effects.auth.clearAuthTokens();
  state.auth.authTokens = effects.auth.initialAuthTokenStates();
  state.authentication.authTokens['APP_TOKEN'] = {
    isPrivileged: false,
    isLoading: false,
    value: null,
    waitingRequests: [],
  };
  state.authentication.authTokens['firebase-token'] = {
    isLoading: false,
    value: null,
    waitingRequests: [],
  };
};
