import { IManagedFundResponse } from '@/models/types';

type IManagedFundState = {
  isLoading: boolean;
  data: IManagedFundResponse | null;
};

export const state: IManagedFundState = {
  isLoading: true,
  //@ts-ignore
  data: {},
};
