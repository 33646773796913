export enum OrderModes {
  Amount = 'Amount',
  Shares = 'Shares',
}
export enum MarketName {
  SIMULATOR = 'simulator',
  EGYPT = 'egypt',
  US = 'us',
}
export enum ORDER_TYPE {
  BUY = 'BUY',
  SELL = 'SELL',
}
export enum OrderSteps {
  OrderDetails = 'ORDER_DETAILS',
  OrderReceipt = 'ORDER_RECEIPT',
  SubmissionStatus = 'SUBMISSION_STATUS',
}
export enum OrderType {
  Market = 'MARKET',
  Limit = 'LIMIT',
  AdvancedLimit = 'ADVANCED_LIMIT',
}
export enum OrderDirection {
  Buy = 'BUY',
  Sell = 'SELL',
}
export enum ValidationErrorTypes {
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
  InsufficientShares = 'INSUFFICIENT_SHARES',
  OrderTypeEmpty = 'EMPTY_ORDER_TYPE',
  InsufficientAdvancedShares = 'INSUFFICIENT_ADVANCED_SHARES',
  InvalidDate = 'INVALID_DATE',
  InvalidPrice = 'INVALID_PRICE',
  InvalidShares = 'INVALID_SHARES',
}
export enum AssetClass {
  Stock = 'STOCK',
  ETF = 'ETF',
  Index = 'INDEX',
  ManagedFund = 'FUND',
}

export enum OrderSubmissionStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Loading = 'LOADING',
}
export enum OrderTimeInForce {
  DAY = 'day',
  DATE = 'date',
  GTC = 'gtc',
  OPG = 'opg',
  CLS = 'cls',
  IOC = 'ioc',
  FOK = 'fok',
}

export enum OrderExecution {
  NO_FILL = 'NO_FILL',
  MIN_FILL = 'MIN_FILL',
  FILL_OR_KILL = 'FILL_OR_KILL',
  FILL_AND_KILL = 'FILL_AND_KILL',
  ALL_OR_NONE = 'ALL_OR_NONE',
}

export enum OrderSettlement {
  T0 = 'T0',
  T1 = 'T1',
  T2 = 'SETTLED',
}

export type Order = {
  amount: number; // 2292;
  amount_filled: number; //2292;
  avg_price: number; //12.4;
  created_at: string; //12.4;'2020-08-11T14:45:07.631138';
  fix_status: string; //'COMPLETED';
  id: string; //'6';
  is_limit: false;
  market_name: MarketName;
  market_price: number; //12.4;
  order_fees: object[];
  order_number: number | null; //12.4;;
  order_status: OrderStatus;
  order_status_details: OrderStatusDetail;
  order_type: OrderDirection; //'BUY';
  org_qty: number; //2292;
  price: number; //0
  status: string; //'COMPLETED';
  stock_id: string; // 'EAST';
  symbol: string; //'EAST';
  symbol_code: null;
  traded_qty: number; //2292;
  user_id: string; //'x3zvnAJvTYVMyZvUKqjoAkxmKiI2';
  asset_class: AssetClass;
  asset_id?: string;
  rejection_reason?: OrderRejectionReasons;
  currency: string;
  name: string;
  logo?: string;
  time_in_force: string;
  time_in_force_date: string;
  settlement: OrderSettlement;
  rejection_reason_value: number;
};

export enum OrderStatusDetail {
  Pending = 'PENDING',
  PendingSubmit = 'PENDING_SUBMIT',
  PendingCancellation = 'PENDING_CANCELLATION',
  PendingQueuedCancel = 'PENDING_QUEUED_CANCEL',
  PendingMcdr = 'PENDING_MCDR',
  ApprovedMcdr = 'APPROVED_MCDR',
  RejectedMcdr = 'REJECTED_MCDR',
  Fulfilled = 'FULFILLED',
  PartiallyFilled = 'PARTIALLY_FILLED',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
  PartiallyCancelled = 'PARTIALLY_CANCELLED',
  LocallyCancelled = 'LOCALLY_CANCELLED',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
  Suspended = 'SUSPENDED',
  InternalError = 'INTERNAL_ERROR',
  ExternalError = 'EXTERNAL_ERROR',
  TradeCancelled = 'TRADE_CANCELLED',
  NotExist = 'NOT_EXIST',
  PendingReplace = 'PENDING_REPLACE',
  PendingQueuedSubmit = 'PENDING_QUEUED_SUBMIT',
  LocallyRejected = 'LOCALLY_REJECTED',
  InsufficientShares = 'INSUFFICIENT_SHARES',
  Replaced = 'REPLACED',
}

export enum OrderStatus {
  QueuedSubmit = 'QUEUED_SUBMIT',
  QueuedCancel = 'QUEUED_CANCEL',
  Pending = 'PENDING',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
}

export enum OrderRejectionReasons {
  OverPriceRange = 'OVER_PRICE_RANGE',
  UnderPriceRange = 'UNDER_PRICE_RANGE',
  NotAvailableForSale = 'NOT_AVAILABLE_FOR_SALE',
  DiscoverySessionMarketOrder = 'DISCOVERY_SESSION_MARKET_ORDER',
  BuyOrderExistsForSecurity = 'BUY_ORDER_EXISTS_FOR_SECURITY',
  UnknownOrder = 'UNKNOWN_ORDER',
  NoWithdrawableOrders = 'NO_WITHDRAWABLE_ORDERS',
  SecurityNotAvailable = 'SECURITY_NOT_AVAILABLE',
  NotAllowedToPlaceOrder = 'NOT_ALLOWED_TO_PLACE_ORDER',
  OrderNotEditable = 'ORDER_NOT_EDITABLE',
  OrderNoLongerOpen = 'ORDER_NO_LONGER_OPEN',
  DuplicateOrderId = 'DUPLICATE_ORDER_ID',
  OrderTypeNotAccepted = 'ORDER_TYPE_NOT_ACCEPTED',
  EntryPriceNotMet = 'ENTRY_PRICE_NOT_MET',
  UserIdExpired = 'USER_ID_EXPIRED',
}

export interface IOrderForm {
  order_id?: string;
  order_type: OrderDirection;
  is_limit?: boolean;
  stock_id?: string;
  amount: number;
  price?: number;
  asset_id?: string;
  asset_class?: string;
  amount_to_invest?: number;
  redeem_all?: boolean;
  execution_type?: OrderExecution;
  time_in_force?: OrderTimeInForce;
  time_in_force_date?: string;
  settlement?: OrderSettlement;
  advanced_order?: boolean;
  idempotent_id?: string;
}

export enum ORDER_ERROR {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  MARKET_NOT_FOUND = 'MARKET_NOT_FOUND',
  MARKET_IS_CLOSED = 'MARKET_IS_CLOSED',
  INSUFFICIENT_SHARES = 'INSUFFICIENT_SHARES',
  INSUFFICIENT_MARKET_VOLUME = 'INSUFFICIENT_MARKET_VOLUME',
  EGID_ERROR = 'EGID_ERROR',
  LIMIT_ERROR = 'LIMIT_ERROR',
  CANCEL_ERROR = 'CANCEL_ERROR',
  UPDATE_ERROR = 'UPDATE_ERROR',
  MALFORMED_TOKEN = 'MALFORMED_TOKEN',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_TOKEN_PAYLOAD = 'INVALID_TOKEN_PAYLOAD',
  UNAUTHORIZED_TOKEN = 'UNAUTHORIZED_TOKEN',
  REQUIRED_SCOPE_NOT_FOUND = 'REQUIRED_SCOPE_NOT_FOUND',
  AUTH_EXCEPTION = 'AUTH_EXCEPTION',
  MISSING_TOKEN = 'MISSING_TOKEN',
  MARKET_NA = 'MARKET_NA',
  NOT_FOUND = 'NOT_FOUND',
  ORDER_NOT_EDITABLE = 'ORDER_NOT_EDITABLE',
  ORDER_NOT_CANCELABLE = 'ORDER_NOT_CANCELABLE',
  INVALID_ASSET = 'INVALID_ASSET',
  MISSING_ASSET_META = 'MISSING_ASSET_META',
  ASSET_NOT_TRADABLE = 'ASSET_NOT_TRADABLE',
  STOCK_TRADING_NOT_ALLOWED = 'STOCK_TRADING_NOT_ALLOWED',
}

export const ERROR_CONFIG_MAP: {
  [error in ORDER_ERROR]: string;
} = {
  [ORDER_ERROR.INSUFFICIENT_FUNDS]: 'orderErrors.insufficientFunds',
  [ORDER_ERROR.INSUFFICIENT_BALANCE]: 'orderErrors.insufficientBalance',
  [ORDER_ERROR.ORDER_NOT_FOUND]: 'orderErrors.orderNotFound',
  [ORDER_ERROR.MARKET_NOT_FOUND]: 'orderErrors.marketNotFound',
  [ORDER_ERROR.MARKET_IS_CLOSED]: 'orderErrors.marketIsClosed',
  [ORDER_ERROR.INSUFFICIENT_SHARES]: 'orderErrors.insufficientShares',
  [ORDER_ERROR.INSUFFICIENT_MARKET_VOLUME]:
    'orderErrors.insufficientMarketVolume',
  [ORDER_ERROR.EGID_ERROR]: 'orderErrors.EGIDError',
  [ORDER_ERROR.LIMIT_ERROR]: 'orderErrors.limitError',
  [ORDER_ERROR.CANCEL_ERROR]: 'orderErrors.cancelError',
  [ORDER_ERROR.UPDATE_ERROR]: 'orderErrors.updateError',
  [ORDER_ERROR.MALFORMED_TOKEN]: 'orderErrors.malformedToken',
  [ORDER_ERROR.INVALID_TOKEN]: 'orderErrors.invalidToken',
  [ORDER_ERROR.INVALID_TOKEN_PAYLOAD]: 'orderErrors.invalidTokenPayload',
  [ORDER_ERROR.UNAUTHORIZED_TOKEN]: 'orderErrors.unauthorizedToken',
  [ORDER_ERROR.REQUIRED_SCOPE_NOT_FOUND]: 'orderErrors.requiredScopeNotFound',
  [ORDER_ERROR.AUTH_EXCEPTION]: 'orderErrors.authException',
  [ORDER_ERROR.MISSING_TOKEN]: 'orderErrors.missingToken',
  [ORDER_ERROR.MARKET_NA]: 'orderErrors.marketNA',
  [ORDER_ERROR.NOT_FOUND]: 'orderErrors.notFound',
  [ORDER_ERROR.ORDER_NOT_EDITABLE]: 'orderErrors.orderNotEditable',
  [ORDER_ERROR.ORDER_NOT_CANCELABLE]: 'orderErrors.orderNotCancelable',
  [ORDER_ERROR.INVALID_ASSET]: 'orderErrors.invalidAsset',
  [ORDER_ERROR.MISSING_ASSET_META]: 'orderErrors.missingAssetMeta',
  [ORDER_ERROR.ASSET_NOT_TRADABLE]: 'orderErrors.assetNotTradable',
  [ORDER_ERROR.STOCK_TRADING_NOT_ALLOWED]: 'orderErrors.stockTradingNotAllowed',
};
