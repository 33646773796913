import { initialForm, IOrderState } from './state';

import {
  OrdersByStatus,
  ORDER_SETTLEMENT,
  ORDER_STATUS,
  QUICK_TRADE_VIEWS,
} from '@/constants/orders';
import { ASSET_CLASSES } from '@/models/assets';
import { IOrderForm, IOrderView } from '@/models/orders';
import { Action, AsyncAction, Context } from '@/store';
import { ORDER_TYPES } from '@/constants/orders';
import { i18n } from '@/store/effects';
import { IOrder } from '@/models/investor';
import { off } from 'process';
import { MarketName } from '@thndr/namespace/market';
import { splitOrder } from './order-splitting';

export const setTrayVisible: Action<boolean> = (
  { state, actions },
  isVisible,
) => {
  state.orders.tray.isVisible = isVisible;
  state.orders.tray.activeView = QUICK_TRADE_VIEWS.SEARCH_ASSET;
  state.orders.tray.search.query = '';
  state.orders.tray.search.assets = [];
};

export const updateOrderView: Action<Partial<IOrderView>> = (
  { state }: Context,
  orderUpdate,
) => {
  state.orders.orderView = { ...state.orders.orderView, ...orderUpdate };
};
export const updateForm: Action<Partial<IOrderForm>> = (
  { state },
  formUpdate,
) => {
  state.orders.form = { ...state.orders.form, ...formUpdate };
};

export const updateTray: Action<Partial<IOrderState['tray']>> = (
  { state },
  trayUpdate,
) => {
  state.orders.tray = { ...state.orders.tray, ...trayUpdate };
};

export const clearForm: Action = ({ state }) => {
  state.orders.form = initialForm;
};

// export const updateTraySearch: AsyncAction<string> = async (
//   { state, actions },
//   searchQuery,
// ) => {
//   if (!searchQuery.replace(' ', '').length) {
//     state.orders.tray.search.assets = [];
//   } else {
//     state.orders.tray.search.isLoading = true;
//     state.orders.tray.search.query = searchQuery;
//     const response = await actions.search.searchAssets({ query: searchQuery });
//     state.orders.tray.search.assets = response.assets;
//     state.orders.tray.search.count = response.count;
//     state.orders.tray.search.isLoading = false;
//   }
// };

export const selectEditOrder: AsyncAction<{
  assetId: string;
  orderType?: ORDER_TYPES;
  orderId?: string;
  orderAmount?: number;
  orderPrice?: number;
}> = async (
  { state, actions },
  { assetId, orderType, orderId, orderAmount, orderPrice },
) => {
  actions.orders.clearForm();
  state.orders.orderId = orderId;
  await actions.newAssets.getAssetInfo({ asset_id: assetId });
  state.orders.tray.selectedAsset = assetId;
  const asset = state.newAssets?.assetInfo?.data?.[assetId];

  actions.orders.updateForm({
    asset_id: assetId,
    order_type: orderType ? orderType : ORDER_TYPES.BUY,
    asset_class: asset.asset_class,
    order_id: orderId,
    amount: orderAmount ?? 0,
    price: orderPrice ?? 0,
    is_limit: true,
    stock_id: asset.symbol,
  });
  state.orders.tray.activeView = QUICK_TRADE_VIEWS.EDIT_ORDER;
  actions.orders.updateTray({ isVisible: true });
};

export const selectAsset: Action<{
  assetId: string;
  orderType?: ORDER_TYPES;
  orderId?: string;
  orderAmount?: number;
  orderPrice?: number;
}> = ({ state, actions }, { assetId, orderType }) => {
  //FIXME: integrate with new asset submodule
  actions.orders.clearForm();
  actions.newAssets.getAssetInfo({ asset_id: assetId });
  state.orders.tray.selectedAsset = assetId;
  const asset = state.newAssets?.assetInfo?.data?.[assetId];
  actions.orders.updateForm({
    asset_id: assetId,
    order_type: orderType ? orderType : ORDER_TYPES.BUY,
    asset_class: asset.asset_class,
  });

  if (asset.asset_class === ASSET_CLASSES.STOCK) {
    actions.orders.updateForm({
      stock_id: asset.symbol,
      price: asset.is_ipo ? asset.ipo_price : asset.feed.close,
    });
  } else if (asset.asset_class === ASSET_CLASSES.MANAGED_FUND) {
    actions.orders.updateForm({
      amount_to_invest: orderType === ORDER_TYPES.BUY ? 0 : 0,
    });
  }
  state.orders.tray.activeView = QUICK_TRADE_VIEWS.SET_DETAILS;
  actions.orders.updateTray({ isVisible: true });
};

export const submitOrder: AsyncAction<{
  ownedSecurityInfo: any;
}> = async ({ effects, actions, state }: Context, { ownedSecurityInfo }) => {
  const market = state.newMarket.market;
  state.orders.tray.isSubmitting = true;
  const order = { ...state.orders.form };

  if (order.advanced_order === false) {
    delete order.settlement;
    delete order.time_in_force;
    delete order.time_in_force_date;
    delete order.execution_type;
  } else {
    if (order.order_type === ORDER_TYPES.BUY) {
      delete order.execution_type;
      delete order.settlement;
    }
    if (order.time_in_force_date === null) {
      delete order.time_in_force_date;
    }
  }
  delete order.advanced_order;
  if (order.asset_class === ASSET_CLASSES.STOCK) {
    delete order.amount_to_invest;
  } else if (order.asset_class === ASSET_CLASSES.MANAGED_FUND) {
    order.is_limit = false;
  }

  const simulatorEnabled = state.newMarket.simulatorEnabled;
  const assetId = order?.asset_id;
  const assetMarket = simulatorEnabled
    ? MarketName.SIMULATOR
    : state.newAssets.assetInfo?.data?.[assetId]?.market ??
      MarketName.SIMULATOR;
  const assetClass = state.newAssets.assetInfo?.data?.[assetId]?.asset_class;
  const shouldSplitOrder =
    assetClass === ASSET_CLASSES.STOCK &&
    !simulatorEnabled &&
    order.order_type === 'SELL';

  const orders = shouldSplitOrder
    ? splitOrder({ order, ownedSecurityInfo })
    : [order];
  const isSplitting = orders.length > 1;

  if (!isSplitting) {
    await actions.orders.submitSingleOrder({
      market: assetMarket,
      order: orders?.[0] ?? order,
    });
    return;
  } else {
    await actions.orders.submitMultipleOrder({ market: assetMarket, orders });
    return;
  }
};

export const submitMultipleOrder: AsyncAction<{
  market: string;
  orders: IOrderForm[];
}> = async ({ effects, actions, state }, { market, orders }) => {
  try {
    await actions.callEffectWithToken({
      effect: effects.orders.postOrder,
      payload: { market, order: orders?.[0] },
      tokenType: actions.investor.getCurrentMarketToken('write'),
    });

    await actions.callEffectWithToken({
      effect: effects.orders.postOrder,
      payload: { market, order: orders?.[1] },
      tokenType: actions.investor.getCurrentMarketToken('write'),
    });

    state.orders.tray.activeView = QUICK_TRADE_VIEWS.ORDER_SUCCESS;
    actions.investor.getInvestorData();
    actions.orders.getOrdersByStatus({
      asset_id: orders?.[0]?.asset_id,
      order_status: ORDER_STATUS.PENDING,
    });
    if (market !== 'simulator') {
      effects.analytics.trackEvent('submit_order', {
        type: orders?.[0]?.order_type,
        asset_class: orders?.[0]?.asset_class,
      });
    }
  } catch (error) {
    const message = error?.response?.data?.detail?.msg;
    effects.notice.showErrorMessage({
      title: i18n?.t('errors.orders.couldNotSubmitOrder'),
      description: message,
    });
  } finally {
    state.orders.tray.isSubmitting = false;
  }
};

export const submitSingleOrder: AsyncAction<{
  market: string;
  order: IOrderForm;
}> = async ({ effects, actions, state }, { market, order }) => {
  try {
    await actions.callEffectWithToken({
      effect: effects.orders.postOrder,
      payload: { market, order },
      tokenType: actions.investor.getCurrentMarketToken('write'),
    });

    state.orders.tray.activeView = QUICK_TRADE_VIEWS.ORDER_SUCCESS;
    actions.investor.getInvestorData();
    actions.orders.getOrdersByStatus({
      asset_id: order.asset_id,
      order_status: ORDER_STATUS.PENDING,
    });
    if (market !== 'simulator') {
      effects.analytics.trackEvent('submit_order', {
        type: order.order_type,
        amount: order.amount_to_invest,
        asset_class: order.asset_class,
      });
    }
  } catch (error) {
    const message = error?.response?.data?.detail?.msg;
    effects.notice.showErrorMessage({
      title: i18n?.t('errors.orders.couldNotSubmitOrder'),
      description: message,
    });
  } finally {
    state.orders.tray.isSubmitting = false;
  }
};

export const getOrdersByStatus: AsyncAction<{
  asset_id: string;
  order_status: ORDER_STATUS;
}> = async ({ effects, actions, state }, { asset_id, order_status }) => {
  const market = state.newMarket.market;
  state.orders.orders.isLoading = true;
  if (
    state.authentication.authTokens['APP_TOKEN'].scopes?.length < 30 &&
    !state.newMarket.simulatorEnabled
  ) {
    console.log('?');
    state.auth.privilegedTokenNeedsRenew = true;
    return;
  }
  const orders = await actions.callEffectWithToken({
    effect: effects.orders.getOrders,
    payload: { market, asset_id, status: order_status },
    tokenType: actions.investor.getCurrentMarketToken('read'),
  });
  if (state.orders.assetOrders.data[asset_id] === undefined) {
    state.orders.assetOrders.data[asset_id] = {} as OrdersByStatus;
  }
  state.orders.assetOrders.data[asset_id][order_status] = orders;
  state.orders.orders.isLoading = false;
};

export const getOrders: AsyncAction = async ({ effects, actions, state }) => {
  if (!state.orders.orders.isLoading) {
    const market = state.newMarket.market;
    state.orders.orders.isLoading = true;
    if (
      state.authentication.authTokens['APP_TOKEN'].scopes?.length < 30 &&
      !state.newMarket.simulatorEnabled
    ) {
      state.auth.privilegedTokenNeedsRenew = true;
      return;
    }
    const orders = await actions.callEffectWithToken({
      effect: effects.orders.getOrders,
      payload: { market },
      tokenType: actions.investor.getCurrentMarketToken('read'),
    });
    state.orders.orders.data = orders;
    state.orders.orders.isLoading = false;
  }
};

export const getOrdersV2: AsyncAction = async ({ effects, actions, state }) => {
  //FIXME: Puts orders by asset id and status in state. Currently not used.
  if (!state.orders.orders.isLoading) {
    const market = state.newMarket.market;
    state.orders.orders.isLoading = true;
    if (
      state.authentication.authTokens['APP_TOKEN'].scopes?.length < 30 &&
      !state.newMarket.simulatorEnabled
    ) {
      state.auth.privilegedTokenNeedsRenew = true;
      return;
    }
    const orders = await actions.callEffectWithToken({
      effect: effects.orders.getOrders,
      payload: { market },
      tokenType: actions.investor.getCurrentMarketToken('read'),
    });
    let ordersStateUpdate: { [assetId: string]: OrdersByStatus } = {};

    orders?.map((order: IOrder) => {
      const assetId = order.asset_id;
      if (ordersStateUpdate[assetId] === undefined) {
        ordersStateUpdate[assetId] = {} as OrdersByStatus;
      }
      ordersStateUpdate[assetId][order.order_status] = [
        order,
        ...(ordersStateUpdate[assetId][order.order_status] ?? []),
      ];
    });
    state.orders.assetOrders.data = ordersStateUpdate;
    state.orders.orders.isLoading = false;
  }
};

export const cancelOrder: AsyncAction<string> = async (
  { effects, actions, state },
  orderId,
) => {
  try {
    const market = state.newMarket.market;
    state.orders.orders.isCanceling = true;
    await actions.callEffectWithToken({
      effect: effects.orders.cancelOrder,
      payload: { market, id: orderId },
      tokenType: actions.investor.getCurrentMarketToken('write'),
    });
    actions.orders.getOrders();
    effects.notice.showSuccessMessage({
      title: i18n?.t('alerts.successfullyCancelled', { ns: 'orders' }),
    });
  } catch (err) {
    alert(err);
  } finally {
    state.orders.orders.isCanceling = false;
  }
};

export const editOrder: AsyncAction<string> = async (
  { effects, actions, state },
  orderId,
) => {
  try {
    const market = state.newMarket.market;
    state.orders.tray.isSubmitting = true;
    const order = state.orders.form;
    await actions.callEffectWithToken({
      effect: effects.orders.patchOrder,
      payload: { market, order, id: orderId },
      tokenType: actions.investor.getCurrentMarketToken('write'),
    });
    state.orders.tray.activeView = QUICK_TRADE_VIEWS.ORDER_SUCCESS;
    state.orders.tray.isSubmitting = false;
  } catch (error) {
    state.orders.tray.isSubmitting = false;
    if (error?.originalError?.response?.status === 400) {
      effects.notice.showErrorMessage({
        title: i18n?.t('wrong'),
        description: error?.originalError?.response?.data?.detail?.msg,
      });
    }
  }
};
