import { createGlobalStyle } from 'styled-components';

import { Theme } from '::theme';

export const GlobalStyle = createGlobalStyle`
  body {
    direction: ${({ theme }) => ((theme as Theme).rtl! ? 'rtl' : 'ltr')};
  }


  .custom_fc_frame {
    ${({ theme }) =>
      (theme as Theme).rtl
        ? `
          left: 5px!important;
          right: unset!important;
        `
        : ``};
  }

  .ant-modal-close {
    ${({ theme }) =>
      (theme as Theme).rtl
        ? `
          left: 0;
          right: unset;
        `
        : ``};
  }
`;
