import { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { EVENTS, IToggle, IVariant } from 'unleash-proxy-client';
import { UnleashContext } from './context';

export function useUnleash() {
  return useContext(UnleashContext);
}

export function useIsEnabled(feature_key: string) {
  const unleash = useUnleash();
  return useUnleashSubscription<boolean>({
    fnConfig: feature_key,
    unleashFunction: unleash?.isEnabled.bind(unleash) ?? (() => undefined),
  });
}

export function useGetVariant(feature_key: string) {
  const unleash = useUnleash();
  return useUnleashSubscription<IVariant>({
    fnConfig: feature_key,
    unleashFunction:
      unleash?.getVariant.bind(unleash) ?? ((name: string) => ({ name })),
  });
}

export function useToggleList() {
  const unleash = useUnleash();
  return useUnleashSubscription<IToggle[]>({
    unleashFunction: unleash?.getAllToggles.bind(unleash) ?? (() => []),
  });
}

export function useUnleashSubscription<T>({
  fnConfig,
  unleashFunction,
}: {
  fnConfig?: any;
  unleashFunction(...args: any): T;
}) {
  const unleash = useUnleash();
  const [value, setValue] = useState<T>(unleashFunction(fnConfig));
  const valueRef = useRef<T>(value);
  valueRef.current = value;
  const updateValue = useCallback(() => {
    const newValue = unleashFunction(fnConfig);
    // console.log(
    //   `useUnleashSubscription [${unleashFunction.name}]`,
    //   `\nnewVal: ${newValue}\n`,
    //   `\noldVal: ${valueRef.current}\n`,
    // );
    if (valueRef.current !== newValue) {
      valueRef.current = newValue;
      setValue(newValue);
    }
  }, [fnConfig, unleashFunction]);
  useEffect(() => {
    unleash?.on(EVENTS.UPDATE, updateValue);
    unleash?.on(EVENTS.READY, updateValue);
    return () => {
      unleash?.off(EVENTS.UPDATE, updateValue);
      unleash?.off(EVENTS.READY, updateValue);
    };
  }, [unleash, updateValue]);

  return value;
}
