import { useInfiniteQuery, useQueryClient } from 'react-query';
import { EXPLORE_QUERY_KEYS } from '../query-keys';
import { getTimeTillNextUpdate } from '@thndr/namespace/assets';
import { IAsset } from '::root/apps/thndr-web/src/models/assets';
import { IUseUserThemes, IUserThemeDetailsResponse } from '../types';
import { thndrApi } from '@thndr/services/api';

const getUserThemeDetails = async ({
  themeId,
  page,
}: {
  themeId: string;
  page: number;
}): Promise<IUserThemeDetailsResponse> => {
  const results = await thndrApi.get(
    `/assets-service/market-filters/${themeId}?page=${page}&page_count=20&market=us&market=egypt&include_feed=true&feed_detail=true`
  );
  return results.data;
};
export function useUserThemeDetails({
  themeId,
  isSubscribed,
  enabled = true,
}: IUseUserThemes) {
  const queryClient = useQueryClient();
  const lastUpdatedAt = queryClient.getQueryState([
    EXPLORE_QUERY_KEYS.userThemeDetails,
    themeId,
  ])?.dataUpdatedAt;
  const maxRandomTime = isSubscribed ? 4000 : 10000;
  const timeTillNextUpdate = lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 15)
    : 15 * 60 * 1000;
  const cacheTime = timeTillNextUpdate + maxRandomTime;
  const {
    data,
    isLoading,
    isError,
    refetch,
    error,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery<IUserThemeDetailsResponse>({
    queryKey: [EXPLORE_QUERY_KEYS.userThemeDetails, themeId],
    queryFn: ({ pageParam = 1 }) =>
      getUserThemeDetails({ themeId: themeId, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      const { asset_count } = lastPage;
      if (asset_count === allAssetsDetails?.length) {
        return undefined;
      }
      const nextPage = pages.length + 1;
      return nextPage;
    },
    staleTime: cacheTime,
    cacheTime,
    enabled,
  });

  const allAssetsDetails = data?.pages
    ?.map((page) => page?.['assets'])
    .flat() as Partial<IAsset>[];
  const themeDetails = data?.pages?.[0];
  const assetsCount = data?.pages?.[0].asset_count;
  const setQueryDataFromCache = (cahceData: any, themeId: string) => {
    queryClient.setQueryData(
      [EXPLORE_QUERY_KEYS.userThemeDetails, themeId],
      cahceData
    );
  };
  return {
    isLoading,
    isError,
    error,
    allAssetsDetails,
    themeDetails,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    assetsCount,
    refetch,
    setQueryDataFromCache,
  };
}
