import { useQuery } from 'react-query';
import { getPlans } from '../api/plans';

export const useSubscriptionPlans = () => {
  const { isError, isLoading, data } = useQuery('subscription-plans', getPlans);
  return {
    plans: data?.data?.results,
    isLoading,
    isError,
  };
};

export const usePlanInfo = (planId: string) => {
  const { data, isError, isLoading } = useQuery('subscription-plans', getPlans);
  return {
    plan: data?.data?.results.find(({ id }: { id: string }) => id === planId),
    isError,
    isLoading,
  };
};
