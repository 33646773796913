import { useQuery, useQueryClient } from 'react-query';
import { getRecommendedSecurities } from '../api/getRecommendedSecurities';
import { getTimeTillNextUpdate } from '../util';
import { randomTimeWrapper } from '@thndr/services/api';
import { ASSETS_QUERY_KEYS } from '../query-keys';

export const useRecommendedSecurities = ({
  assetId,
  market,
  enabled = true,
  isSubscribed = false,
  count = 4,
}: {
  assetId: string;
  market: string;
  enabled?: boolean;
  isSubscribed?: boolean;
  count?: number;
}) => {
  const queryClient = useQueryClient();
  const lastUpdatedAt = queryClient.getQueryState([
    ASSETS_QUERY_KEYS.recommendedSecurities,
    assetId,
  ])?.dataUpdatedAt;
  const timeTillNextUpdate = lastUpdatedAt
    ? getTimeTillNextUpdate(lastUpdatedAt, 15)
    : 15 * 60 * 1000;
  const { data, isLoading, error } = useQuery({
    queryKey: [ASSETS_QUERY_KEYS.recommendedSecurities, assetId],
    queryFn: () =>
      randomTimeWrapper({
        isSubscribed,
        skipDelay: data ? false : true,
        callback: async () => {
          const { results } = await getRecommendedSecurities({
            market,
            assetId,
            count,
          });
          return results;
        },
      }),
    staleTime: timeTillNextUpdate,
    cacheTime: timeTillNextUpdate,
    enabled: enabled,
  });
  return {
    recommendedAssets: data,
    isLoading,
    error,
  };
};
