import { thndrApi } from '@thndr/services/api';
import { IAsset, IUserData } from '../types';

export const searchAssets = async ({
  query,
  market,
}: {
  query: string;
  market: string;
}): Promise<Partial<IAsset[]>> => {
  const { data } = await thndrApi.get(
    `/assets-service/assets/search?query=${query}&page=1&market=${market}&include_feed=true&feed_detail=true`
  );
  return data?.assets?.slice(0, 10);
};

export const searchUser = async ({
  query,
  page,
}: {
  query: string;
  page: number;
}): Promise<IUserData> => {
  const { data } = await thndrApi.get(
    `/users-service/users?q=${query}f&page=${page}`
  );
  return data;
};
