// this will be edited with data related to web app
const unleashProxyDomain = `https://unleash.thndr.app`;
export const unleashConfig = {
  url: `${unleashProxyDomain}/proxy`,
  clientKey: 'jU99iOlkjauiwe',
  refreshInterval: 15,
  appName: 'thndr-web',
  environment:
    process.env.NEXT_PUBLIC_APP_ID === 'web.axismarkets.thndr.staging'
      ? 'staging'
      : 'prod',
  context: {
    properties: {
      appId: process.env.NEXT_PUBLIC_APP_ID,
    },
  },
};
