import axios from 'axios';
export { getWithToken } from './getWithToken';

export let instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    sessionId: global.sessionID,
  },
});

export const initialize = () => {};
