import {
  getAuth,
  signInWithPopup,
  signInWithCustomToken,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  updateProfile,
} from 'firebase/auth';

// @ts-ignore

import { ApplicationError } from '@/util/errors';
import { Context } from '@/store';
import { logger } from '@/store/effects';

export async function initializeFirebase({
  actions,
}: {
  actions: Context['actions'];
}) {
  getAuth().onAuthStateChanged(actions.auth.refreshFirebaseToken);
}
export async function signInWithToken(firebaseToken: string) {
  try {
    const auth = getAuth();
    return signInWithCustomToken(auth, firebaseToken);
  } catch (error) {
    throw new ApplicationError(error);
  }
}
export async function signInWithFacebook() {
  try {
    const provider = new FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');
    const auth = getAuth();
    return signInWithPopup(auth, provider);
  } catch (error) {
    throw new ApplicationError(error, 'errors.auth.facebook');
  }
}
export async function signInWithGoogle() {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    const auth = getAuth();
    return signInWithPopup(auth, provider);
  } catch (error) {
    throw new ApplicationError(error, 'errors.auth.google');
  }
}
export async function signInWithApple() {
  try {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const auth = getAuth();
    return signInWithPopup(auth, provider);
  } catch (error) {
    throw new ApplicationError(error, 'errors.auth.apple');
  }
}
export async function getFirebaseUser() {
  const auth = getAuth();
  if (!auth.currentUser) {
    logger.warn(
      'firebase user must be get after state.auth.hasLoadedFirebaseUser is true',
    );
  }
  return auth.currentUser;
}
export async function getFirebaseToken() {
  const user = await getFirebaseUser();
  return user?.getIdToken(true) ?? null;
}
export async function updateFirebaseUser(userUpdate) {
  const user = await getFirebaseUser();
  updateProfile(user, userUpdate);
}
export async function logoutFirebase() {
  const auth = getAuth();
  return auth.signOut();
}
