import { IOrderForm, IOrderView } from '@/models/orders';
import {
  OrdersByStatus,
  ORDER_EXECUTION,
  ORDER_SETTLEMENT,
  ORDER_TIME_IN_FORCE,
  QUICK_TRADE_VIEWS,
} from '@/constants/orders';
import { initialOrderViewState } from 'libs/namespace/orders/src/constants';

export const initialForm: IOrderForm = {
  order_type: null,
  is_limit: true,
  stock_id: null,
  amount: 0,
  price: null,
  amount_to_invest: 0,
  redeem_all: false,
  asset_id: null,
  asset_class: null,
  order_id: null,
  advanced_order: false,
  execution_type: ORDER_EXECUTION.NO_FILL,
  time_in_force: ORDER_TIME_IN_FORCE.DAY,
  settlement: ORDER_SETTLEMENT.T2,
  time_in_force_date: null,
};

export type IOrderState = {
  orderId: string;
  tray: {
    isVisible: boolean;
    isConfirming: boolean;
    activeView: QUICK_TRADE_VIEWS;
    selectedAsset: string;
    isSubmitting: boolean;
    search: {
      isLoading: boolean;
      query: string;
      count: number;
      assets: string[];
      page: number;
      page_count: number;
    };
  };
  form: IOrderForm;
  orders: {
    isLoading: boolean;
    isCanceling: boolean;
    data: [];
  };
  assetOrders: {
    isLoading: boolean;
    isCanceling: boolean;
    data: { [assetId: string]: OrdersByStatus };
  };
  orderView: IOrderView;
};

export const state: IOrderState = {
  orderId: '',
  tray: {
    isVisible: false,
    isConfirming: false,
    isSubmitting: false,
    activeView: QUICK_TRADE_VIEWS.SEARCH_ASSET,
    selectedAsset: null,
    search: {
      isLoading: false,
      query: '',
      count: 0,
      assets: [],
      page: 0,
      page_count: 0,
    },
  },
  form: initialForm,
  orders: { data: [], isLoading: false, isCanceling: false },
  assetOrders: { data: {}, isLoading: false, isCanceling: false },
  orderView: initialOrderViewState,
};
