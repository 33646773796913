import axios from 'axios';
import { API_BASE_URL } from '::platform';

export const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept = 'application/json';
    config.headers.sessionId = global.sessionID;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // here we can have a switch statement to handle different errors
    if (error.response.status === 500) {
      // we could show the user a generic error here
      console.log('error', error);
    }
    return Promise.reject(error);
  },
);
