import { unleash } from '@/services/feature/instance';
import { IMutableContext } from 'unleash-proxy-client';
import { UnleashFeature, FeatureState } from '@/services/feature/types';

export const isEnabled = (feature: UnleashFeature) => {
  return unleash.isEnabled(feature);
};
export const getVariant = (feature: UnleashFeature) => {
  return unleash.getVariant(feature);
};

export const updateContext = (
  context: Partial<FeatureState['context']> = {},
) => {
  try {
    if (!context.properties.email) {
      return;
    }
    //@ts-ignore
    unleash.updateContext(context as IMutableContext);
  } catch (error) {
    console.error(error);
  }
};
export const start = async () => {
  if (!unleash) {
    console.log(`attempted to start unleash without unleash instance`);
    return false;
  }
  try {
    return unleash.start();
  } catch (error) {
    console.error(error);
  }
};
export const setContextField = (key: string, value: any) => {
  try {
    unleash.setContextField(key, value);
  } catch (error) {
    console.error(error);
  }
};
export const getAllFeatures = () => {
  try {
    return unleash.getAllToggles() || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export { unleash };
