import { AsyncAction } from '@/store';
import Routes, {
  IAccessAccountQueryParams,
  ISubscriptionTrialParams,
} from '@/constants/routes';

export const navigateToNewSubscription: AsyncAction<ISubscriptionTrialParams> =
  async ({ effects, actions }, payload) => {};

export const navigateToManageSubscriptions: AsyncAction = async ({
  actions,
  effects,
  state,
}) => {


  effects.navigation.replace(Routes.SUBSCRIPTIONS);
};

export const navigateToConfirm: AsyncAction = async ({
  actions,
  effects,
  state,
}) => {
  // TODO: maybe handel the case of someone going to confirm without being logged in
 

  effects.navigation.replace(Routes.SUBSCRIPTIONS);
};
