
import { ApplicationError } from '@/util/errors';

export const logger = {
  log(string: String) {},
  initialize() {
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      logger.recordError(error);
    };
  },
  info(...messages: any[]) {
    process.env.NEXT_PUBLIC___DEV__ && console.log(...messages);
  },
  warn(...messages: any[]) {
    process.env.NEXT_PUBLIC___DEV__ && console.warn(...messages);
  },
  async recordError(error: any, extras: Record<string, any> = {}) {
    //FIXME: fix when we have a proper error handling/logger
    let message = error.message;
    if (error instanceof ApplicationError) {
      message = error.messages;
      error = error.unWrapError();
    }
    process.env.NEXT_PUBLIC___DEV__ && console.error(error);
  },
};
