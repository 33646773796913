import {
  createOvermind,
  json,
  derived,
  IContext,
  IAction,
  IOperator,
  rehydrate,
} from 'overmind';
import type { IState } from 'overmind';
import { merge } from 'overmind/config';
import {
  createStateHook,
  createActionsHook,
  createEffectsHook,
  createReactionHook,
} from 'overmind-react';
import { state } from './state';
import * as actions from './actions';
import * as effects from './effects';
import namespaces from './namespaces';

export const config = merge({ actions, state, effects }, namespaces);
// eslint-disable-next-line @typescript-eslint/naming-convention
export type Context = IContext<typeof config>;

export const useStates = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
export const useEffects = createEffectsHook<Context>();
export const useReaction = createReactionHook<Context>();

export type { IState };
export { createOvermind, json, derived, rehydrate };

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Config {
  state: typeof config.state;
  actions: typeof config.actions;
  effects: typeof config.effects;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Action<Input = void, Output = void>
  extends IAction<Input, Output> {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface AsyncAction<Input = void, Output = void>
  extends IAction<Input, Promise<Output>> {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Operator<Input = void, Output = Input>
  extends IOperator<Input, Output> {}
