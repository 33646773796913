export enum MarketName {
  SIMULATOR = 'simulator',
  EGYPT = 'egypt',
  US = 'us',
}

export const AVAILABLE_EGYPT = [MarketName.EGYPT];
export const AVAILABLE_US = [MarketName.US];
export const AVAILABLE_ALL = [MarketName.US, MarketName.EGYPT];

export const AVAILABLE_MARKET_MAP = {
  [MarketName.EGYPT]: AVAILABLE_EGYPT,
  [MarketName.US]: AVAILABLE_US,
  [MarketName.SIMULATOR]: AVAILABLE_ALL,
};

export interface MARKET_HOURS {
  session_open: string;
  session_close: string;
}
export type IGetMarketHoursPayload = { market: MarketName };
export type IGetMarketHoursResponse = {
  session_open: string;
  session_close: string;
};
export type IMarketHoursState =
  | 'OPENS_AT'
  | 'NOW_OPEN'
  | 'CLOSES_AT'
  | 'OPENS_TOMORROW'
  | 'OPENS_ON_DAY';
export type IOrderPrice = {
  volume_traded: number;
  order_price: number;
  total_bids?: number;
  total_ask?: number;
  split: number;
  volume_traded_cum_sum: number;
};

export type MarketDepth = {
  bids_per_price: Array<IOrderPrice>;
  asks_per_price: Array<IOrderPrice>;
  total_bids_and_asks: {
    total_bids: number;
    total_asks: number;
  };
};
